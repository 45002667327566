
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import WordsFind from '../../components/ExcerciseTypes/WordsFind';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: { // Exercise num
    unit: 'Starter Unit',
    id: 'WB6-S-P6-E1',
    audio: '',
    video: '',
    component: WordsFind,
    exerciseKey: 'img/FriendsPlus/Page6/E1/key.png',
    titleImage: 'img/FriendsPlus/Page6/E1/title.jpg',
    // titleQuestion: [{ num: '1', title: "Find twelve more words in the wordsearch.", star: 1, color: '#5C97A9' }],
    questionImage: [],
    array: [
      ["P", "E", "N", "W", "X", "V", "U", "Z", "L", "G", "N", "H", "N"],
      ["K", "Q", "Y", "P", "D", "H", "C", "O", "A", "T", "O", "F", "K"],
      ["S", "U", "D", "E", "S", "K", "L", "M", "P", "K", "T", "K", "W"],
      ["P", "K", "F", "H", "L", "S", "O", "Q", "T", "Q", "E", "S", "U"],
      ["E", "P", "S", "Z", "E", "G", "C", "K", "O", "D", "B", "N", "V"],
      ["A", "K", "H", "C", "F", "J", "K", "W", "P", "R", "O", "J", "E"],
      ["K", "G", "E", "J", "X", "Y", "P", "Y", "G", "A", "O", "P", "L"],
      ["E", "I", "L", "H", "T", "A", "B", "L", "E", "W", "K", "M", "N"],
      ["R", "H", "F", "W", "P", "D", "K", "Q", "C", "E", "H", "N", "B"],
      ["X", "D", "M", "E", "Z", "B", "I", "U", "X", "R", "V", "R", "H"],
      ["B", "A", "G", "F", "G", "P", "O", "S", "T", "E", "R", "T", "J"],
      ["M", "O", "B", "I", "L", "E", "P", "H", "O", "N", "E", "F", "K"],
    ],
    initialValues: [
      { id: '0-0', isCorrect: true },
      { id: '0-1', isCorrect: true },
      { id: '0-2', isCorrect: true },
    ],
    questions: [
      {
        title: 'positive',
        answerInputs: ['bag', 'clock', 'coat', 'desk', 'drawer', 'laptop', 'mobilephone']
      },
      {
        title: 'negative',
        answerInputs: ['notebook', 'pen', 'poster', 'shelf', 'speaker', 'table']
      }
    ]

  },
  2: { // Exercise num
    unit: 'Starter Unit',
    id: 'WB6-S-P6-E2',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page6/E2/Key/Key.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page6/E2/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page6/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page6/E2/3.jpg', input: true, answer: "It isn't a notebook." },
        { url: 'img/FriendsPlus/Page6/E2/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page6/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page6/E2/6.jpg', input: true, answer: "It's a pen." },
        { url: 'img/FriendsPlus/Page6/E2/7.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page6/E2/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page6/E2/9.jpg' },
        { url: 'img/FriendsPlus/Page6/E2/10.jpg', input: true, answer: "It isn't a dictionary." },
        { url: 'img/FriendsPlus/Page6/E2/11.jpg' },
        { url: 'img/FriendsPlus/Page6/E2/12.jpg', input: true, answer: "It isn't a coat." },
        { url: 'img/FriendsPlus/Page6/E2/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page6/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page6/E2/15.jpg', input: true, answer: "It's a bag." },
        { url: 'img/FriendsPlus/Page6/E2/16.jpg' },
        { url: 'img/FriendsPlus/Page6/E2/17.jpg', input: true, answer: "It's a book." },
        { url: 'img/FriendsPlus/Page6/E2/18.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page6/E2/19.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page6/E2/20.jpg' },
        { url: 'img/FriendsPlus/Page6/E2/21.jpg', input: true, answer: "It isn't a pen." },
        { url: 'img/FriendsPlus/Page6/E2/22.jpg' },
        { url: 'img/FriendsPlus/Page6/E2/23.jpg', input: true, answer: "It isn't a bag." },
        { url: 'img/FriendsPlus/Page6/E2/24.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page6/E2/25.jpg' },
        { url: 'img/FriendsPlus/Page6/E2/26.jpg', input: true, answer: "It's a laptop." },
        { url: 'img/FriendsPlus/Page6/E2/27.jpg' },
        { url: 'img/FriendsPlus/Page6/E2/28.jpg', input: true, answer: "It's a coat." },
        { url: 'img/FriendsPlus/Page6/E2/29.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  3: { // Exercise num
    unit: 'Starter Unit',
    id: 'WB6-S-P6-E3',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page6/E3/Key/Key.png',
    component: T6,
    imgSize: '70%',
    titleImage: 'img/FriendsPlus/Page6/E3/title.jpg',
    //titleQuestion: [{ num: '4', title: 'Complete the sentences with be and one of the adjectives from this page.', color: '#F58024', star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>The coat is<span style='font-weight:600;font-size: 28px;font-family:OneStrokeScriptStd-Bold;color:gray'> near </span> the notebook.</p>" +
          "<p><span style='font-weight:600'>1</span> The clock is  # the shelf. </p>" +
          "<p><span style='font-weight:600'>2</span> The bag is # the chair.</p>" +
          "<p><span style='font-weight:600'>3</span> The dictionary is # the drawer. </p>" +
          "<p><span style='font-weight:600'>4</span> The desk is  # the table. </p>" +
          "<p><span style='font-weight:600'>5</span> The mobile phone is # the </p>" +
          "<p>&ensp; laptop and the speaker.</p>" +
          "<p><span style='font-weight:600'>6</span> The poster is # the board.</p>",
        answer: [
          "on",
          "under",
          "in",
          "opposite",
          "between",
          "next to",
        ],
        type: 'longAnwser'
      },
    ]
  },


  4: { // Exercise num
    unit: 'Starter Unit',
    id: 'WB6-S-P6-E4',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page6/E4/Key/Key.png',
    component: T6,
    imgSize: '70%',
    inputSize: 650,
    titleImage: 'img/FriendsPlus/Page6/E4/title.jpg',
    //titleQuestion: [{ num: '4', title: 'Complete the sentences with be and one of the adjectives from this page.', color: '#F58024', star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray'>The laptop is on the desk.</span></p>" +
          "<p><span style='font-weight:600'></span>#</p>" +
          "<p><span style='font-weight:600'></span>#</p>" +
          "<p><span style='font-weight:600'></span>#</p>" +
          "<p><span style='font-weight:600'></span>#</p>" +
          "<p><span style='font-weight:600'></span>#</p>" +
          "<p><span style='font-weight:600'></span>#</p>" +
          "<p><span style='font-weight:600'></span>#</p>",
        answer: [
          " The notebook is between the dictionary and the speaker. / The notebook is between the book and the speaker. / The poster is on the wall. / The poster is above the table. / The pen is near the laptop. / The clock is in the drawer. / The bag is under the table. / The coat is on the table. / The coat is next to the mobile phone.",
          " The notebook is between the dictionary and the speaker. / The notebook is between the book and the speaker. / The poster is on the wall. / The poster is above the table. / The pen is near the laptop. / The clock is in the drawer. / The bag is under the table. / The coat is on the table. / The coat is next to the mobile phone.",
          " The notebook is between the dictionary and the speaker. / The notebook is between the book and the speaker. / The poster is on the wall. / The poster is above the table. / The pen is near the laptop. / The clock is in the drawer. / The bag is under the table. / The coat is on the table. / The coat is next to the mobile phone.",
          " The notebook is between the dictionary and the speaker. / The notebook is between the book and the speaker. / The poster is on the wall. / The poster is above the table. / The pen is near the laptop. / The clock is in the drawer. / The bag is under the table. / The coat is on the table. / The coat is next to the mobile phone.",
          " The notebook is between the dictionary and the speaker. / The notebook is between the book and the speaker. / The poster is on the wall. / The poster is above the table. / The pen is near the laptop. / The clock is in the drawer. / The bag is under the table. / The coat is on the table. / The coat is next to the mobile phone.",
          " The notebook is between the dictionary and the speaker. / The notebook is between the book and the speaker. / The poster is on the wall. / The poster is above the table. / The pen is near the laptop. / The clock is in the drawer. / The bag is under the table. / The coat is on the table. / The coat is next to the mobile phone.",
          " The notebook is between the dictionary and the speaker. / The notebook is between the book and the speaker. / The poster is on the wall. / The poster is above the table. / The pen is near the laptop. / The clock is in the drawer. / The bag is under the table. / The coat is on the table. / The coat is next to the mobile phone.",
        ],
        type: 'longAnwser'
      },
    ]
  },


}

export default json;