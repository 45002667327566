import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import MC6 from '../../components/ExcerciseTypes/MultipleChoice/MC6';

const json = {
  1: { // Exercise num
     unit : 'Unit 4',
    id : 'WB6-U4-P34-E1',
    audio: '',
    video: '',
    component: MC6,
    exerciseKey: 'img/FriendsPlus/Page34/E1/Key/answerKey.png',
    titleQuestion: [{ num: '1', title: 'Read the text. Choose the correct answer.', color: '#B83D3E' }],
    titleImage: '',
    img: 'img/FriendsPlus/Page34/E1/img.jpg',
    questionImage: [ // Row
      [
        // Column
        { url: 'http://' },
        { url: 'http://', input: true },
        { url: 'http://' },
      ],
    ],
    questions: [
      {
        title: 'The school is different because the students ...',
        answers: [
          { image: "", isCorrect: false, right: "", text: "learn to make boats." },
          { image: "", isCorrect: false, right: "", text: "have all their lessons on boats." },
          { image: "", isCorrect: true, right: "", text: "go out on boats in the school day." },
        ],
        no: 1,
        question: '',
        type: '',
      },
    ]
  },

  2: {
    unit : 'Unit 4',
    id : 'WB6-U4-P34-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page34/E2/Key/Key.png',
    component: D1,
    fontSize: 20,
    // titleQuestion: [{ num: '1', title: `Listen to the street interviews and tick (✔) the correct box. What is the person’s favourite building?`, star: 2, color: '#F68025' }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page34/E2/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page34/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page34/E2/3.jpg', input: true, answer: "True" },
        { url: 'img/FriendsPlus/Page34/E2/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page34/E2/5.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page34/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page34/E2/7.jpg', input: true, answer: "False" },
        { url: 'img/FriendsPlus/Page34/E2/8.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page34/E2/9.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page34/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page34/E2/11.jpg', input: true, answer: "False" },
        { url: 'img/FriendsPlus/Page34/E2/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page34/E2/13.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page34/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page34/E2/15.jpg', input: true, answer: "True" },
        { url: 'img/FriendsPlus/Page34/E2/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page34/E2/17.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page34/E2/18.jpg' },
        { url: 'img/FriendsPlus/Page34/E2/19.jpg', input: true, answer: "False" },
        { url: 'img/FriendsPlus/Page34/E2/20.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page34/E2/21.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page34/E2/22.jpg' },
        { url: 'img/FriendsPlus/Page34/E2/23.jpg', input: true, answer: "True" },
        { url: 'img/FriendsPlus/Page34/E2/24.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page34/E2/25.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  3: {
    unit : 'Unit 4',
    id : 'WB6-U4-P34-E3',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page34/E3/Key/Key.png',
    component: D1,
    fontSize: 25,
    // titleQuestion: [{ num: '1', title: `Listen to the street interviews and tick (✔) the correct box. What is the person’s favourite building?`, star: 2, color: '#F68025' }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page34/E3/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page34/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page34/E3/3.jpg', input: true, answer: "B" },
        { url: 'img/FriendsPlus/Page34/E3/4.jpg' },
      ], [
        { url: 'img/FriendsPlus/Page34/E3/5.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page34/E3/6.jpg' },
        { url: 'img/FriendsPlus/Page34/E3/7.jpg', input: true, answer: "C" },
        { url: 'img/FriendsPlus/Page34/E3/8.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page34/E3/9.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page34/E3/10.jpg' },
        { url: 'img/FriendsPlus/Page34/E3/11.jpg', input: true, answer: "F" },
        { url: 'img/FriendsPlus/Page34/E3/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page34/E3/13.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page34/E3/14.jpg' },

        { url: 'img/FriendsPlus/Page34/E3/15.jpg', input: true, answer: "A" },
        { url: 'img/FriendsPlus/Page34/E3/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page34/E3/17.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page34/E3/18.jpg' },
        { url: 'img/FriendsPlus/Page34/E3/19.jpg', input: true, answer: "D" },
        { url: 'img/FriendsPlus/Page34/E3/20.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page34/E3/21.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page34/E3/22.jpg' },
        { url: 'img/FriendsPlus/Page34/E3/23.jpg', input: true, answer: "E" },
        { url: 'img/FriendsPlus/Page34/E3/24.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page34/E3/25.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  4: { // Exercise num
     unit : 'Unit 4',
    id : 'WB6-U4-P34-E4',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page34/E4/Key/Key.png',
    component: T1,
    inputSize: 280,
    // titleImage: 'img/FriendsPlus/Page34/E4/title.jpg',
    titleQuestion: [{ num: '4', title: 'Answer the questions. Write complete sentences.', color: '#d92a2e', star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>What are the students doing at 8 a.m.?</p>" +
          "<p><u style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px;'>They’re having breakfast.</u></p>" +
          "<p><span style='font-weight:600'>1 </span>What are the students wearing? #</p>" +
          "<p><span style='font-weight:600'>2 </span>Why is Lake Windermere special? #</p>" +
          "<p><span style='font-weight:600'>3 </span>When do lessons finish every day? #</p>" +
          "<p><span style='font-weight:600'>4 </span>What do the students use when they go on the lake? #</p>" +
          "<p><span style='font-weight:600'>5 </span>Do you think boarding schools are a good idea? Why/Why not? # </p>" +
          "<p><span style='font-weight:600'>6 </span>Do you like the sound of this school? Why/Why not? #</p>",
        answer: [
          "They're wearing school uniforms.",
          "It's special because it's one of the most beautiful lakes in England.",
          "Lessons finish at 4.00.",
          "They use the school's boats.",
          "",
          "",
        ],
        type: 'longAnwser'
      },
    ]
  },

  5: { // Exercise num
     unit : 'Unit 4',
    id : 'WB6-U4-P34-E5',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page34/E5/Key/Key.png',
    component: T6,
    inputSize: 150,
    imgSize: '65%',
    titleImage: 'img/FriendsPlus/Page34/E5/title.jpg',
    // titleQuestion: [{ num: '4', title: 'Answer the questions. Write complete sentences.', color: '#d92a2e', star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>Our project is about a &#160;&#160;<span><u style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px;'>boarding</u></span>&#160;&#160; school. </p>" +
          "<p>Students live at the school and sleep there.</p>" +
          "<p><span style='font-weight:600'>1 </span>We’re learning about a # school</p>" +
          "<p>in India. The children there are from six to ten years old.</p>" +
          "<p><span style='font-weight:600'>2 </span>The school in my project is different. It’s on the</p>" +
          "<p>water and it’s called a # school.</p>" +
          "<p><span style='font-weight:600'>3 </span>This is a TV programme about an expensive </p>" +
          "<p># school in the UK.</p>" +
          "<p><span style='font-weight:600'>4 </span>That school in the picture is for older students </p>" +
          "<p>from eleven to fourteen. It’s a  # school.</p>",
        answer: [
          "primary",
          "boat",
          "private",
          "secondary",
        ],
        type: 'longAnwser'
      },
    ]
  },
}
export default json