import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
  1: { // Exercise num
    unit: 'Unit 2',
    id: 'WB6-U2-P23-E1',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 6,
    exerciseKey: 'img/FriendsPlus/Page23/E1/Key/answerKey.png',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page23/E1/03.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page23/E1/05.jpg' },
        { url: 'img/FriendsPlus/Page23/E1/06.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page23/E1/07.jpg' },
        { url: 'img/FriendsPlus/Page23/E1/08.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page23/E1/09.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page23/E1/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page23/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page23/E1/12.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page23/E1/13.jpg' },
        { url: 'img/FriendsPlus/Page23/E1/14.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page23/E1/15.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page23/E1/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page23/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page23/E1/18.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page23/E1/19.jpg' },
        { url: 'img/FriendsPlus/Page23/E1/20.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page23/E1/21.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page23/E1/22.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page23/E1/23.jpg' },
        { url: 'img/FriendsPlus/Page23/E1/24.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page23/E1/25.jpg' },
        { url: 'img/FriendsPlus/Page23/E1/26.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page23/E1/27.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page23/E1/28.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page23/E1/29.jpg' },
        { url: 'img/FriendsPlus/Page23/E1/30.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page23/E1/31.jpg' },
        { url: 'img/FriendsPlus/Page23/E1/32.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page23/E1/33.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page23/E1/34.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page23/E1/35.jpg' },
        { url: 'img/FriendsPlus/Page23/E1/36.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page23/E1/37.jpg' },
        { url: 'img/FriendsPlus/Page23/E1/38.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page23/E1/39.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page23/E1/40.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  2: { // Exercise num
    unit: 'Unit 2',
    id: 'WB6-U2-P23-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page23/E2/Key/Key.png',
    imgSize: '70%',
    inputSize: 330,
    titleImage: 'img/FriendsPlus/Page23/E2/title.jpg',
    //titleQuestion: [{ num: '4', title: 'Write sentences about the capital city of your country or a big city near you.', color: '#66A0B4', star: 3 }],
    questionImage: [],
    questions: [
      {
        title: "<p>The carnival is big.<span style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u> It’s also really exciting.</u></span></p>" +
          "<p style='font-weight:500'>1 Eva sings in the parade.  #</p>" +
          "<p style='font-weight:500'></p>" +
          "<p style='font-weight:500'>2 My brother is fun. #</p>" +
          "<p style='font-weight:500'></p>" +
          "<p style='font-weight:500'>3 David and I love films. #</p>" +
          "<p style='font-weight:500'></p>" +
          "<p style='font-weight:500'>4 There are two cinemas. #</p>" +
          "<p style='font-weight:500'></p>" +
          "<p style='font-weight:500'>5 I’m quiet.  #</p>",
        answer: [
          "She also dances.",
          "He's also very noisy.",
          "We also like music videos.",
          "There's also a theatre.",
          "But I'm also really friendly.",
        ],
        type: 'longAnwser'
      },
    ]
  },

  3: { // Exercise num
    unit: 'Unit 2',
    id: 'WB6-U2-P23-E3',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page23/E3/Key/Key.png',
    titleImage: 'img/FriendsPlus/Page23/E3/title.jpg',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page23/E3/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page23/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page23/E3/3.jpg', input: true, answer: "March" },
        { url: 'img/FriendsPlus/Page23/E3/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page23/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page23/E3/6.jpg', input: true, answer: "Thursday" },
        { url: 'img/FriendsPlus/Page23/E3/7.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page23/E3/8.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page23/E3/9.jpg' },
        { url: 'img/FriendsPlus/Page23/E3/10.jpg', input: true, answer: "books" },
        { url: 'img/FriendsPlus/Page23/E3/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page23/E3/12.jpg' },
        { url: 'img/FriendsPlus/Page23/E3/13.jpg', input: true, answer: "famous people in books" },
        { url: 'img/FriendsPlus/Page23/E3/14.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page23/E3/15.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page23/E3/16.jpg' },
        { url: 'img/FriendsPlus/Page23/E3/17.jpg', input: true, answer: "wear costumes" },
        { url: 'img/FriendsPlus/Page23/E3/18.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page23/E3/19.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page23/E3/20.jpg' },
        { url: 'img/FriendsPlus/Page23/E3/21.jpg', input: true, answer: "do quizzes" },
        { url: 'img/FriendsPlus/Page23/E3/22.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page23/E3/23.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page23/E3/24.jpg' },
        { url: 'img/FriendsPlus/Page23/E3/25.jpg', input: true, answer: "interesting" },
        { url: 'img/FriendsPlus/Page23/E3/26.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page23/E3/27.jpg' },
        { url: 'img/FriendsPlus/Page23/E3/28.jpg', input: true, answer: "fun" },
        { url: 'img/FriendsPlus/Page23/E3/29.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page23/E3/30.jpg' },
      ],
    ],

    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  4: { // Exercise num
    unit: 'Unit 2',
    id: 'WB6-U2-P23-E4',
    audio: '',
    video: '',
    component: T2,
    // exerciseKey: 'img/FriendsPlus/Page23/E4/Key/Key.png',
    imgSize: 700,
    // titleImage: 'img/FriendsPlus/Page23/E4/title.jpg',
    titleQuestion: [{ num: '4', title: ' Write a description of a special day at your school. Use the text in exercise 3 to help you.', color: '#04a450', star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p style='font-weight:500'></p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>",


        answer: [],
        type: 'longAnwser'
      },
    ]
  },

}
export default json