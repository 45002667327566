export const dataBook = {
  1: [
    [
      { imgUrl: 'img/FriendsPlus/Page1/1.png', excerciseNum: 0 },
    ],
  ],
  2: [
    [
      { imgUrl: 'img/FriendsPlus/Page2/1.jpg', excerciseNum: 0 },
    ],
  ],
  3: [
    [
      { imgUrl: 'img/FriendsPlus/Page3/1.jpg', excerciseNum: 0 },
    ],
  ],
  4: [
    [
      { imgUrl: 'img/FriendsPlus/Page4/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page4/2.jpg', excerciseNum: 1 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page4/3.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page4/4.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page4/5.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page4/6.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page4/7.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page4/8.jpg', excerciseNum: 5 },
    ],
  ],
  5: [
    [
      { imgUrl: 'img/FriendsPlus/Page5/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page5/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page5/3.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page5/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page5/5.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page5/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page5/7.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page5/8.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page5/9.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page5/10.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page5/11.jpg', excerciseNum: 6 },
    ],
  ],
  6: [
    [
      { imgUrl: 'img/FriendsPlus/Page6/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page6/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page6/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page6/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page6/5.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page6/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page6/7.jpg', excerciseNum: 4 },
    ],
  ],
  7: [
    [
      { imgUrl: 'img/FriendsPlus/Page7/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page7/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page7/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page7/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page7/5.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page7/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page7/7.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page7/8.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page7/9.jpg', excerciseNum: 5 },
    ],
  ],
  8: [
    [
      { imgUrl: 'img/FriendsPlus/Page8/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page8/2.jpg', excerciseNum: 1 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page8/3.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page8/4.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page8/5.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page8/6.jpg', excerciseNum: 4 },
    ],
  ],
  9: [
    [
      { imgUrl: 'img/FriendsPlus/Page9/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page9/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page9/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page9/4.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page9/5.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page9/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page9/7.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page9/8.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page9/9.jpg', excerciseNum: 5 },
    ],
  ],
  10: [
    [
      { imgUrl: 'img/FriendsPlus/Page10/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page10/2.jpg', excerciseNum: 1 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page10/3.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page10/4.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page10/5.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page10/6.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page10/7.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page10/8.jpg', excerciseNum: 4 },
    ],
  ],
  11: [
    [
      { imgUrl: 'img/FriendsPlus/Page11/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page11/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page11/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page11/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page11/5.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page11/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page11/7.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page11/8.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page11/9.jpg', excerciseNum: 5 },
    ],
  ],
  12: [
    [
      { imgUrl: 'img/FriendsPlus/Page12/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page12/2.jpg', excerciseNum: 1 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page12/3.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page12/4.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page12/5.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page12/6.jpg', excerciseNum: 4 },
    ],

  ],
  13: [
    [
      { imgUrl: 'img/FriendsPlus/Page13/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page13/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page13/3.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page13/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page13/5.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page13/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page13/7.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page13/8.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page13/9.jpg', excerciseNum: 5 },
    ],
  ],
  14: [
    [
      { imgUrl: 'img/FriendsPlus/Page14/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page14/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page14/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page14/4.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page14/5.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page14/6.jpg', excerciseNum: 2 },
    ],
  ],
  15: [
    [
      { imgUrl: 'img/FriendsPlus/Page15/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page15/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page15/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page15/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page15/5.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page15/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page15/7.jpg', excerciseNum: 4 },
    ],
  ],
  16: [
    [
      { imgUrl: 'img/FriendsPlus/Page16/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page16/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page16/3.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page16/4.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page16/5.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page16/6.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page16/7.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page16/8.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page16/9.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page16/10.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page16/11.jpg', excerciseNum: 4 },
      { imgUrl: 'img/FriendsPlus/Page16/12.jpg', excerciseNum: 5 },
    ],
  ],
  17: [
    [
      { imgUrl: 'img/FriendsPlus/Page17/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page17/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page17/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page17/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page17/5.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page17/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page17/7.jpg', excerciseNum: 4 },
    ],
  ],
  18: [
    [
      { imgUrl: 'img/FriendsPlus/Page18/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page18/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page18/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page18/4.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page18/5.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page18/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page18/7.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page18/8.jpg', excerciseNum: 0 },
    ],
  ],
  19: [
    [
      { imgUrl: 'img/FriendsPlus/Page19/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page19/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page19/3.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page19/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page19/5.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page19/6.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page19/7.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page19/8.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page19/9.jpg', excerciseNum: 5 },
    ],
  ],
  20: [
    [
      { imgUrl: 'img/FriendsPlus/Page20/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page20/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page20/3.jpg', excerciseNum: 2 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page20/4.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page20/5.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page20/6.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page20/7.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page20/8.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page20/9.jpg', excerciseNum: 5 },
    ],
  ],
  21: [
    [
      { imgUrl: 'img/FriendsPlus/Page21/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page21/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page21/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page21/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page21/5.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page21/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page21/7.jpg', excerciseNum: 4 },
    ],
  ],
  22: [
    [
      { imgUrl: 'img/FriendsPlus/Page22/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page22/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page22/3.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page22/4.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page22/5.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page22/6.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page22/7.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page22/8.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page22/9.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page22/10.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page22/11.jpg', excerciseNum: 4 },
      { imgUrl: 'img/FriendsPlus/Page22/12.jpg', excerciseNum: 5 },
    ],
  ],
  23: [
    [
      { imgUrl: 'img/FriendsPlus/Page23/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page23/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page23/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page23/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page23/5.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page23/6.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page23/7.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page23/8.jpg', excerciseNum: 4 },
    ],
  ],
  24: [
    [
      { imgUrl: 'img/FriendsPlus/Page24/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page24/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page24/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page24/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page24/5.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page24/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page24/7.jpg', excerciseNum: 4 },
    ],
  ],
  25: [
    [
      { imgUrl: 'img/FriendsPlus/Page25/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page25/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page25/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page25/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page25/5.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page25/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page25/7.jpg', excerciseNum: 4 },
    ],
  ],
  26: [
    [
      { imgUrl: 'img/FriendsPlus/Page26/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page26/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page26/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page26/4.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page26/5.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page26/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page26/7.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page26/8.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page26/9.jpg', excerciseNum: 5 },
    ],
  ],
  27: [
    [
      { imgUrl: 'img/FriendsPlus/Page27/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page27/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page27/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page27/4.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page27/5.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page27/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page27/7.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page27/8.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page27/9.jpg', excerciseNum: 5 },
    ],
  ],
  28: [
    [
      { imgUrl: 'img/FriendsPlus/Page28/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page28/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page28/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page28/4.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page28/5.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page28/6.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page28/7.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page28/8.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page28/9.jpg', excerciseNum: 5 },
    ],
  ],
  29: [
    [
      { imgUrl: 'img/FriendsPlus/Page29/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page29/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page29/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page29/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page29/5.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page29/6.jpg', excerciseNum: 0 },
      { imgUrl: 'img/FriendsPlus/Page29/7.jpg', excerciseNum: 4 },
    ],
  ],
  30: [
    [
      { imgUrl: 'img/FriendsPlus/Page30/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page30/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page30/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page30/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page30/5.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page30/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page30/7.jpg', excerciseNum: 0 },
    ],
  ],
  31: [
    [
      { imgUrl: 'img/FriendsPlus/Page31/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page31/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page31/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page31/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page31/5.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page31/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page31/7.jpg', excerciseNum: 4 },
    ],
  ],
  32: [
    [
      { imgUrl: 'img/FriendsPlus/Page32/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page32/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page32/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page32/4.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page32/5.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page32/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page32/7.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page32/8.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page32/9.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page32/10.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page32/11.jpg', excerciseNum: 0 },
    ],
  ],
  33: [
    [
      { imgUrl: 'img/FriendsPlus/Page33/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page33/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page33/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page33/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page33/5.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page33/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page33/7.jpg', excerciseNum: 4 },
    ],
  ],
  34: [
    [
      { imgUrl: 'img/FriendsPlus/Page34/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page34/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page34/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page34/4.jpg', excerciseNum: 0 },
      { imgUrl: 'img/FriendsPlus/Page34/5.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page34/6.jpg', excerciseNum: 0 },
      { imgUrl: 'img/FriendsPlus/Page34/7.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page34/8.jpg', excerciseNum: 0 },
      { imgUrl: 'img/FriendsPlus/Page34/9.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page34/10.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page34/11.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page34/12.jpg', excerciseNum: 0 },
    ],
  ],
  35: [
    [
      { imgUrl: 'img/FriendsPlus/Page35/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page35/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page35/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page35/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page35/5.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page35/6.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page35/7.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page35/8.jpg', excerciseNum: 4 },
    ],
  ],
  36: [
    [
      { imgUrl: 'img/FriendsPlus/Page36/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page36/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page36/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page36/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page36/5.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page36/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page36/7.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page36/8.jpg', excerciseNum: 2 },
    ],
  ],
  37: [
    [
      { imgUrl: 'img/FriendsPlus/Page37/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page37/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page37/3.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page37/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page37/5.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page37/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page37/7.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page37/8.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page37/9.jpg', excerciseNum: 5 },
    ],
  ],
  38: [
    [
      { imgUrl: 'img/FriendsPlus/Page38/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page38/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page38/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page38/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page38/5.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page38/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page38/7.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page38/8.jpg', excerciseNum: 0 },
    ],
  ],
  39: [
    [
      { imgUrl: 'img/FriendsPlus/Page39/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page39/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page39/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page39/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page39/5.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page39/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page39/7.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page39/8.jpg', excerciseNum: 5 },
    ],
  ],
  40: [
    [
      { imgUrl: 'img/FriendsPlus/Page40/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page40/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page40/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page40/4.jpg', excerciseNum: 0 },
      { imgUrl: 'img/FriendsPlus/Page40/5.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page40/6.jpg', excerciseNum: 0 },
      { imgUrl: 'img/FriendsPlus/Page40/7.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page40/8.jpg', excerciseNum: 0 },
      { imgUrl: 'img/FriendsPlus/Page40/9.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page40/10.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page40/11.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page40/12.jpg', excerciseNum: 0 },
    ],
  ],
  41: [
    [
      { imgUrl: 'img/FriendsPlus/Page41/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page41/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page41/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page41/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page41/5.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page41/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page41/7.jpg', excerciseNum: 4 },
    ],
  ],
  42: [
    [
      { imgUrl: 'img/FriendsPlus/Page42/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page42/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page42/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page42/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page42/5.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page42/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page42/7.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page42/8.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page42/9.jpg', excerciseNum: 0 },
    ],
  ],
  43: [
    [
      { imgUrl: 'img/FriendsPlus/Page43/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page43/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page43/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page43/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page43/5.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page43/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page43/7.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page43/8.jpg', excerciseNum: 0 },
    ],
  ],
  44: [
    [
      { imgUrl: 'img/FriendsPlus/Page44/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page44/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page44/3.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page44/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page44/5.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page44/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page44/7.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page44/8.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page44/9.jpg', excerciseNum: 5 },
    ],
  ],
  45: [
    [
      { imgUrl: 'img/FriendsPlus/Page45/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page45/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page45/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page45/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page45/5.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page45/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page45/7.jpg', excerciseNum: 4 },
    ],
  ],
  46: [
    [
      { imgUrl: 'img/FriendsPlus/Page46/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page46/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page46/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page46/4.jpg', excerciseNum: 0 },
      { imgUrl: 'img/FriendsPlus/Page46/5.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page46/6.jpg', excerciseNum: 0 },
      { imgUrl: 'img/FriendsPlus/Page46/7.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page46/8.jpg', excerciseNum: 0 },
      { imgUrl: 'img/FriendsPlus/Page46/9.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page46/10.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page46/11.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page46/12.jpg', excerciseNum: 0 },
    ],
  ],
  47: [
    [
      { imgUrl: 'img/FriendsPlus/Page47/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page47/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page47/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page47/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page47/5.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page47/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page47/7.jpg', excerciseNum: 4 },
    ],
  ],
  48: [
    [
      { imgUrl: 'img/FriendsPlus/Page48/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page48/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page48/3.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page48/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page48/5.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page48/6.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page48/7.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page48/8.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page48/9.jpg', excerciseNum: 5 },
    ],
  ],
  49: [
    [
      { imgUrl: 'img/FriendsPlus/Page49/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page49/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page49/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page49/4.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page49/5.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page49/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page49/7.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page49/8.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page49/9.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page49/10.jpg', excerciseNum: 0 },
    ],
  ],
  50: [
    [
      { imgUrl: 'img/FriendsPlus/Page50/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page50/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page50/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page50/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page50/5.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page50/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page50/7.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page50/8.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page50/9.jpg', excerciseNum: 5 },
    ],
  ],
  51: [
    [
      { imgUrl: 'img/FriendsPlus/Page51/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page51/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page51/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page51/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page51/5.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page51/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page51/7.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page51/8.jpg', excerciseNum: 0 },
    ],
  ],
  52: [
    [
      { imgUrl: 'img/FriendsPlus/Page52/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page52/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page52/3.jpg', excerciseNum: 2 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page52/4.jpg', excerciseNum: 0 },
      { imgUrl: 'img/FriendsPlus/Page52/5.jpg', excerciseNum: 2 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page52/6.jpg', excerciseNum: 0 },
      { imgUrl: 'img/FriendsPlus/Page52/7.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page52/8.jpg', excerciseNum: 0 },
      { imgUrl: 'img/FriendsPlus/Page52/9.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page52/10.jpg', excerciseNum: 0 },
      { imgUrl: 'img/FriendsPlus/Page52/11.jpg', excerciseNum: 5 },
    ],
  ],
  53: [
    [
      { imgUrl: 'img/FriendsPlus/Page53/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page53/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page53/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page53/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page53/5.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page53/6.jpg', excerciseNum: 0 },
      { imgUrl: 'img/FriendsPlus/Page53/7.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page53/8.jpg', excerciseNum: 0 },
    ],
  ],
  54: [
    [
      { imgUrl: 'img/FriendsPlus/Page54/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page54/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page54/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page54/4.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page54/5.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page54/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page54/7.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page54/8.jpg', excerciseNum: 0 },
    ],
  ],
  55: [
    [
      { imgUrl: 'img/FriendsPlus/Page55/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page55/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page55/3.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page55/4.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page55/5.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page55/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page55/7.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page55/8.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page55/9.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page55/10.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page55/11.jpg', excerciseNum: 6 },
    ],
  ],
  56: [
    [
      { imgUrl: 'img/FriendsPlus/Page56/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page56/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page56/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page56/4.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page56/5.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page56/6.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page56/7.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page56/8.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page56/9.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page56/10.jpg', excerciseNum: 0 },
    ],
  ],
  57: [
    [
      { imgUrl: 'img/FriendsPlus/Page57/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page57/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page57/3.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page57/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page57/5.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page57/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page57/7.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page57/8.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page57/9.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page57/10.jpg', excerciseNum: 0 },
    ],
  ],
  58: [
    [
      { imgUrl: 'img/FriendsPlus/Page58/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page58/2.jpg', excerciseNum: 0 },
      { imgUrl: 'img/FriendsPlus/Page58/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page58/4.jpg', excerciseNum: 0 },
      { imgUrl: 'img/FriendsPlus/Page58/5.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page58/6.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page58/7.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page58/8.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page58/9.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page58/10.jpg', excerciseNum: 0 },
    ],
  ],
  59: [
    [
      { imgUrl: 'img/FriendsPlus/Page59/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page59/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page59/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page59/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page59/5.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page59/6.jpg', excerciseNum: 0 },
      { imgUrl: 'img/FriendsPlus/Page59/7.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page59/8.jpg', excerciseNum: 0 },
      { imgUrl: 'img/FriendsPlus/Page59/9.jpg', excerciseNum: 4 },
    ],
  ],
  60: [
    [
      { imgUrl: 'img/FriendsPlus/Page60/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page60/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page60/3.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page60/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page60/5.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page60/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page60/7.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page60/8.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page60/9.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page60/10.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page60/11.jpg', excerciseNum: 6 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page60/12.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page60/13.jpg', excerciseNum: 6 },
    ],
  ],
  61: [
    [
      { imgUrl: 'img/FriendsPlus/Page61/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page61/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page61/3.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page61/4.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page61/5.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page61/6.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page61/7.jpg', excerciseNum: 6 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page61/8.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page61/9.jpg', excerciseNum: 6 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page61/10.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page61/11.jpg', excerciseNum: 6 },
    ],
  ],
  62: [
    [
      { imgUrl: 'img/FriendsPlus/Page62/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page62/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page62/3.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page62/4.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page62/5.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page62/6.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page62/7.jpg', excerciseNum: 6 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page62/8.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page62/9.jpg', excerciseNum: 6 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page62/10.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page62/11.jpg', excerciseNum: 6 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page62/12.jpg', excerciseNum: 0 },
    ],
  ],
  63: [
    [
      { imgUrl: 'img/FriendsPlus/Page63/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page63/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page63/3.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page63/4.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page63/5.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page63/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page63/7.jpg', excerciseNum: 6 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page63/8.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page63/9.jpg', excerciseNum: 6 },
    ],
  ],
  64: [
    [
      { imgUrl: 'img/FriendsPlus/Page64/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page64/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page64/3.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page64/4.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page64/5.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page64/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page64/7.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page64/8.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page64/9.jpg', excerciseNum: 6 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page64/10.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page64/11.jpg', excerciseNum: 6 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page64/12.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page64/13.jpg', excerciseNum: 7 },
    ],
  ],
  65: [
    [
      { imgUrl: 'img/FriendsPlus/Page65/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page65/2.jpg', excerciseNum: 8 },
      { imgUrl: 'img/FriendsPlus/Page65/3.jpg', excerciseNum: 11 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page65/4.jpg', excerciseNum: 9 },
      { imgUrl: 'img/FriendsPlus/Page65/5.jpg', excerciseNum: 11 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page65/6.jpg', excerciseNum: 9 },
      { imgUrl: 'img/FriendsPlus/Page65/7.jpg', excerciseNum: 12 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page65/8.jpg', excerciseNum: 10 },
      { imgUrl: 'img/FriendsPlus/Page65/9.jpg', excerciseNum: 12 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page65/10.jpg', excerciseNum: 10 },
      { imgUrl: 'img/FriendsPlus/Page65/11.jpg', excerciseNum: 13 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page65/12.jpg', excerciseNum: 0 },
    ],
  ],
  66: [
    [
      { imgUrl: 'img/FriendsPlus/Page66/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page66/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page66/3.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page66/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page66/5.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page66/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page66/7.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page66/8.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page66/9.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page66/10.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page66/11.jpg', excerciseNum: 6 },
    ],
  ],
  67: [
    [
      { imgUrl: 'img/FriendsPlus/Page67/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page67/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page67/3.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page67/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page67/5.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page67/6.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page67/7.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page67/8.jpg', excerciseNum: 0 },
    ],
  ],
  68: [
    [
      { imgUrl: 'img/FriendsPlus/Page68/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page68/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page68/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page68/4.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page68/5.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page68/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page68/7.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page68/8.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page68/9.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page68/10.jpg', excerciseNum: 0 },
    ],
  ],
  69: [
    [
      { imgUrl: 'img/FriendsPlus/Page69/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page69/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page69/3.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page69/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page69/5.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page69/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page69/7.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page69/8.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page69/9.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page69/10.jpg', excerciseNum: 0 },
    ],
  ],
  70: [
    [
      { imgUrl: 'img/FriendsPlus/Page70/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page70/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page70/3.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page70/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page70/5.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page70/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page70/7.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page70/8.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page70/9.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page70/10.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page70/11.jpg', excerciseNum: 6 },
    ],
  ],
  71: [
    [
      { imgUrl: 'img/FriendsPlus/Page71/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page71/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page71/3.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page71/4.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page71/5.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page71/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page71/7.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page71/8.jpg', excerciseNum: 3 },
      { imgUrl: 'img/FriendsPlus/Page71/9.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page71/10.jpg', excerciseNum: 0 },
    ],
  ],
  72: [
    [
      { imgUrl: 'img/FriendsPlus/Page72/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page72/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page72/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page72/4.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page72/5.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page72/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page72/7.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page72/8.jpg', excerciseNum: 0 },
    ],
  ],
  73: [
    [
      { imgUrl: 'img/FriendsPlus/Page73/1.jpg', excerciseNum: 0 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page73/2.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page73/3.jpg', excerciseNum: 3 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page73/4.jpg', excerciseNum: 1 },
      { imgUrl: 'img/FriendsPlus/Page73/5.jpg', excerciseNum: 4 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page73/6.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page73/7.jpg', excerciseNum: 5 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page73/8.jpg', excerciseNum: 2 },
      { imgUrl: 'img/FriendsPlus/Page73/9.jpg', excerciseNum: 6 },
    ],
    [
      { imgUrl: 'img/FriendsPlus/Page73/10.jpg', excerciseNum: 0 },
    ],
  ],
  74: [
    [
      { imgUrl: 'img/FriendsPlus/Page74/1.jpg', excerciseNum: 0 },
    ],
  ],
  75: [
    [
      { imgUrl: 'img/FriendsPlus/Page75/1.jpg', excerciseNum: 0 },
    ],
  ],
  76: [
    [
      { imgUrl: 'img/FriendsPlus/Page76/1.jpg', excerciseNum: 0 },
    ],
  ],
  77: [
    [
      { imgUrl: 'img/FriendsPlus/Page77/1.jpg', excerciseNum: 0 },
    ],
  ],
  78: [
    [
      { imgUrl: 'img/FriendsPlus/Page78/1.jpg', excerciseNum: 0 },
    ],
  ],
  79: [
    [
      { imgUrl: 'img/FriendsPlus/Page79/1.jpg', excerciseNum: 0 },
    ],
  ],
  80: [
    [
      { imgUrl: 'img/FriendsPlus/Page80/1.jpg', excerciseNum: 0 },
    ],
  ],
  81: [[{ imgUrl: 'img/FriendsPlus/ListPage/81.jpg', excerciseNum: 0 }]],
}
