import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';

import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
  1: { // Exercise num
    unit: 'Language Focus Practice Unit 3',
    id: 'WB6-LFP3-P68-E1',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page68/E1/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page68/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page68/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page68/E1/3.jpg', input: true, answer: "the best" },
        { url: 'img/FriendsPlus/Page68/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page68/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page68/E1/6.jpg', input: true, answer: "the oldest" },
        { url: 'img/FriendsPlus/Page68/E1/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page68/E1/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page68/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page68/E1/10.jpg', input: true, answer: "the strangest" },
        { url: 'img/FriendsPlus/Page68/E1/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page68/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page68/E1/13.jpg', input: true, answer: "the most beautiful" },
        { url: 'img/FriendsPlus/Page68/E1/14.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page68/E1/15.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page68/E1/16.jpg' },
        { url: 'img/FriendsPlus/Page68/E1/17.jpg', input: true, answer: "the most modern" },
        { url: 'img/FriendsPlus/Page68/E1/18.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page68/E1/19.jpg' },
        { url: 'img/FriendsPlus/Page68/E1/20.jpg', input: true, answer: "the noisiest" },
        { url: 'img/FriendsPlus/Page68/E1/21.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page68/E1/22.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page68/E1/23.jpg' },
        { url: 'img/FriendsPlus/Page68/E1/24.jpg', input: true, answer: "the most boring " },
        { url: 'img/FriendsPlus/Page68/E1/25.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page68/E1/26.jpg' },
        { url: 'img/FriendsPlus/Page68/E1/27.jpg', input: true, answer: "the heaviest" },
        { url: 'img/FriendsPlus/Page68/E1/28.jpg' },
      ],
      [
        // Column13
        { url: 'img/FriendsPlus/Page68/E1/29.jpg' },
      ],



    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  2: { // Exercise num
    unit: 'Language Focus Practice Unit 3',
    id: 'WB6-LFP3-P68-E2',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page68/E2/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page68/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page68/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page68/E2/3.jpg', input: true, answer: "Bag C is the most expensive." },
        { url: 'img/FriendsPlus/Page68/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page68/E2/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page68/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page68/E2/7.jpg', input: true, answer: "Bag B is the smallest." },
        { url: 'img/FriendsPlus/Page68/E2/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page68/E2/9.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page68/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page68/E2/11.jpg', input: true, answer: "Bag A is the biggest." },
        { url: 'img/FriendsPlus/Page68/E2/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page68/E2/13.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page68/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page68/E2/15.jpg', input: true, answer: "Bag C is the best." },
        { url: 'img/FriendsPlus/Page68/E2/16.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page68/E2/17.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page68/E2/18.jpg' },
        { url: 'img/FriendsPlus/Page68/E2/19.jpg', input: true, answer: "Bag A is the worst." },
        { url: 'img/FriendsPlus/Page68/E2/20.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page68/E2/21.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  3: { // Exercise num
    unit: 'Language Focus Practice Unit 3',
    id: 'WB6-LFP3-P68-E3',
    audio: '',
    video: '',
    component: T1,
    exerciseKey: 'img/FriendsPlus/Page68/E3/Key/answerKey.png',
    titleImage: 'img/FriendsPlus/Page68/E3/title.jpg',
    //titleQuestion: [{ num: '2', title: 'Read the text. What is Hannah’s project about? What is she learning about at school?', color: "#4D3D9B" }],
    questionImage: [],
    questions: [
      {
        title: "<p>Crocodiles can’t swim. ✔</p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>Crocodiles can swim.</u></p>" +

          "<p><span style='font-weight:600'>1</span> Kemal can play a musical instrument. ? # </p>" +
          "<p><span style='font-weight:600'>2</span> My uncle can’t speak English. ✔ # </p>" +
          "<p><span style='font-weight:600'>3</span> Our cat can run very fast. ✘ # </p>" +
          "<p><span style='font-weight:600'>4</span> My grandmother can’t make clothes. ✔ # </p>" +
          "<p><span style='font-weight:600'>5</span> Elephants can survive in a very cold place. ✘ # </p>" +
          "<p><span style='font-weight:600'>6</span> Frogs can jump high. ? # </p>",

        answer: [
          "Can Kemal play a musical instrument?",
          "My uncle can speak English.",
          "Our cat can't run very fast.",
          "My grandmother can make clothes.",
          "Elephants can't survive in a very cold place.",
          "Can frogs jump high?",



        ],
        type: 'longAnwser'
      },
    ]
  },
  4: {
    unit: 'Language Focus Practice Unit 3',
    id: 'WB6-LFP3-P68-E4',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page68/E4/Key/answerKey.png',
    component: DesignUnderLine,
    totalInput: 5,
    // titleQuestion: [{ num: '2', title: `Listen to the street interviews and tick (✔) the correct box. What is the person’s favourite building?`, star: 2, color: '#F68025' }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page68/E4/03.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page68/E4/05.jpg' },
        { url: 'img/FriendsPlus/Page68/E4/06.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page68/E4/07.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page68/E4/08.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page68/E4/09.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page68/E4/10.jpg' },
        { url: 'img/FriendsPlus/Page68/E4/11.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page68/E4/12.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page68/E4/13.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page68/E4/14.jpg' },
        { url: 'img/FriendsPlus/Page68/E4/15.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page68/E4/16.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page68/E4/17.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page68/E4/18.jpg' },
        { url: 'img/FriendsPlus/Page68/E4/19.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page68/E4/20.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page68/E4/21.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page68/E4/22.jpg' },
        { url: 'img/FriendsPlus/Page68/E4/23.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page68/E4/24.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page68/E4/25.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  5: { // Exercise num
    unit: 'Language Focus Practice Unit 3',
    id: 'WB6-LFP3-P68-E5',
    audio: '',
    video: '',
    component: T1,
    exerciseKey: 'img/FriendsPlus/Page68/E5/Key/answerKey.png',
    titleImage: 'img/FriendsPlus/Page68/E5/title.jpg',
    //titleQuestion: [{ num: '2', title: 'Read the text. What is Hannah’s project about? What is she learning about at school?', color: "#4D3D9B" }],
    questionImage: [],
    questions: [
      {
        title: "<p>heavy / a camel / ?</p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>How heavy is a camel?</u></p>" +
          "<p>far / a bear / walk / in one day / ?</p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>How far can a bear walk in one day?</u></p>" +

          "<p><span style='font-weight:600'>1</span> high / this mountain / ? # </p>" +
          "<p><span style='font-weight:600'>2</span> fast / a whale / swim / ? # </p>" +
          "<p><span style='font-weight:600'>3</span> long / a scorpion / ? # </p>" +
          "<p><span style='font-weight:600'>4</span> big / the national park / ? # </p>" +
          "<p><span style='font-weight:600'>5</span> far / a giraffe / see / ? # </p>" +
          "<p><span style='font-weight:600'>6</span> cold / the North Pole / ? # </p>",

        answer: [
          "How high is this mountain?",
          "How fast can a whale swim?",
          "How long is a scorpion?",
          "How big is the national park?",
          "How far can a giraffe see?",
          "How cold is the North Pole?",



        ],
        type: 'longAnwser'
      },
    ]
  },


}

export default json;