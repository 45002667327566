
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
    1: { // Exercise num
        unit: 'Language Focus Practice Unit 6',
        id: 'WB6-LFP6-P71-E1',
        audio: '',
        video: '',
        fontSize: 21,
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page71/E1/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page71/E1/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page71/E1/2.jpg' },
                { url: 'img/FriendsPlus/Page71/E1/3.jpg', input: true, answer: "There were" },
                { url: 'img/FriendsPlus/Page71/E1/4.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page71/E1/5.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page71/E1/6.jpg' },
                { url: 'img/FriendsPlus/Page71/E1/7.jpg', input: true, answer: "there was" },
                { url: 'img/FriendsPlus/Page71/E1/8.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page71/E1/9.jpg' },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page71/E1/10.jpg' },
                { url: 'img/FriendsPlus/Page71/E1/11.jpg', input: true, answer: "there weren't" },
                { url: 'img/FriendsPlus/Page71/E1/12.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page71/E1/13.jpg' },
                { url: 'img/FriendsPlus/Page71/E1/14.jpg', input: true, answer: "there was" },
                { url: 'img/FriendsPlus/Page71/E1/15.jpg' },
            ],
            [
                // Column8
                { url: 'img/FriendsPlus/Page71/E1/16.jpg' },
            ],
            [
                // Column9
                { url: 'img/FriendsPlus/Page71/E1/17.jpg' },
                { url: 'img/FriendsPlus/Page71/E1/18.jpg', input: true, answer: "There wasn't" },
                { url: 'img/FriendsPlus/Page71/E1/19.jpg' },
            ],
            [
                // Column10
                { url: 'img/FriendsPlus/Page71/E1/20.jpg' },
                { url: 'img/FriendsPlus/Page71/E1/21.jpg', input: true, answer: "there was" },
                { url: 'img/FriendsPlus/Page71/E1/22.jpg' },
            ],
            [
                // Column11
                { url: 'img/FriendsPlus/Page71/E1/23.jpg' },
                { url: 'img/FriendsPlus/Page71/E1/24.jpg', input: true, answer: "There weren't" },
                { url: 'img/FriendsPlus/Page71/E1/25.jpg' },
            ],

        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    2: { // Exercise num
        unit: 'Language Focus Practice Unit 6',
        id: 'WB6-LFP6-P71-E2',
        audio: '',
        video: '',
        component: T1,
        exerciseKey: 'img/FriendsPlus/Page71/E2/Key/answerKey.png',
        titleImage: 'img/FriendsPlus/Page71/E2/title.jpg',
        //titleQuestion: [{ num: '2', title: 'Read the text. What is Hannah’s project about? What is she learning about at school?', color: "#4D3D9B" }],
        questionImage: [],
        questions: [
            {
                title: "<p>The race isn’t very exciting.</p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>The race wasn’t very exciting.</u></p>" +

                    "<p><span style='font-weight:600'>1</span>  He’s a famous skiing champion. #</p>" +
                    "<p><span style='font-weight:600'>2</span>  All the players are very good. #</p>" +
                    "<p><span style='font-weight:600'>3</span>  She isn’t in the gymnastics competition. #</p>" +
                    "<p><span style='font-weight:600'>4</span>  This football book isn’t expensive. #</p>" +
                    "<p><span style='font-weight:600'>5</span>  Are you excited about the match? #</p>" +
                    "<p><span style='font-weight:600'>6</span>  Is he interested in that golf magazine? #</p>",

                answer: [
                    "He was a famous skiing champion.",
                    "All the players were very good.",
                    "She wasn't in the gymnastics competition.",
                    "This football book wasn't expensive.",
                    "Were you excited about the match?",
                    "Was he interested in that golf magazine?",
                ],
                type: 'longAnwser'
            },
        ]
    },
    3: { // Exercise num
        unit: 'Language Focus Practice Unit 6',
        id: 'WB6-LFP6-P71-E3',
        audio: '',
        video: '',
        component: T1,
        exerciseKey: 'img/FriendsPlus/Page71/E3/Key/answerKey.png',
        titleImage: 'img/FriendsPlus/Page71/E3/title.jpg',
        //titleQuestion: [{ num: '2', title: 'Read the text. What is Hannah’s project about? What is she learning about at school?', color: "#4D3D9B" }],
        questionImage: [],
        questions: [
            {
                title: "<p>that tennis match / very exciting ✔</p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>That tennis match was very exciting.</u></p>" +

                    "<p><span style='font-weight:600'>1</span>  you / very fast in the race ✔ #</p>" +
                    "<p><span style='font-weight:600'>2</span>  our teacher / really happy this morning ✔ #</p>" +
                    "<p><span style='font-weight:600'>3</span>  Merve and Esra / in the park earlier ✘ #</p>" +
                    "<p><span style='font-weight:600'>4</span>  the journey / very long ✘ #</p>" +
                    "<p><span style='font-weight:600'>5</span>  I / really tired / this afternoon ✔ #</p>" +
                    "<p><span style='font-weight:600'>6</span>  Ryan / at school today ✘ #</p>",

                answer: [
                    "You were very fast in the race.",
                    "Our teacher was really happy this morning.",
                    "Merve and Esra weren't in the park earlier.",
                    "The journey wasn't very long.",
                    "I was really tired this afternoon.",
                    "Ryan wasn't at school today.",
                ],
                type: 'longAnwser'
            },
        ]
    },
    4: { // Exercise num
        unit: 'Language Focus Practice Unit 6',
        id: 'WB6-LFP6-P71-E4',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page71/E4/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page71/E4/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page71/E4/2.jpg' },
                { url: 'img/FriendsPlus/Page71/E4/3.jpg', input: true, answer: "was" },
                { url: 'img/FriendsPlus/Page71/E4/4.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page71/E4/5.jpg' },
                { url: 'img/FriendsPlus/Page71/E4/6.jpg', input: true, answer: "competed" },
                { url: 'img/FriendsPlus/Page71/E4/7.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page71/E4/8.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page71/E4/9.jpg' },
                { url: 'img/FriendsPlus/Page71/E4/10.jpg', input: true, answer: "travelled" },
                { url: 'img/FriendsPlus/Page71/E4/11.jpg' },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page71/E4/12.jpg' },
                { url: 'img/FriendsPlus/Page71/E4/13.jpg', input: true, answer: "went" },
                { url: 'img/FriendsPlus/Page71/E4/14.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page71/E4/15.jpg' },
            ],
            [
                // Column8
                { url: 'img/FriendsPlus/Page71/E4/16.jpg' },
                { url: 'img/FriendsPlus/Page71/E4/17.jpg', input: true, answer: "practised" },
                { url: 'img/FriendsPlus/Page71/E4/18.jpg' },
            ],
            [
                // Column9
                { url: 'img/FriendsPlus/Page71/E4/19.jpg' },
                { url: 'img/FriendsPlus/Page71/E4/20.jpg', input: true, answer: "had" },
                { url: 'img/FriendsPlus/Page71/E4/21.jpg' },
            ],
            [
                // Column10
                { url: 'img/FriendsPlus/Page71/E4/22.jpg' },
                { url: 'img/FriendsPlus/Page71/E4/23.jpg', input: true, answer: "were" },
                { url: 'img/FriendsPlus/Page71/E4/24.jpg' },
            ],
            [
                // Column11
                { url: 'img/FriendsPlus/Page71/E4/25.jpg' },
                { url: 'img/FriendsPlus/Page71/E4/26.jpg', input: true, answer: "won" },
                { url: 'img/FriendsPlus/Page71/E4/27.jpg' },
            ],
            [
                // Column12
                { url: 'img/FriendsPlus/Page71/E4/28.jpg' },
                { url: 'img/FriendsPlus/Page71/E4/29.jpg', input: true, answer: "wasn't" },
                { url: 'img/FriendsPlus/Page71/E4/30.jpg' },
            ],
            [
                // Column13
                { url: 'img/FriendsPlus/Page71/E4/31.jpg' },
                { url: 'img/FriendsPlus/Page71/E4/32.jpg', input: true, answer: "were" },
                { url: 'img/FriendsPlus/Page71/E4/33.jpg' },
            ],
            [
                // Column14
                { url: 'img/FriendsPlus/Page71/E4/34.jpg' },
                { url: 'img/FriendsPlus/Page71/E4/35.jpg', input: true, answer: "became" },
                { url: 'img/FriendsPlus/Page71/E4/36.jpg' },
            ],
            [
                // Column15
                { url: 'img/FriendsPlus/Page71/E4/37.jpg' },
                { url: 'img/FriendsPlus/Page71/E4/38.jpg', input: true, answer: "weren't" },
                { url: 'img/FriendsPlus/Page71/E4/39.jpg' },
            ],
            [
                // Column16
                { url: 'img/FriendsPlus/Page71/E4/40.jpg' },
            ],
            [
                // Column17
                { url: 'img/FriendsPlus/Page71/E4/41.jpg' },
                { url: 'img/FriendsPlus/Page71/E4/42.jpg', input: true, answer: "came" },
                { url: 'img/FriendsPlus/Page71/E4/43.jpg' },
            ],
            [
                // Column18
                { url: 'img/FriendsPlus/Page71/E4/44.jpg' },
                { url: 'img/FriendsPlus/Page71/E4/45.jpg', input: true, answer: "was" },
                { url: 'img/FriendsPlus/Page71/E4/46.jpg' },
            ],
            [
                // Column19
                { url: 'img/FriendsPlus/Page71/E4/47.jpg' },
            ],

        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    5: { // Exercise num
        unit: 'Language Focus Practice Unit 6',
        id: 'WB6-LFP6-P71-E5',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page71/E5/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page71/E5/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page71/E5/2.jpg' },
                { url: 'img/FriendsPlus/Page71/E5/3.jpg', input: true, answer: "ago" },
                { url: 'img/FriendsPlus/Page71/E5/4.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page71/E5/5.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page71/E5/6.jpg' },
                { url: 'img/FriendsPlus/Page71/E5/7.jpg', input: true, answer: "last" },
                { url: 'img/FriendsPlus/Page71/E5/8.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page71/E5/9.jpg' },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page71/E5/10.jpg' },
                { url: 'img/FriendsPlus/Page71/E5/11.jpg', input: true, answer: "when" },
                { url: 'img/FriendsPlus/Page71/E5/12.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page71/E5/13.jpg' },
            ],
            [
                // Column8
                { url: 'img/FriendsPlus/Page71/E5/14.jpg' },
                { url: 'img/FriendsPlus/Page71/E5/15.jpg', input: true, answer: "In" },
                { url: 'img/FriendsPlus/Page71/E5/16.jpg' },
            ],
            [
                // Column9
                { url: 'img/FriendsPlus/Page71/E5/17.jpg' },
            ],
            [
                // Column10
                { url: 'img/FriendsPlus/Page71/E5/18.jpg' },
                { url: 'img/FriendsPlus/Page71/E5/19.jpg', input: true, answer: "week" },
                { url: 'img/FriendsPlus/Page71/E5/20.jpg' },
            ],
            [
                // Column11
                { url: 'img/FriendsPlus/Page71/E5/21.jpg' },
            ],
            [
                // Column12
                { url: 'img/FriendsPlus/Page71/E5/22.jpg' },
                { url: 'img/FriendsPlus/Page71/E5/23.jpg', input: true, answer: "October" },
                { url: 'img/FriendsPlus/Page71/E5/24.jpg' },
            ],

        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },



}

export default json;