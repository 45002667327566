import LT1 from '../../components/ExcerciseTypes/LineTo/LT1';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  1: { // Exercise num
    unit: 'Cumulative review Starter unit-Unit 8',
    id: 'WB6-CR4-P63-E1',
    audio: '',
    video: '',
    component: T2,
    exerciseKey: '',
    titleImage: 'img/FriendsPlus/Page63/E1/title.jpg',
    //titleQuestion: [{ num: '4', title: '', color: '#19BED7', star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
  2: { // Exercise num
    unit: 'Cumulative review Starter unit-Unit 8',
    id: 'WB6-CR4-P63-E2',
    audio: '',
    video: '',
    imgSize: 700,
    component: T1,
    exerciseKey: 'img/FriendsPlus/Page63/E2/Key/answerKey.png',
    titleImage: 'img/FriendsPlus/Page63/E2/title.jpg',
    //titleQuestion: [{ num: '2', title: 'Read the text. What is Hannah’s project about? What is she learning about at school?', color: "#4D3D9B" }],
    questionImage: [],
    questions: [
      {
        title:

          "<p>#</p>",

        answer: [
          "Natalie is going to have a city tour without a tourguide, use a map and go to the opera house.",
        ],
        type: 'longAnwser'
      },
    ]
  },
  3: { // Exercise num
    unit: 'Cumulative review Starter unit-Unit 8',
    id: 'WB6-CR4-P63-E3',
    audio: '',
    video: '',
    component: LT1,
    exerciseKey: 'img/FriendsPlus/Page63/E3/key.png',
    titleImage: 'img/FriendsPlus/Page63/E3/1.jpg',
    // titleQuestion: [{ num: '3', title: 'Read the text again. Match sentence halves 1-5 with a-e.' }],
    questionImage: [],
    questions: [
      {
        right: [
          {
            id: 1,
            text: "1. Yesterday I tried my first",
          },
          {
            id: 2,
            text: "2. It was very",
          },
          {
            id: 3,
            text: "3. At 9 a.m we're going to",
          },
          {
            id: 4,
            text: "4. After that we'll have",
          },
          {
            id: 5,
            text: "5. The weather will be",
          },
        ],
        left: [
          {
            id: 1,
            text: "a. delicious.",
          },
          {
            id: 2,
            text: "b. some Italian ice cream.",
          },
          {
            id: 3,
            text: "c. cloudy at first, but it won't rain.",
          },
          {
            id: 4,
            text: "d. authentic ravioli.",
          },
          {
            id: 5,
            text: "e. have a city tour without a tour guide.",
          },
        ],
        answer: [
          {
            right: 1,
            left: 4,
          },
          {
            right: 2,
            left: 1,
          },
          {
            right: 3,
            left: 5,
          },
          {
            right: 4,
            left: 2,
          },
          {
            right: 5,
            left: 3,
          },
        ],
        type: ''
      },
    ]
  },
  4: { // Exercise num
    unit: 'Cumulative review Starter unit-Unit 8',
    id: 'WB6-CR4-P63-E4',
    audio: 'img/FriendsPlus/Page63/E4/Audio/Friends Plus for Vietnam G6 WB Track 13.mp3',
    video: '',
    imgSize: 800,
    component: T1,
    exerciseKey: 'img/FriendsPlus/Page63/E4/Key/answerKey.png',
    titleImage: 'img/FriendsPlus/Page63/E4/title.jpg',
    //titleQuestion: [{ num: '2', title: 'Read the text. What is Hannah’s project about? What is she learning about at school?', color: "#4D3D9B" }],
    questionImage: [],
    questions: [
      {
        title:

          "<p>#</p>",

        answer: [
          "He thinks it will be sunny.",
        ],
        type: 'longAnwser'
      },
    ]
  },
  5: { // Exercise num
    unit: 'Cumulative review Starter unit-Unit 8',
    id: 'WB6-CR4-P63-E5',
    audio: 'img/FriendsPlus/Page63/E5/Audio/Friends Plus for Vietnam G6 WB Track 13.mp3',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page63/E5/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page63/E5/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page63/E5/2.jpg' },
        { url: 'img/FriendsPlus/Page63/E5/3.jpg', input: true, answer: "chicken sandwiches" },
        { url: 'img/FriendsPlus/Page63/E5/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page63/E5/5.jpg' },
        { url: 'img/FriendsPlus/Page63/E5/6.jpg', input: true, answer: "nuts" },
        { url: 'img/FriendsPlus/Page63/E5/7.jpg' },
        { url: 'img/FriendsPlus/Page63/E5/8.jpg', input: true, answer: "sweets" },
        { url: 'img/FriendsPlus/Page63/E5/9.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page63/E5/10.jpg' },
        { url: 'img/FriendsPlus/Page63/E5/11.jpg', input: true, answer: "apples" },
        { url: 'img/FriendsPlus/Page63/E5/12.jpg' },
        { url: 'img/FriendsPlus/Page63/E5/13.jpg', input: true, answer: "grapes" },
        { url: 'img/FriendsPlus/Page63/E5/14.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page63/E5/15.jpg' },
        { url: 'img/FriendsPlus/Page63/E5/16.jpg', input: true, answer: "juice" },
        { url: 'img/FriendsPlus/Page63/E5/17.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  6: { // Exercise num
    unit: 'Cumulative review Starter unit-Unit 8',
    id: 'WB6-CR4-P63-E6',
    audio: '',
    video: '',
    component: T2,
    exerciseKey: '',
    titleImage: 'img/FriendsPlus/Page63/E6/title.jpg',
    //titleQuestion: [{ num: '4', title: '', color: '#19BED7', star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },

}

export default json;
