
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import S6 from '../../components/ExcerciseTypes/Select/Select6';

const json = {
  1: { // Exercise num
    unit: 'Unit 1',
    id: 'WB6-U1-P12-E1',
    audio: '',
    video: '',
    component: S6,
    width: 200,
    exerciseKey: 'img/FriendsPlus/Page12/E1/Key/answerKey.png',
    titleImage: 'img/FriendsPlus/Page12/E1/title.png',
    answerKey: '',
    titleQuestion: [{ num: '1', star: 1, title: 'Look at the pictures. Correct the words.' }],
    questions: [
      {
        // no: "0",
        // textLeft: "market",
        text: 'monument',
        image: "img/FriendsPlus/Page12/E1/0.png",
      },
      {
        no: "1",
        textLeft: "market",
        text: 'library',
        image: "img/FriendsPlus/Page12/E1/1.png",
      },
      {
        no: "2",
        textLeft: "cinema",
        text: 'train station',
        image: "img/FriendsPlus/Page12/E1/2.png",
      },
      {
        no: "3",
        textLeft: "park",
        text: 'market',
        image: "img/FriendsPlus/Page12/E1/3.png",
      },
      {
        no: "4",
        textLeft: "train station",
        text: 'bridge',
        image: "img/FriendsPlus/Page12/E1/4.png",
      },
      {
        no: "5",
        textLeft: "monument",
        text: 'cinema',
        image: "img/FriendsPlus/Page12/E1/5.png",
      },
      {
        no: "6",
        textLeft: "library",
        text: 'bus station',
        image: "img/FriendsPlus/Page12/E1/6.png",
      },
      {
        no: "7",
        textLeft: "bus station",
        text: 'park',
        image: "img/FriendsPlus/Page12/E1/7.png",
      },
    ]
  },

  2: { // Exercise num
    unit: 'Unit 1',
    id: 'WB6-U1-P12-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page12/E2/Key/Key.jpg',
    component: D1,
    fontSize: 24,
    titleImage: 'http://',
    titleQuestion: [{ num: '2', title: 'Read the clues and write the places.', color: "#66A0B4", star: 2 }],
    questionImage: [ // Row
      // [
      //   { url: 'img/FriendsPlus/Page12/E2/1.jpg' },
      // ],
      // [
      //   { url: 'img/FriendsPlus/Page12/E2/2.jpg' },
      // ],
      // [
      //   { url: 'img/FriendsPlus/Page12/E2/3.jpg' },
      // ],
      // [
      //   { url: 'img/FriendsPlus/Page12/E2/4.jpg' },
      //   { url: 'img/FriendsPlus/Page12/E2/5.jpg' },
      // ],
      // [
      //   { url: 'img/FriendsPlus/Page12/E2/6.jpg' },
      // ],
      // [
      //   { url: 'img/FriendsPlus/Page12/E2/7.jpg' },
      //   { url: 'img/FriendsPlus/Page12/E2/8.jpg', input: true, answer: "school" },
      //   { url: 'img/FriendsPlus/Page12/E2/9.jpg' },
      // ],
      // [
      //   { url: 'img/FriendsPlus/Page12/E2/10.jpg' },
      // ],
      // [
      //   { url: 'img/FriendsPlus/Page12/E2/11.jpg' },
      //   { url: 'img/FriendsPlus/Page12/E2/12.jpg', input: true, answer: "café/cafe" },
      //   { url: 'img/FriendsPlus/Page12/E2/13.jpg' },
      // ],
      // [
      //   { url: 'img/FriendsPlus/Page12/E2/14.jpg' },
      // ],
      // [
      //   { url: 'img/FriendsPlus/Page12/E2/15.jpg' },
      //   { url: 'img/FriendsPlus/Page12/E2/16.jpg', input: true, answer: "theatre" },
      //   { url: 'img/FriendsPlus/Page12/E2/17.jpg' },
      // ],
      // [
      //   { url: 'img/FriendsPlus/Page12/E2/18.jpg' },
      // ],
      // [
      //   { url: 'img/FriendsPlus/Page12/E2/19.jpg' },
      //   { url: 'img/FriendsPlus/Page12/E2/20.jpg', input: true, answer: "flats" },
      //   { url: 'img/FriendsPlus/Page12/E2/21.jpg' },
      // ],
      // [
      //   { url: 'img/FriendsPlus/Page12/E2/22.jpg' },
      // ],
      // [
      //   { url: 'img/FriendsPlus/Page12/E2/23.jpg' },
      // ],
      // [
      //   { url: 'img/FriendsPlus/Page12/E2/24.jpg' },
      //   { url: 'img/FriendsPlus/Page12/E2/25.jpg', input: true, answer: "street" },
      //   { url: 'img/FriendsPlus/Page12/E2/26.jpg' },
      // ],
      // [
      //   { url: 'img/FriendsPlus/Page12/E2/27.jpg' },

      // ],
      // [
      //   { url: 'img/FriendsPlus/Page12/E2/28.jpg' },
      //   { url: 'img/FriendsPlus/Page12/E2/29.jpg', input: true, answer: "sports centre" },
      //   { url: 'img/FriendsPlus/Page12/E2/30.jpg' },
      // ],
      // [
      //   { url: 'img/FriendsPlus/Page12/E2/31.jpg' },
      // ],
      // [
      //   { url: 'img/FriendsPlus/Page12/E2/32.jpg' },
      //   { url: 'img/FriendsPlus/Page12/E2/33.jpg', input: true, answer: "shopping centre" },
      //   { url: 'img/FriendsPlus/Page12/E2/34.jpg' },
      // ],
      // [
      //   { url: 'img/FriendsPlus/Page12/E2/35.jpg' },
      // ],
      // [
      //   { url: 'img/FriendsPlus/Page12/E2/36.jpg' },
      //   { url: 'img/FriendsPlus/Page12/E2/37.jpg', input: true, answer: "office building" },
      //   { url: 'img/FriendsPlus/Page12/E2/38.jpg' },
      // ],
      // [
      //   { url: 'img/FriendsPlus/Page12/E2/39.jpg' },
      // ],
      [
        // Column1
        { url: 'img/FriendsPlus/Page12/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page12/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page12/E2/3.jpg', input: true, answer: "school" },
        { url: 'img/FriendsPlus/Page12/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page12/E2/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page12/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page12/E2/7.jpg', input: true, answer: "café" },
        { url: 'img/FriendsPlus/Page12/E2/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page12/E2/9.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page12/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page12/E2/11.jpg', input: true, answer: "theatre" },
        { url: 'img/FriendsPlus/Page12/E2/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page12/E2/13.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page12/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page12/E2/15.jpg', input: true, answer: "flats" },
        { url: 'img/FriendsPlus/Page12/E2/16.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page12/E2/17.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page12/E2/18.jpg' },
        { url: 'img/FriendsPlus/Page12/E2/19.jpg', input: true, answer: "street" },
        { url: 'img/FriendsPlus/Page12/E2/20.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page12/E2/21.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page12/E2/22.jpg' },
        { url: 'img/FriendsPlus/Page12/E2/23.jpg', input: true, answer: "sports centre" },
        { url: 'img/FriendsPlus/Page12/E2/24.jpg' },
      ],
      [
        // Column13
        { url: 'img/FriendsPlus/Page12/E2/25.jpg' },
      ],
      [
        // Column14
        { url: 'img/FriendsPlus/Page12/E2/26.jpg' },
        { url: 'img/FriendsPlus/Page12/E2/27.jpg', input: true, answer: "shopping centre" },
        { url: 'img/FriendsPlus/Page12/E2/28.jpg' },
      ],
      [
        // Column15
        { url: 'img/FriendsPlus/Page12/E2/29.jpg' },
      ],
      [
        // Column16
        { url: 'img/FriendsPlus/Page12/E2/30.jpg' },
        { url: 'img/FriendsPlus/Page12/E2/31.jpg', input: true, answer: "office building" },
        { url: 'img/FriendsPlus/Page12/E2/32.jpg' },
      ],
      [
        // Column17
        { url: 'img/FriendsPlus/Page12/E2/33.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  3: { // Exercise num
    unit: 'Unit 1',
    id: 'WB6-U1-P12-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page12/E3/Key/Key.png',
    titleImage: 'img/FriendsPlus/Page12/E3/title.jpg',
    imgSize: '70%',
    inputSize: 650,
    // titleQuestion: [{ num: '3', title: 'Look at the map. Write five more sentences about things you can see.', color: '#66A0B4', star: 2 }],
    questionImage: [],
    questions: [
      {
        title: "<p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>There's a cafe</u></p>" +
          "<p style='font-weight:500'>1.  #. </p>" +
          "<p style='font-weight:500'>2.  #. </p>" +
          "<p style='font-weight:500'>3.  #. </p>" +
          "<p style='font-weight:500'>4.  #. </p>" +
          "<p style='font-weight:500'>5.  #. </p>",
        answer: [
          "There's a bridge",
          "There's a market",
          "There's a shop",
          "There's a square / There's a park",
          "There's a hotel",
        ],
        type: 'longAnwserInLine'
      },
    ]
  },

  4: { // Exercise num
    unit: 'Unit 1',
    id: 'WB6-U1-P12-E4',
    audio: '',
    video: '',
    component: T2,
    exerciseKey: '',
    imgSize: '80%',
    titleImage: '',
    titleQuestion: [{ num: '4', title: 'Write sentences about the capital city of your country or a big city near you.', color: '#66A0B4', star: 3 }],
    questionImage: [],
    questions: [
      {
        title: "<p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>There’s a big new office building. It’s opposite the station.</u></p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}

export default json;