import WordsFind from "../../components/ExcerciseTypes/WordsFind";
// import WordsFind from "../../components/ExcerciseTypes/WordsFind2";
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T5 from '../../components/ExcerciseTypes/TypeIn/T5';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
  // 1: { // Exercise num
  //   unit : 'Unit 4',
  //   id : 'WB6-U4-P30-E1',
  //   audio: '',
  //   video: '',
  //   component: WordsFind,
  //   // exerciseKey: 'img/FriendsPlus/Page30/E1/key.png',
  //   titleImage: 'img/FriendsPlus/Page30/E1/1.png',
  //   titleQuestion: [{ num: '1', title: "Find six more school subjects in the wordsearch.", star: 1, color: '#5C97A9' }],
  //   questionImage: [],
  //   questions: [
  //     {
  //       title: 'positive',
  //       answerInputs: ['art', 'english', 'geography', 'history']
  //     },
  //     {
  //       title: 'negative',
  //       answerInputs: ['ict', 'maths', 'science']
  //     }
  //   ]
  // },
  1: { // Exercise num
    unit: 'Unit 4',
    id: 'WB6-U4-P30-E1',
    audio: '',
    video: '',
    component: WordsFind,
    exerciseKey: 'img/FriendsPlus/Page30/E1/key.png',
    titleImage: 'img/FriendsPlus/Page30/E1/1.png',
    titleQuestion: [{ num: '1', title: "Find six more school subjects in the wordsearch.", star: 1, color: '#5C97A9' }],
    questionImage: [],
    array: [
      ["A", "R", "T", "J", "Y", "H", "X", "R", "B", "U", "C"],
      ["Z", "E", "K", "S", "W", "M", "O", "U", "V", "F", "F"],
      ["P", "N", "J", "C", "F", "A", "M", "I", "Q", "R", "A"],
      ["S", "G", "H", "I", "S", "T", "O", "R", "Y", "H", "E"],
      ["H", "L", "B", "E", "U", "H", "F", "K", "B", "B", "C"],
      ["E", "I", "Z", "N", "M", "S", "T", "H", "E", "F", "B"],
      ["K", "S", "A", "C", "X", "B", "I", "C", "T", "D", "H"],
      ["R", "H", "Q", "E", "C", "E", "N", "K", "W", "A", "P"],
      ["G", "E", "O", "G", "R", "A", "P", "H", "Y", "O", "S"],
    ],
    initialValues: [
      { id: '0-0', isCorrect: true },
      { id: '0-1', isCorrect: true },
      { id: '0-2', isCorrect: true },
    ],
    questions: [
      {
        title: 'positive',
        answerInputs: ['art', 'english', 'geography', 'history']
      },
      {
        title: 'negative',
        answerInputs: ['ict', 'maths', 'science']
      }
    ]
  },

  2: {
    unit: 'Unit 4',
    id: 'WB6-U4-P30-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page30/E2/Key/Key.png',
    component: D1,
    // titleQuestion: [{ num: '1', title: `Listen to the street interviews and tick (✔) the correct box. What is the person’s favourite building?`, star: 2, color: '#F68025' }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page30/E2/0.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page30/E2/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page30/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page30/E2/3.jpg', input: true, answer: "art" },
        { url: 'img/FriendsPlus/Page30/E2/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page30/E2/5.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page30/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page30/E2/7.jpg', input: true, answer: "maths" },
        { url: 'img/FriendsPlus/Page30/E2/8.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page30/E2/9.jpg' },
        { url: 'img/FriendsPlus/Page30/E2/10.jpg', input: true, answer: "geography" },
        { url: 'img/FriendsPlus/Page30/E2/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page30/E2/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page30/E2/13.jpg' },
        { url: 'img/FriendsPlus/Page30/E2/14.jpg', input: true, answer: "English / Spanish" },
        { url: 'img/FriendsPlus/Page30/E2/15.jpg' },
        { url: 'img/FriendsPlus/Page30/E2/16.jpg', input: true, answer: "English / Spanish" },
        { url: 'img/FriendsPlus/Page30/E2/17.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page30/E2/18.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page30/E2/19.jpg' },
        { url: 'img/FriendsPlus/Page30/E2/20.jpg', input: true, answer: "history" },
        { url: 'img/FriendsPlus/Page30/E2/21.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page30/E2/22.jpg' },
        { url: 'img/FriendsPlus/Page30/E2/23.jpg', input: true, answer: "PE" },
        { url: 'img/FriendsPlus/Page30/E2/24.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page30/E2/25.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  3: { // Exercise num
    unit: 'Unit 4',
    id: 'WB6-U4-P30-E3',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page30/E3/Key/Key.png',
    component: T6,
    imgSize: '90%',
    inputSize: 150,
    titleImage: 'img/FriendsPlus/Page30/E3/title.jpg',
    // titleQuestion: [{ num: '4', title: 'Answer the questions. Write complete sentences.', color: '#db2d37', star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>You need to study a lot before you do this.&#160;&#160;<span style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px;'><u> exam </u></span></p>" +
          "<p><span style='font-weight:600'>1 </span>You learn about your school subject from this special book. #</p>" +
          "<p><span style='font-weight:600'>2 </span>This person helps you to learn new things. # </p>" +
          "<p><span style='font-weight:600'>3 </span>This is school work, but you don’t do it in class. #</p>" +
          "<p><span style='font-weight:600'>4 </span>This person is very, very good at a subject! #</p>" +
          "<p><span style='font-weight:600'>5 </span>You write information in this at home and in class.  #</p>" +
          "<p><span style='font-weight:600'>6 </span>The time when you learn something at school with a teacher # </p>",
        answer: [
          "textbook",
          "teacher",
          "homework",
          "genius",
          "notebook",
          "class",
        ],
        type: 'longAnwser'
      },
    ]
  },

  4: { // Exercise num
    unit: 'Unit 4',
    id: 'WB6-U4-P30-E4',
    audio: '',
    video: '',
    // exerciseKey: 'img/FriendsPlus/Page30/E3/Key/Key.png',
    component: T5,
    inputSize: 600,
    // titleImage: 'img/FriendsPlus/Page30/E3/title.jpg',
    titleQuestion: [{ num: '4', title: 'Write sentences about the school subjects that you like and that you don’t like. Say why. Use phrases from exercise 2 to help you.', color: '#0367b2', star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>I like &#160;&#160;<span style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px;'><u> geography </u></span> &#160;&#160; because &#160;&#160; <span style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px;'><u> we learn interesting things about mountains.</u></span></p>" +
          "<p><span style='font-weight:600'>1 </span>I’m good at  #</p>" +
          "<p> #.</p>" +
          "<p><span style='font-weight:600'>2 </span>I’m OK at #</p>" +
          "<p> #.</p>" +
          "<p><span style='font-weight:600'>3 </span> My favourite subject is #</p>" +
          "<p>&ensp; because #. </p>" +
          "<p><span style='font-weight:600'>4 </span>I’m interested in #</p>" +
          "<p>&ensp; because #. </p>" +
          "<p><span style='font-weight:600'>5 </span>I don’t like # </p>" +
          "<p>&ensp; because #. </p>",
        answer: [

        ],
        type: 'longAnwser'
      },
    ]
  },
}

export default json;

