import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';
import LT1 from '../../components/ExcerciseTypes/LineTo/LT1';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';


const json = {
  1: { // Exercise num
    unit: 'Language Focus Practice Unit 8',
    id: 'WB6-LFP8-P73-E1',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page73/E1/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page73/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page73/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page73/E1/3.jpg', input: true, answer: "'re going to visit" },
        { url: 'img/FriendsPlus/Page73/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page73/E1/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page73/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page73/E1/7.jpg', input: true, answer: "isn't going to make" },
        { url: 'img/FriendsPlus/Page73/E1/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page73/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page73/E1/10.jpg', input: true, answer: "'m not going to put" },
        { url: 'img/FriendsPlus/Page73/E1/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page73/E1/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page73/E1/13.jpg' },
        { url: 'img/FriendsPlus/Page73/E1/14.jpg', input: true, answer: "'re going to have" },
        { url: 'img/FriendsPlus/Page73/E1/15.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page73/E1/16.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page73/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page73/E1/18.jpg', input: true, answer: "is going to take" },
        { url: 'img/FriendsPlus/Page73/E1/19.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page73/E1/20.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page73/E1/21.jpg' },
        { url: 'img/FriendsPlus/Page73/E1/22.jpg', input: true, answer: "'s going to talk" },
        { url: 'img/FriendsPlus/Page73/E1/23.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page73/E1/24.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  2: { // Exercise num
    unit: 'Language Focus Practice Unit 8',
    id: 'WB6-LFP8-P73-E2',
    audio: '',
    video: '',
    component: T1,
    exerciseKey: 'img/FriendsPlus/Page73/E2/Key/answerKey.png',
    titleImage: 'img/FriendsPlus/Page73/E2/title.jpg',
    //titleQuestion: [{ num: '2', title: 'Read the text. What is Hannah’s project about? What is she learning about at school?', color: "#4D3D9B" }],
    questionImage: [],
    questions: [
      {
        title: "<p>you / going / go / are / to / holiday / on / ?</p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>Are you going to go on holiday?</u></p>" +

          "<p><span style='font-weight:600'>1</span>  are / where / you / stay / to / going / ?  #</p>" +
          "<p><span style='font-weight:600'>2</span>  going / are / your / parents / to / the river / in / swim / ?  #</p>" +
          "<p><span style='font-weight:600'>3</span>  to / you / what / going / eat / are / ?  #</p>" +
          "<p><span style='font-weight:600'>4</span>  is / going / dad / your / do / to / a barbecue / ?  #</p>" +
          "<p><span style='font-weight:600'>5</span>  going / you / take / are / tent / to / your / ?  #</p>" +
          "<p><span style='font-weight:600'>6</span>  to / when / you / going / back / are / come / ? #</p>",

        answer: [
          "Where are you going to stay?",
          "Are your parents going to swim in the river?",
          "What are you going to eat?",
          "Is your dad going to do a barbecue?",
          "Are you going to take your washbag?",
          "When are you going to come back?",
        ],
        type: 'longAnwser'
      },
    ]
  },
  3: { // Exercise num
    unit: 'Language Focus Practice Unit 8',
    id: 'WB6-LFP8-P73-E3',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page73/E3/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page73/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page73/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page73/E3/3.jpg', input: true, answer: "'ll be" },
        { url: 'img/FriendsPlus/Page73/E3/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page73/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page73/E3/6.jpg', input: true, answer: "won't need" },
        { url: 'img/FriendsPlus/Page73/E3/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page73/E3/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page73/E3/9.jpg' },
        { url: 'img/FriendsPlus/Page73/E3/10.jpg', input: true, answer: "'ll become" },
        { url: 'img/FriendsPlus/Page73/E3/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page73/E3/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page73/E3/13.jpg' },
        { url: 'img/FriendsPlus/Page73/E3/14.jpg', input: true, answer: "won't enjoy" },
        { url: 'img/FriendsPlus/Page73/E3/15.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  4: { // Exercise num
    unit: 'Language Focus Practice Unit 8',
    id: 'WB6-LFP8-P73-E4',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page73/E4/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page73/E4/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page73/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page73/E4/3.jpg', input: true, answer: "'ll carry" },
        { url: 'img/FriendsPlus/Page73/E4/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page73/E4/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page73/E4/6.jpg' },
        { url: 'img/FriendsPlus/Page73/E4/7.jpg', input: true, answer: "won't be" },
        { url: 'img/FriendsPlus/Page73/E4/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page73/E4/9.jpg' },
        { url: 'img/FriendsPlus/Page73/E4/10.jpg', input: true, answer: "'ll text" },
        { url: 'img/FriendsPlus/Page73/E4/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page73/E4/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page73/E4/13.jpg' },
        { url: 'img/FriendsPlus/Page73/E4/14.jpg', input: true, answer: "won't forget" },
        { url: 'img/FriendsPlus/Page73/E4/15.jpg' },
      ],


    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  5: { // Exercise num
    unit: 'Language Focus Practice Unit 8',
    id: 'WB6-LFP8-P73-E5',
    audio: '',
    video: '',
    component: LT1,
    exerciseKey: 'img/FriendsPlus/Page73/E5/Key/Key.png',
    titleImage: 'img/FriendsPlus/Page73/E5/1.jpg',
    // titleQuestion: [{ num: '5', title: 'Match 1–6 with a–f to make questions. Then write answers about your town or city.' }],
    questionImage: [],
    questions: [
      {
        right: [
          {
            id: 1,
            text: "1 If you bring some water",
          },
          {
            id: 2,
            text: "2 I won't be happy",
          },
          {
            id: 3,
            text: "3 I'll bring my skateboard",
          },
          {
            id: 4,
            text: "4 If I go shopping",
          },
        ],
        left: [
          {
            id: 1,
            text: "a if we go to the park.",
          },
          {
            id: 2,
            text: "b if it rains on our holiday.",
          },
          {
            id: 3,
            text: "c I'll leave my camera in the hotel.",
          },
          {
            id: 4,
            text: "d I'll carry it in my rucksack.",
          },
        ],
        answer: [
          {
            right: 1,
            left: 4,
          },
          {
            right: 2,
            left: 2,
          },
          {
            right: 3,
            left: 1,
          },
          {
            right: 4,
            left: 3,
          },
        ],
        type: ''
      },
    ]
  },
  6: {
    unit: 'Language Focus Practice Unit 8',
    id: 'WB6-LFP8-P73-E6',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page73/E6/Key/answerKey.png',
    component: DesignUnderLine,
    totalInput: 8,
    // titleQuestion: [{ num: '2', title: `Listen to the street interviews and tick (✔) the correct box. What is the person’s favourite building?`, star: 2, color: '#F68025' }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page73/E6/03.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page73/E6/05.jpg' },
        { url: 'img/FriendsPlus/Page73/E6/06.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page73/E6/07.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page73/E6/08.jpg' },
        { url: 'img/FriendsPlus/Page73/E6/09.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page73/E6/10.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page73/E6/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page73/E6/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page73/E6/13.jpg' },
        { url: 'img/FriendsPlus/Page73/E6/14.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page73/E6/15.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page73/E6/16.jpg' },
        { url: 'img/FriendsPlus/Page73/E6/17.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page73/E6/18.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page73/E6/19.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page73/E6/20.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page73/E6/21.jpg' },
        { url: 'img/FriendsPlus/Page73/E6/22.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page73/E6/23.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page73/E6/24.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page73/E6/25.jpg' },
        { url: 'img/FriendsPlus/Page73/E6/26.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page73/E6/27.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page73/E6/28.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page73/E6/29.jpg' },
        { url: 'img/FriendsPlus/Page73/E6/30.jpg', input: 7 },
        { url: 'img/FriendsPlus/Page73/E6/31.jpg', input: 7, isCorrect: true },
        { url: 'img/FriendsPlus/Page73/E6/32.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page73/E6/33.jpg' },
        { url: 'img/FriendsPlus/Page73/E6/34.jpg', input: 8, isCorrect: true },
        { url: 'img/FriendsPlus/Page73/E6/35.jpg', input: 8 },
        { url: 'img/FriendsPlus/Page73/E6/36.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

}

export default json;