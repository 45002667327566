
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';



const json = {
    1: { // Exercise num
        unit: 'Unit 4',
        id: 'WB6-U4-P33-E1',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page33/E1/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page33/E1/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page33/E1/2.jpg' },
                { url: 'img/FriendsPlus/Page33/E1/3.jpg', input: true, answer: "am" },
                { url: 'img/FriendsPlus/Page33/E1/4.jpg' },
                { url: 'img/FriendsPlus/Page33/E1/5.jpg', input: true, answer: "'m not" },
                { url: 'img/FriendsPlus/Page33/E1/6.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page33/E1/7.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page33/E1/8.jpg' },
                { url: 'img/FriendsPlus/Page33/E1/9.jpg', input: true, answer: "Is" },
                { url: 'img/FriendsPlus/Page33/E1/10.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page33/E1/11.jpg' },
                { url: 'img/FriendsPlus/Page33/E1/12.jpg', input: true, answer: "is" },
                { url: 'img/FriendsPlus/Page33/E1/13.jpg' },
                { url: 'img/FriendsPlus/Page33/E1/14.jpg', input: true, answer: "isn't" },
                { url: 'img/FriendsPlus/Page33/E1/15.jpg' },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page33/E1/16.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page33/E1/17.jpg' },
                { url: 'img/FriendsPlus/Page33/E1/18.jpg', input: true, answer: "Are" },
                { url: 'img/FriendsPlus/Page33/E1/19.jpg' },
            ],
            [
                // Column8
                { url: 'img/FriendsPlus/Page33/E1/20.jpg' },
            ],
            [
                // Column9
                { url: 'img/FriendsPlus/Page33/E1/21.jpg' },
                { url: 'img/FriendsPlus/Page33/E1/22.jpg', input: true, answer: "are" },
                { url: 'img/FriendsPlus/Page33/E1/23.jpg' },
                { url: 'img/FriendsPlus/Page33/E1/24.jpg', input: true, answer: "aren't" },
                { url: 'img/FriendsPlus/Page33/E1/25.jpg' },
            ],



        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    2: { // Exercise num
        unit: 'Unit 4',
        id: 'WB6-U4-P33-E2',
        audio: '',
        video: '',
        component: T1,
        exerciseKey: 'img/FriendsPlus/Page33/E2/Key/answerKey.png',
        titleImage: 'img/FriendsPlus/Page33/E2/title.png',
        titleQuestion: [{ num: '3', title: 'Look at the picture. Write questions and short answers.', color: "#1ABED7", star: 2 }],
        questionImage: [],
        questions: [
            {
                title: "<p>Pat / dance / ?</p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>Is Pat dancing? Yes, she is.</u></p>" +
                    "<p><span style='font-weight:600'>1</span>. Ethan / talk to the teacher / ? # </p>" +
                    "<p><span style='font-weight:600'>2</span>. Matt / listen to music / ? # </p>" +
                    "<p><span style='font-weight:600'>3</span>. James and Oliver / play tennis / ? # </p>" +
                    "<p><span style='font-weight:600'>4</span>. Eva / sit / under the tree / ? # </p>" +
                    "<p><span style='font-weight:600'>5</span>. Molly and Mariam / eat lunch / ? # </p>" +
                    "<p><span style='font-weight:600'>6</span>. Rupert / walk / ? # </p>",



                answer: [
                    "Is Ethan talking to the teacher? Yes, he is.",
                    "Is Matt listening to music? No, he isn't.",
                    "Are James and Oliver playing tennis? No, they aren't.",
                    "Is Eva sitting under the tree? Yes, she is.",
                    "Are Molly and Mariam eating lunch? Yes, they are.",
                    "Is Rupert walking? No, he isn't.",

                ],
                type: 'longAnwser'
            },
        ]
    },
    3: { // Exercise num
        unit: 'Unit 4',
        id: 'WB6-U4-P33-E3',
        audio: '',
        video: '',
        component: D1,
        fontSize: 21,
        exerciseKey: 'img/FriendsPlus/Page33/E3/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page33/E3/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page33/E3/2.jpg' },
                { url: 'img/FriendsPlus/Page33/E3/3.jpg', input: true, answer: "'m doing" },
                { url: 'img/FriendsPlus/Page33/E3/4.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page33/E3/5.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page33/E3/6.jpg' },
                { url: 'img/FriendsPlus/Page33/E3/7.jpg', input: true, answer: "gives" },
                { url: 'img/FriendsPlus/Page33/E3/8.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page33/E3/9.jpg' },
                { url: 'img/FriendsPlus/Page33/E3/10.jpg', input: true, answer: "finish" },
                { url: 'img/FriendsPlus/Page33/E3/11.jpg' },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page33/E3/12.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page33/E3/13.jpg' },
                { url: 'img/FriendsPlus/Page33/E3/14.jpg', input: true, answer: "don't usually have" },
                { url: 'img/FriendsPlus/Page33/E3/15.jpg' },
            ],
            [
                // Column8
                { url: 'img/FriendsPlus/Page33/E3/16.jpg' },
            ],
            [
                // Column9
                { url: 'img/FriendsPlus/Page33/E3/17.jpg' },
                { url: 'img/FriendsPlus/Page33/E3/18.jpg', input: true, answer: "Does your teacher often give" },
                { url: 'img/FriendsPlus/Page33/E3/19.jpg' },
            ],
            [
                // Column10
                { url: 'img/FriendsPlus/Page33/E3/20.jpg' },
            ],
            [
                // Column11
                { url: 'img/FriendsPlus/Page33/E3/21.jpg' },
                { url: 'img/FriendsPlus/Page33/E3/22.jpg', input: true, answer: "'m working" },
                { url: 'img/FriendsPlus/Page33/E3/23.jpg' },
            ],
            [
                // Column12
                { url: 'img/FriendsPlus/Page33/E3/24.jpg' },
            ],
            [
                // Column13
                { url: 'img/FriendsPlus/Page33/E3/25.jpg' },
                { url: 'img/FriendsPlus/Page33/E3/26.jpg', input: true, answer: "helps" },
                { url: 'img/FriendsPlus/Page33/E3/27.jpg' },
            ],
            [
                // Column14
                { url: 'img/FriendsPlus/Page33/E3/28.jpg' },
                { url: 'img/FriendsPlus/Page33/E3/29.jpg', input: true, answer: "'s playing" },
                { url: 'img/FriendsPlus/Page33/E3/30.jpg' },
            ],
            [
                // Column15
                { url: 'img/FriendsPlus/Page33/E3/31.jpg' },
            ],
            [
                // Column16
                { url: 'img/FriendsPlus/Page33/E3/32.jpg' },
                { url: 'img/FriendsPlus/Page33/E3/33.jpg', input: true, answer: "isn't answering" },
                { url: 'img/FriendsPlus/Page33/E3/34.jpg' },
            ],
            [
                // Column17
                { url: 'img/FriendsPlus/Page33/E3/35.jpg' },
                { url: 'img/FriendsPlus/Page33/E3/36.jpg', input: true, answer: "are you doing" },
                { url: 'img/FriendsPlus/Page33/E3/37.jpg' },
            ],
            [
                // Column18
                { url: 'img/FriendsPlus/Page33/E3/38.jpg' },
            ],



        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    4: { // Exercise num
        unit: 'Unit 4',
        id: 'WB6-U4-P33-E4',
        audio: '',
        video: '',
        //exerciseKey: 'img/FriendsPlus/Page31/E2/Key/answerKey.png',
        component: T6,
        titleImage: '',
        titleQuestion: [{ num: '4', title: 'Complete the sentences using the present simple or the present continuous form.', color: '#41CADC', star: 3 }],
        questionImage: [],
        questions: [
            {
                title:
                    '<p>Every morning, we <u style="font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray"><b>&nbsp;&nbsp; get the school bus at 6.15.&nbsp;&nbsp;</b></u></p>' +

                    "<p><span style='font-weight:600'>1</span> After dinner, I always #</p>" +
                    "<p><span style='font-weight:600'>2</span> At the moment, my mum #</p>" +
                    "<p><span style='font-weight:600'>3</span> My best friend often #</p>" +
                    "<p><span style='font-weight:600'>4</span> Now, my school friends  #</p>" +
                    "<p><span style='font-weight:600'>5</span> Our teacher usually #</p>" +
                    "<p><span style='font-weight:600'>6</span> Today, I #</p>",

                answer: [

                ],
                type: 'longAnwser'
            },
        ]
    },



}

export default json;