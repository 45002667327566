import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import D_none from '../../components/ExcerciseTypes/Design/TypeIn_none';


const json = {
  1: { // Exercise num
    unit: 'Unit 5',
    id: 'WB6-U5-P37-E1',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page37/E1/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page37/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page37/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/3.jpg', input: true, answer: "U" },
        { url: 'img/FriendsPlus/Page37/E1/4.jpg', },
        { url: 'img/FriendsPlus/Page37/E1/5.jpg', input: true, answer: "C" },
        { url: 'img/FriendsPlus/Page37/E1/6.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page37/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/8.jpg', input: true, answer: "C" },
        { url: 'img/FriendsPlus/Page37/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/10.jpg', input: true, answer: "C" },
        { url: 'img/FriendsPlus/Page37/E1/11.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page37/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/13.jpg', input: true, answer: "U" },
        { url: 'img/FriendsPlus/Page37/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/15.jpg', input: true, answer: "U" },
        { url: 'img/FriendsPlus/Page37/E1/16.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page37/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/18.jpg', input: true, answer: "C" },
        { url: 'img/FriendsPlus/Page37/E1/19.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/20.jpg', input: true, answer: "C" },
        { url: 'img/FriendsPlus/Page37/E1/21.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page37/E1/22.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/23.jpg', input: true, answer: "U" },
        { url: 'img/FriendsPlus/Page37/E1/24.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/25.jpg', input: true, answer: "U" },
        { url: 'img/FriendsPlus/Page37/E1/26.jpg' },
      ],



    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 5',
    id: 'WB6-U5-P37-E2',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page37/E2/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page37/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page37/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page37/E2/3.jpg', input: true, answer: "a lot of" },
        { url: 'img/FriendsPlus/Page37/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page37/E2/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page37/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page37/E2/7.jpg', input: true, answer: "some" },
        { url: 'img/FriendsPlus/Page37/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page37/E2/9.jpg', input: true, answer: "some" },
        { url: 'img/FriendsPlus/Page37/E2/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page37/E2/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page37/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page37/E2/13.jpg', input: true, answer: "many" },
        { url: 'img/FriendsPlus/Page37/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page37/E2/15.jpg', input: true, answer: "much" },
        { url: 'img/FriendsPlus/Page37/E2/16.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page37/E2/17.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page37/E2/18.jpg' },
        { url: 'img/FriendsPlus/Page37/E2/19.jpg', input: true, answer: "any" },
        { url: 'img/FriendsPlus/Page37/E2/20.jpg' },
        { url: 'img/FriendsPlus/Page37/E2/21.jpg', input: true, answer: "any" },
        { url: 'img/FriendsPlus/Page37/E2/22.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page37/E2/23.jpg' },
      ],


    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 5',
    id: 'WB6-U5-P37-E3',
    audio: '',
    video: '',
    fontSize: 21,
    component: D_none,
    exerciseKey: 'img/FriendsPlus/Page37/E3/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page37/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page37/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page37/E3/3.jpg', input: true, answer: "How many" },
        { url: 'img/FriendsPlus/Page37/E3/4.jpg' },
        { url: 'img/FriendsPlus/Page37/E3/5.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page37/E3/6.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page37/E3/7.jpg' },
        { url: 'img/FriendsPlus/Page37/E3/8.jpg', input: true, answer: "How much" },
        { url: 'img/FriendsPlus/Page37/E3/9.jpg' },
        { url: 'img/FriendsPlus/Page37/E3/10.jpg', input: true, answer: "none" },
        { url: 'img/FriendsPlus/Page37/E3/11.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page37/E3/12.jpg' },
        { url: 'img/FriendsPlus/Page37/E3/13.jpg', input: true, answer: "How many" },
        { url: 'img/FriendsPlus/Page37/E3/14.jpg' },
        { url: 'img/FriendsPlus/Page37/E3/15.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page37/E3/16.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page37/E3/17.jpg' },
        { url: 'img/FriendsPlus/Page37/E3/18.jpg', input: true, answer: "How much" },
        { url: 'img/FriendsPlus/Page37/E3/19.jpg' },
        { url: 'img/FriendsPlus/Page37/E3/20.jpg', input: true, answer: "none" },
        { url: 'img/FriendsPlus/Page37/E3/21.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page37/E3/22.jpg' },
        { url: 'img/FriendsPlus/Page37/E3/23.jpg', input: true, answer: "How many" },
        { url: 'img/FriendsPlus/Page37/E3/24.jpg' },
        { url: 'img/FriendsPlus/Page37/E3/25.jpg', input: true, answer: "es" },
        { url: 'img/FriendsPlus/Page37/E3/26.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page37/E3/27.jpg' },
        { url: 'img/FriendsPlus/Page37/E3/28.jpg', input: true, answer: "How much" },
        { url: 'img/FriendsPlus/Page37/E3/29.jpg' },
        { url: 'img/FriendsPlus/Page37/E3/30.jpg', input: true, answer: "none" },
        { url: 'img/FriendsPlus/Page37/E3/31.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page37/E3/32.jpg' },
        { url: 'img/FriendsPlus/Page37/E3/33.jpg', input: true, answer: "How much" },
        { url: 'img/FriendsPlus/Page37/E3/34.jpg' },
        { url: 'img/FriendsPlus/Page37/E3/35.jpg', input: true, answer: "none" },
        { url: 'img/FriendsPlus/Page37/E3/36.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page37/E3/37.jpg' },
        { url: 'img/FriendsPlus/Page37/E3/38.jpg', input: true, answer: "How many" },
        { url: 'img/FriendsPlus/Page37/E3/39.jpg' },
        { url: 'img/FriendsPlus/Page37/E3/40.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page37/E3/41.jpg' },
      ],




    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 5',
    id: 'WB6-U5-P37-E4',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page37/E4/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page37/E4/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page37/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page37/E4/3.jpg', input: true, answer: "a lot of" },
        { url: 'img/FriendsPlus/Page37/E4/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page37/E4/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page37/E4/6.jpg' },
        { url: 'img/FriendsPlus/Page37/E4/7.jpg', input: true, answer: "many / a lot of" },
        { url: 'img/FriendsPlus/Page37/E4/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page37/E4/9.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page37/E4/10.jpg' },
        { url: 'img/FriendsPlus/Page37/E4/11.jpg', input: true, answer: "any" },
        { url: 'img/FriendsPlus/Page37/E4/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page37/E4/13.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page37/E4/14.jpg' },
        { url: 'img/FriendsPlus/Page37/E4/15.jpg', input: true, answer: "a lot of" },
        { url: 'img/FriendsPlus/Page37/E4/16.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page37/E4/17.jpg' },
        { url: 'img/FriendsPlus/Page37/E4/18.jpg', input: true, answer: "some" },
        { url: 'img/FriendsPlus/Page37/E4/19.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page37/E4/20.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page37/E4/21.jpg' },
        { url: 'img/FriendsPlus/Page37/E4/22.jpg', input: true, answer: "any" },
        { url: 'img/FriendsPlus/Page37/E4/23.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page37/E4/24.jpg' },
        { url: 'img/FriendsPlus/Page37/E4/25.jpg', input: true, answer: "much / a lot of" },
        { url: 'img/FriendsPlus/Page37/E4/26.jpg' },
      ],
      [
        // Column13
        { url: 'img/FriendsPlus/Page37/E4/27.jpg' },
      ],
      [
        // Column14
        { url: 'img/FriendsPlus/Page37/E4/28.jpg' },
        { url: 'img/FriendsPlus/Page37/E4/29.jpg', input: true, answer: "some" },
        { url: 'img/FriendsPlus/Page37/E4/30.jpg' },
      ],
      [
        // Column15
        { url: 'img/FriendsPlus/Page37/E4/31.jpg' },
      ],




    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  // 5: { // Exercise num
  unit: 'Unit 5',
  //   audio: '',
  //   video: '',
  //   component: T2,
  //   exerciseKey: '',
  //   titleImage: 'img/FriendsPlus/Page37/E5/title.png',
  //   titleQuestion: [{ num: '5', title: 'Look back at the table in exercise 4. Complete the table for you and a friend. Write sentences with some, any, much, many and a lot of', color: '#19BED7', star: 3 }],
  //   questionImage: [],
  //   questions: [
  //     {
  //       title: "<p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>I eat a lot of crisps. I love them. My friend Minh never eats any crisps. He doesn’t like them.</u></p>" +
  //         "<p style='font-weight:500'>#</p>" +
  //         "<p style='font-weight:500'>#</p>" +
  //         "<p style='font-weight:500'>#</p>" +
  //         "<p style='font-weight:500'>#</p>" +
  //         "<p style='font-weight:500'>#</p>" +
  //         "<p style='font-weight:500'>#</p>" +
  //         "<p style='font-weight:500'>#</p>" +
  //         "<p style='font-weight:500'>#</p>",
  //       answer: [],
  //       type: 'longAnwser'
  //     },
  //   ]
  // },

  5: { // Exercise num
    unit: 'Unit 5',
    id: 'WB6-U5-P37-E5',
    audio: '',
    video: '',
    component: D1,
    fontSize: 25,
    // exerciseKey: 'img/FriendsPlus/Page37/E5/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page37/E5/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page37/E5/2.jpg' },
        { url: 'img/FriendsPlus/Page37/E5/3.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page37/E5/4.jpg' },
        { url: 'img/FriendsPlus/Page37/E5/5.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page37/E5/6.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page37/E5/7.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page37/E5/8.jpg' },
        { url: 'img/FriendsPlus/Page37/E5/9.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page37/E5/10.jpg' },
        { url: 'img/FriendsPlus/Page37/E5/11.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page37/E5/12.jpg' },
        { url: 'img/FriendsPlus/Page37/E5/13.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page37/E5/14.jpg' },
        { url: 'img/FriendsPlus/Page37/E5/15.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page37/E5/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page37/E5/17.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page37/E5/18.jpg' },
        { url: 'img/FriendsPlus/Page37/E5/19.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page37/E5/20.jpg' },
        { url: 'img/FriendsPlus/Page37/E5/21.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page37/E5/22.jpg' },
        { url: 'img/FriendsPlus/Page37/E5/23.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page37/E5/24.jpg' },
        { url: 'img/FriendsPlus/Page37/E5/25.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page37/E5/26.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page37/E5/27.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page37/E5/28.jpg' },
        { url: 'img/FriendsPlus/Page37/E5/29.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page37/E5/30.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page37/E5/31.jpg' },
        { url: 'img/FriendsPlus/Page37/E5/32.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page37/E5/33.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page37/E5/34.jpg' },
        { url: 'img/FriendsPlus/Page37/E5/35.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page37/E5/36.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page37/E5/37.jpg' },
        { url: 'img/FriendsPlus/Page37/E5/38.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page37/E5/39.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page37/E5/40.jpg' },
        { url: 'img/FriendsPlus/Page37/E5/41.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page37/E5/42.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page37/E5/43.jpg' },
        { url: 'img/FriendsPlus/Page37/E5/44.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page37/E5/45.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page37/E5/46.jpg' },
        { url: 'img/FriendsPlus/Page37/E5/47.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page37/E5/48.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page37/E5/49.jpg' },
        { url: 'img/FriendsPlus/Page37/E5/50.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page37/E5/51.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

}

export default json;