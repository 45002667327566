import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {

    1: { // Exercise num
        unit : 'Unit 7',
        id : 'WB6-U7-P50-E1',
        audio: '',
        video: '',
        exerciseKey: 'img/FriendsPlus/Page50/E1/Key/Key.png',
        component: D1,
        fontSize: 25,
        titleImage: 'http://',
        // titleQuestion: [{ num: '1', title: 'Write the comparative forms of the given adjectives', color: "#5DC8DA", star: 1 }],
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page50/E1/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E1/2.jpg' },
                { url: 'img/FriendsPlus/Page50/E1/3.jpg', input: true, answer: "4" },
                { url: 'img/FriendsPlus/Page50/E1/4.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E1/5.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E1/6.jpg' },
                { url: 'img/FriendsPlus/Page50/E1/7.jpg' },
                { url: 'img/FriendsPlus/Page50/E1/8.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E1/9.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E1/10.jpg' },
                { url: 'img/FriendsPlus/Page50/E1/11.jpg', input: true, answer: "5" },
                { url: 'img/FriendsPlus/Page50/E1/12.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E1/13.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E1/14.jpg' },
                { url: 'img/FriendsPlus/Page50/E1/15.jpg', input: true, answer: "3" },
                { url: 'img/FriendsPlus/Page50/E1/16.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E1/17.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E1/18.jpg' },
                { url: 'img/FriendsPlus/Page50/E1/19.jpg', input: true, answer: "8" },
                { url: 'img/FriendsPlus/Page50/E1/20.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E1/21.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E1/22.jpg' },
                { url: 'img/FriendsPlus/Page50/E1/23.jpg', input: true, answer: "7" },
                { url: 'img/FriendsPlus/Page50/E1/24.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E1/25.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E1/26.jpg' },
                { url: 'img/FriendsPlus/Page50/E1/27.jpg', input: true, answer: "6" },
                { url: 'img/FriendsPlus/Page50/E1/28.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E1/29.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E1/30.jpg' },
                { url: 'img/FriendsPlus/Page50/E1/31.jpg', input: true, answer: "2" },
                { url: 'img/FriendsPlus/Page50/E1/32.jpg' },
            ],
        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },

    2: { // Exercise num
        unit : 'Unit 7',
        id : 'WB6-U7-P50-E2',
        audio: '',
        video: '',
        exerciseKey: 'img/FriendsPlus/Page50/E2/Key/Key.png',
        component: D1,
        fontSize: 20,
        titleImage: 'http://',
        // titleQuestion: [{ num: '1', title: 'Write the comparative forms of the given adjectives', color: "#5DC8DA", star: 1 }],
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page50/E2/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E2/2.jpg' },
                { url: 'img/FriendsPlus/Page50/E2/3.jpg', input: true, answer: "went to school" },
                { url: 'img/FriendsPlus/Page50/E2/4.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E2/5.jpg' },
                { url: 'img/FriendsPlus/Page50/E2/6.jpg', input: true, answer: "left school" },
                { url: 'img/FriendsPlus/Page50/E2/7.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E2/8.jpg' },
                { url: 'img/FriendsPlus/Page50/E2/9.jpg', input: true, answer: "didn't go" },
                { url: 'img/FriendsPlus/Page50/E2/10.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E2/11.jpg' },
                { url: 'img/FriendsPlus/Page50/E2/12.jpg', input: true, answer: "didn't get a qualification" },
                { url: 'img/FriendsPlus/Page50/E2/13.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E2/14.jpg' },
                { url: 'img/FriendsPlus/Page50/E2/15.jpg', input: true, answer: "got a job" },
                { url: 'img/FriendsPlus/Page50/E2/16.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E2/17.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E2/18.jpg' },
                { url: 'img/FriendsPlus/Page50/E2/19.jpg', input: true, answer: "travelled" },
                { url: 'img/FriendsPlus/Page50/E2/20.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E2/21.jpg' },
            ],
        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },

    3: { // Exercise num
        unit : 'Unit 7',
        id : 'WB6-U7-P50-E3',
        audio: 'img/FriendsPlus/Page50/E3/Audio/Friends Plus for Vietnam G6 WB Track 08.mp3',
        video: '',
        exerciseKey: 'img/FriendsPlus/Page50/E3/Key/Key.png',
        component: D1,
        fontSize: 25,
        titleImage: 'http://',
        // titleQuestion: [{ num: '1', title: 'Write the comparative forms of the given adjectives', color: "#5DC8DA", star: 1 }],
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page50/E3/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E3/2.jpg' },
                { url: 'img/FriendsPlus/Page50/E3/3.jpg', input: true, answer: "south" },
                { url: 'img/FriendsPlus/Page50/E3/4.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E3/5.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E3/6.jpg' },
                { url: 'img/FriendsPlus/Page50/E3/7.jpg', input: true, answer: "61" },
                { url: 'img/FriendsPlus/Page50/E3/8.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E3/9.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E3/10.jpg' },
                { url: 'img/FriendsPlus/Page50/E3/11.jpg', input: true, answer: "theatre" },
                { url: 'img/FriendsPlus/Page50/E3/12.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E3/13.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E3/14.jpg' },
                { url: 'img/FriendsPlus/Page50/E3/15.jpg', input: true, answer: "Pauline" },
                { url: 'img/FriendsPlus/Page50/E3/16.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E3/17.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E3/18.jpg' },
                { url: 'img/FriendsPlus/Page50/E3/19.jpg', input: true, answer: "the USA" },
                { url: 'img/FriendsPlus/Page50/E3/20.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E3/21.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E3/22.jpg' },
                { url: 'img/FriendsPlus/Page50/E3/23.jpg', input: true, answer: "March" },
                { url: 'img/FriendsPlus/Page50/E3/24.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E3/25.jpg' },
            ],
        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },

    4: { // Exercise num
        unit : 'Unit 7',
        id : 'WB6-U7-P50-E4',
        audio: 'img/FriendsPlus/Page50/E4/Audio/Friends Plus for Vietnam G6 WB Track 08.mp3',
        video: '',
        exerciseKey: 'img/FriendsPlus/Page50/E4/Key/Key.png',
        component: D1,
        fontSize: 25,
        titleImage: 'http://',
        // titleQuestion: [{ num: '1', title: 'Write the comparative forms of the given adjectives', color: "#5DC8DA", star: 1 }],
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page50/E4/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E4/2.jpg' },
                { url: 'img/FriendsPlus/Page50/E4/3.jpg', input: true, answer: "True" },
                { url: 'img/FriendsPlus/Page50/E4/4.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E4/5.jpg' },
                { url: 'img/FriendsPlus/Page50/E4/6.jpg', input: true, answer: "True" },
                { url: 'img/FriendsPlus/Page50/E4/7.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E4/8.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E4/9.jpg' },
                { url: 'img/FriendsPlus/Page50/E4/10.jpg', input: true, answer: "False" },
                { url: 'img/FriendsPlus/Page50/E4/11.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E4/12.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E4/13.jpg' },
                { url: 'img/FriendsPlus/Page50/E4/14.jpg', input: true, answer: "False" },
                { url: 'img/FriendsPlus/Page50/E4/15.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E4/16.jpg' },
                { url: 'img/FriendsPlus/Page50/E4/17.jpg', input: true, answer: "True" },
                { url: 'img/FriendsPlus/Page50/E4/18.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page50/E4/19.jpg' },
                { url: 'img/FriendsPlus/Page50/E4/20.jpg', input: true, answer: "False" },
                { url: 'img/FriendsPlus/Page50/E4/21.jpg' },
            ],
        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },

    5: { // Exercise num
        unit : 'Unit 7',
        id : 'WB6-U7-P50-E5',
        audio: '',
        video: '',
        component: T2,
        // exerciseKey: 'img/FriendsPlus/Page50/E5/Key/Key.png',
        imgSize: '85%',
        titleImage: 'img/FriendsPlus/Page50/E5/title.jpg',
        // titleQuestion: [{ num: '4', title: 'Answer the questions. Write complete sentences.', color: "#de2f3a", star: 3 }],
        questionImage: [],
        questions: [
            {
                title:
                    "<p><u style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px;'> I was born in Bursa and I grew up there. </u></p>" +
                    "<p>#</p>" +
                    "<p>#</p>" +
                    "<p>#</p>" +
                    "<p>#</p>" +
                    "<p>#</p>" +
                    "<p>#</p>",
                answer: [

                ],
                type: 'longAnwser'
            },
        ]
    },
}

export default json;