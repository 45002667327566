
import MC2 from '../../components/ExcerciseTypes/MultipleChoice/MC2';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: { // Exercise num
    unit: 'Starter Unit',
    id: 'WB6-S-P5-E1',
    audio: '',
    video: '',
    fontSize: 30,
    height: 36,
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page5/E1/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    marginLeft: '',
    widthButton: '',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page5/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page5/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page5/E1/3.jpg', input: true, answer: "'s" },
        { url: 'img/FriendsPlus/Page5/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page5/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page5/E1/6.jpg', input: true, answer: "'re" },
        { url: 'img/FriendsPlus/Page5/E1/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page5/E1/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page5/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page5/E1/10.jpg', input: true, answer: "'m not" },
        { url: 'img/FriendsPlus/Page5/E1/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page5/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page5/E1/13.jpg', input: true, answer: "isn't" },
        { url: 'img/FriendsPlus/Page5/E1/14.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page5/E1/15.jpg' },
        { url: 'img/FriendsPlus/Page5/E1/16.jpg', input: true, answer: "aren't" },
        { url: 'img/FriendsPlus/Page5/E1/17.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page5/E1/18.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  2: { // Exercise num
    unit: 'Starter Unit',
    id: 'WB6-S-P5-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page5/E2/Key/answerKey.png',
    component: T6,
    titleImage: 'img/FriendsPlus/Page5/E2/title.png',
    imgSize: 700,
    inputSize: 220,
    //titleQuestion: [{ num: '4', title: 'Complete the sentences with be and one of the adjectives from this page.', color: '#F58024', star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1</span> I # interested in animals.</p>" +
          "<p><span style='font-weight:600'>2</span> He # Italian.</p>" +
          "<p><span style='font-weight:600'>3</span> You # good at skateboarding.</p>" +
          "<p><span style='font-weight:600'>4</span> She # into chatting online.</p>" +
          "<p><span style='font-weight:600'>5</span> It # a good video game!</p>" +
          "<p><span style='font-weight:600'>6</span> We # from Edinburgh.</p>" +
          "<p><span style='font-weight:600'>7</span> I # eleven.</p>" +
          "<p><span style='font-weight:600'>8</span> They # teachers.</p>",
        answer: [
          "'m",
          "'s",
          "'re",
          "'s",
          "'s",
          "'re",
          "'m",
          "'re",

        ],
        type: 'longAnwser'
      },
    ]
  },
  3: { // Exercise num
    unit: 'Starter Unit',
    id: 'WB6-S-P5-E3',
    audio: '',
    video: '',
    component: T1,
    exerciseKey: 'img/FriendsPlus/Page5/E3/Key/Key.png',
    titleImage: '',
    titleQuestion: [{ num: '3', title: 'Rewrite the sentences using the negative form.', color: "#1ABED7", star: 2 }],
    questionImage: [],
    questions: [
      {
        title: "<p>I’m into cycling.</p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>I’m not into cycling.</u></p>" +
          "<p><span style='font-weight:600'>1</span>. She’s interested in reading. # </p>" +
          "<p><span style='font-weight:600'>2</span>. We’re good at basketball. # </p>" +
          "<p><span style='font-weight:600'>3</span>. The book is very good. # </p>" +
          "<p><span style='font-weight:600'>4</span>. My brothers are into photography. # </p>" +
          "<p><span style='font-weight:600'>5</span>. I’m fifteen. # </p>" +
          "<p><span style='font-weight:600'>6</span>. You’re from Ankara. # </p>" +
          "<p><span style='font-weight:600'>7</span>. He’s my friend. # </p>" +
          "<p><span style='font-weight:600'>8</span>. We’re in our house. # </p>",
        answer: [
          "She isn't interested in reading.",
          "We aren't good at basketball.",
          "The book isn't very good.",
          "My brothers aren't into photography.",
          "I'm not fifteen.",
          "You aren't from Ankara.",
          "He isn't my friend.",
          "We aren't in our house.",
        ],
        type: 'longAnwser'
      },
    ]
  },
  4: { // Exercise num
    unit: 'Starter Unit',
    id: 'WB6-S-P5-E4',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page5/E4/Key/answerKey.png',
    component: T6,
    titleImage: 'img/FriendsPlus/Page5/E4/title.png',

    //titleQuestion: [{ num: '4', title: 'Complete the sentences with be and one of the adjectives from this page.', color: '#F58024', star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1</span> Nuran#.#.</p>" +
          "<p><span style='font-weight:600'>2</span> Jessica and Holly #.#.</p>" +
          "<p><span style='font-weight:600'>3</span> I #.#.</p>",
        answer: [
          "is thirteen",
          "She's from Izmir",
          "are eleven",
          "They're from Liverpool",
          "",
          "",
        ],
        type: 'longAnwser'
      },
    ]
  },
  5: { // Exercise num
    unit: 'Starter Unit',
    id: 'WB6-S-P5-E5',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page5/E5/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page5/E5/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page5/E5/2.jpg' },
        { url: 'img/FriendsPlus/Page5/E5/3.jpg', input: true, answer: "It" },
        { url: 'img/FriendsPlus/Page5/E5/4.jpg' },
        { url: 'img/FriendsPlus/Page5/E5/5.jpg', input: true, answer: "his" },
        { url: 'img/FriendsPlus/Page5/E5/6.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page5/E5/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page5/E5/8.jpg' },
        { url: 'img/FriendsPlus/Page5/E5/9.jpg', input: true, answer: "They" },
        { url: 'img/FriendsPlus/Page5/E5/10.jpg' },
        { url: 'img/FriendsPlus/Page5/E5/11.jpg', input: true, answer: "her" },
        { url: 'img/FriendsPlus/Page5/E5/12.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page5/E5/13.jpg' },
        { url: 'img/FriendsPlus/Page5/E5/14.jpg', input: true, answer: "It" },
        { url: 'img/FriendsPlus/Page5/E5/15.jpg' },
        { url: 'img/FriendsPlus/Page5/E5/16.jpg', input: true, answer: "my" },
        { url: 'img/FriendsPlus/Page5/E5/17.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page5/E5/18.jpg' },
        { url: 'img/FriendsPlus/Page5/E5/19.jpg', input: true, answer: "We" },
        { url: 'img/FriendsPlus/Page5/E5/20.jpg' },
        { url: 'img/FriendsPlus/Page5/E5/21.jpg', input: true, answer: "their" },
        { url: 'img/FriendsPlus/Page5/E5/22.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page5/E5/23.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page5/E5/24.jpg' },
        { url: 'img/FriendsPlus/Page5/E5/25.jpg', input: true, answer: "He" },
        { url: 'img/FriendsPlus/Page5/E5/26.jpg' },
        { url: 'img/FriendsPlus/Page5/E5/27.jpg', input: true, answer: "our" },
        { url: 'img/FriendsPlus/Page5/E5/28.jpg' },
      ],



    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },




  6: { // Exercise num
    unit: 'Starter Unit',
    id: 'WB6-S-P5-E6',
    audio: '',
    video: '',
    component: MC2,
    exerciseKey: 'img/FriendsPlus/Page5/E6/Key/answerKey.png',
    // titleQuestion: [{ num: '6', title: 'Choose the correct answers.', color: '#00B7D1', star: 2 }],
    titleImage: 'img/FriendsPlus/Page5/E6/title.jpg',
    questionImage: [ // Row
      [
        // Column
        { url: 'http://' },
        { url: 'http://', input: true },
        { url: 'http://' },
      ],
    ],
    questions: [
      {
        title: 'This is a good photo. Do you want to see ....... ?',
        answers: [
          { image: "", isCorrect: true, right: "", text: "it" },
          { image: "", isCorrect: false, right: "", text: "them" },
          { image: "", isCorrect: false, right: "", text: "him" },
        ],
        no: 1,
        question: '',
        type: '',
      },
      {
        title: 'We’re going to the cinema. Come with ....... .',
        answers: [
          { image: "", isCorrect: false, right: "", text: "them" },
          { image: "", isCorrect: false, right: "", text: "her" },
          { image: "", isCorrect: true, right: "", text: "us" },
        ],
        no: 2,
        question: '',
        type: '',
      },
      {
        title: 'My uncle is in this picture. That’s ....... .',
        answers: [
          { image: "", isCorrect: false, right: "", text: "them" },
          { image: "", isCorrect: true, right: "", text: "him" },
          { image: "", isCorrect: false, right: "", text: "you" },
        ],
        no: 3,
        question: '',
        type: '',
      },
      {
        title: `I don't eat burgers because I don't like ....... .`,
        answers: [
          { image: "", isCorrect: false, right: "", text: "him" },
          { image: "", isCorrect: true, right: "", text: "them" },
          { image: "", isCorrect: false, right: "", text: "us" },
        ],
        no: 4,
        question: '',
        type: '',
      },
      {
        title: `I’ve got your bag. I can give it to ........ on Friday.`,
        answers: [
          { image: "", isCorrect: true, right: "", text: "you" },
          { image: "", isCorrect: false, right: "", text: "us" },
          { image: "", isCorrect: false, right: "", text: "me" },
        ],
        no: 5,
        question: '',
        type: '',
      },
      {
        title: `I don’t know that new girl. Do you know ........ ?`,
        answers: [
          { image: "", isCorrect: false, right: "", text: "him" },
          { image: "", isCorrect: false, right: "", text: "them" },
          { image: "", isCorrect: true, right: "", text: "her" },
        ],
        no: 6,
        question: '',
        type: '',
      },
    ]
  },

}

export default json;