import T5 from '../../components/ExcerciseTypes/TypeIn/T5';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import WordsBox from '../../components/ExcerciseTypes/Design/WordBox';

const json = {
    1: { // Exercise num
        unit: 'Unit 8',
        id: 'WB6-U8-P54-E1',
        audio: '',
        video: '',
        component: WordsBox,
        isAutofocus: true,
        exerciseKey: 'img/FriendsPlus/Page54/E1/key.png',
        titleImage: '',
        questionImage: [ // Row
            [
                // Column
                { url: 'img/FriendsPlus/Page54/E1/1.png', itemHeight: 75 },
            ],
            [
                { url: 'img/FriendsPlus/Page54/E1/2.png' },
                { url: 'img/FriendsPlus/Page54/E1/3.png', input: true, answer: 'i' },
                { url: 'img/FriendsPlus/Page54/E1/4.png', input: true, answer: 'r' },
                { url: 'img/FriendsPlus/Page54/E1/5.png', input: true, answer: 'p' },
                { url: 'img/FriendsPlus/Page54/E1/6.png', input: true, answer: 'l' },
                { url: 'img/FriendsPlus/Page54/E1/7.png', input: true, answer: 'a' },
                { url: 'img/FriendsPlus/Page54/E1/8.png', input: true, answer: 'n' },
                { url: 'img/FriendsPlus/Page54/E1/9.png', input: true, answer: 'e' },
                { url: 'img/FriendsPlus/Page54/E1/10.png' },
            ],
            [
                { url: 'img/FriendsPlus/Page54/E1/11.png' },
            ],
            [
                { url: 'img/FriendsPlus/Page54/E1/12.png' },
                { url: 'img/FriendsPlus/Page54/E1/13.png', input: true, answer: 'f' },
                { url: 'img/FriendsPlus/Page54/E1/14.png' },
                { url: 'img/FriendsPlus/Page54/E1/15.png', input: true, answer: 'r' },
                { url: 'img/FriendsPlus/Page54/E1/16.png', input: true, answer: 'r' },
                { url: 'img/FriendsPlus/Page54/E1/17.png', input: true, answer: 'y' },
                { url: 'img/FriendsPlus/Page54/E1/18.png' },
            ],
            [
                { url: 'img/FriendsPlus/Page54/E1/19.png' },
                { url: 'img/FriendsPlus/Page54/E1/20.png' },
                { url: 'img/FriendsPlus/Page54/E1/21.png' },
                { url: 'img/FriendsPlus/Page54/E1/22.png', input: true, answer: 'b' },
                { url: 'img/FriendsPlus/Page54/E1/23.png' },
            ],
            [
                { url: 'img/FriendsPlus/Page54/E1/24.png' },
                { url: 'img/FriendsPlus/Page54/E1/25.png', input: true, answer: 'b' },
                { url: 'img/FriendsPlus/Page54/E1/26.png' },
                { url: 'img/FriendsPlus/Page54/E1/27.png', input: true, answer: 'o' },
                { url: 'img/FriendsPlus/Page54/E1/28.png' },
            ],
            [
                { url: 'img/FriendsPlus/Page54/E1/29.png' },
                { url: 'img/FriendsPlus/Page54/E1/30.png', input: true, answer: 's' },
                { url: 'img/FriendsPlus/Page54/E1/31.png', input: true, answer: 'u' },
                { url: 'img/FriendsPlus/Page54/E1/32.png', input: true, answer: 'b' },
                { url: 'img/FriendsPlus/Page54/E1/33.png', input: true, answer: 'w' },
                { url: 'img/FriendsPlus/Page54/E1/34.png' },
                { url: 'img/FriendsPlus/Page54/E1/35.png', input: true, answer: 'y' },
                { url: 'img/FriendsPlus/Page54/E1/36.png' },
                { url: 'img/FriendsPlus/Page54/E1/37.png', input: true, answer: 't' },
                { url: 'img/FriendsPlus/Page54/E1/38.png', input: true, answer: 'r' },
                { url: 'img/FriendsPlus/Page54/E1/39.png', input: true, answer: 'a' },
                { url: 'img/FriendsPlus/Page54/E1/40.png', input: true, answer: 'i' },
                { url: 'img/FriendsPlus/Page54/E1/41.png', input: true, answer: 'n' },
                { url: 'img/FriendsPlus/Page54/E1/42.png' },
            ],
            [
                { url: 'img/FriendsPlus/Page54/E1/43.png' },
                { url: 'img/FriendsPlus/Page54/E1/44.png', input: true, answer: 's' },
                { url: 'img/FriendsPlus/Page54/E1/45.png' },
                { url: 'img/FriendsPlus/Page54/E1/46.png', input: true, answer: 't' },
                { url: 'img/FriendsPlus/Page54/E1/47.png' },
            ],
            [

                { url: 'img/FriendsPlus/Page54/E1/48.png' },
            ],
        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    2: { // Exercise num
        unit: 'Unit 8',
        id: 'WB6-U8-P54-E2',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page54/E2/Key/Key.png',
        // titleImage: 'http://',
        titleQuestion: [{ num: '2', title: 'Complete the sentences with the given words.', color: "#0b6fbb", star: 2 }],
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page54/E2/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page54/E2/2.jpg' },
                { url: 'img/FriendsPlus/Page54/E2/3.jpg', input: true, answer: "automobile" },
                { url: 'img/FriendsPlus/Page54/E2/4.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page54/E2/5.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page54/E2/6.jpg' },
                { url: 'img/FriendsPlus/Page54/E2/7.jpg', input: true, answer: "trams" },
                { url: 'img/FriendsPlus/Page54/E2/8.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page54/E2/9.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page54/E2/10.jpg' },
                { url: 'img/FriendsPlus/Page54/E2/11.jpg', input: true, answer: "boats" },
                { url: 'img/FriendsPlus/Page54/E2/12.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page54/E2/13.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page54/E2/14.jpg' },
                { url: 'img/FriendsPlus/Page54/E2/15.jpg', input: true, answer: "airplane" },
                { url: 'img/FriendsPlus/Page54/E2/16.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page54/E2/17.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page54/E2/18.jpg' },
                { url: 'img/FriendsPlus/Page54/E2/19.jpg', input: true, answer: "subway train" },
                { url: 'img/FriendsPlus/Page54/E2/20.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page54/E2/21.jpg' },
            ],
        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    3: { // Exercise num
        unit: 'Unit 8',
        id: 'WB6-U8-P54-E3',
        audio: '',
        video: '',
        component: T6,
        exerciseKey: 'img/FriendsPlus/Page54/E3/Key/Key.png',
        titleImage: 'img/FriendsPlus/Page54/E3/title.jpg',
        imgSize: '60%',
        // titleQuestion: [{ num: '3', title: 'Read the clues and write the objects.', color: '#0b6fbb', star: 2 }],
        questionImage: [],
        questions: [
            {
                title: "<p>This has meters to show how much you have to pay.</p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u style='font-size:30px'>taxi</u></p>" +
                    "<p>1  This carries a lot of people, vehicles and goods. </p><p style='font-weight:500'>#</p>" +
                    "<p>2  This carries people up and down a mountain.</p><p style='font-weight:500'>#</p>" +
                    "<p>3  This flies without wings. </p><p style='font-weight:500'>#</p>" +
                    "<p>4  This has two wheels.</p><p style='font-weight:500'>#</p>" +
                    "<p>5  This helps people to discover the moon.</p><p style='font-weight:500'>#</p>" +
                    "<p>6  This carries heavy loads by road. </p><p style='font-weight:500'>#</p>",
                answer: [
                    "ferry",
                    "cable car",
                    "helicopter",
                    "bicycle",
                    "spaceship",
                    "truck",
                ],
                type: 'longAnwser'
            },
        ]
    },
    4: { // Exercise num
        unit: 'Unit 8',
        id: 'WB6-U8-P54-E4',
        audio: '',
        video: '',
        component: T2,
        // exerciseKey: 'img/FriendsPlus/Page54/E4/Key/Key.png',
        // titleImage: 'img/FriendsPlus/Page51/E4/title.jpg',
        titleQuestion: [{ num: '4', title: 'Complete the sentences with some of the words from this page and your own ideas.', color: '#0b6fbb', star: 3 }],
        questionImage: [],
        questions: [
            {
                title: "<p>I like travelling by train</p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>because I can see a lot of things</u></p>" +
                    "<p><span style='font-weight:600'>1</span>  I like travelling by taxi because</p>" +
                    "<p style='font-weight:500'> # # </p>" +
                    "<p><span style='font-weight:600'>2</span>  You should travel by bicycle because</p>" +
                    "<p style='font-weight:500'># #</p>" +
                    "<p><span style='font-weight:600'>3</span>  Nowadays more people travel by plane because</p>" +
                    "<p style='font-weight:500'># #</p>" +
                    "<p><span style='font-weight:600'>4</span>  Travelling by bus is good because</p>" +
                    "<p style='font-weight:500'># #</p>" +
                    "<p><span style='font-weight:600'>5</span>  Many people in Việt Nam still travel by motorcycle because</p>" +
                    "<p style='font-weight:500'># #</p>",

                answer: [],
                type: 'longAnwser'
            },
        ]
    },

}
export default json;