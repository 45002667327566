import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';

const json = {
  1: { // Exercise num
    unit: 'Starter Unit',
    id: 'WB6-S-P9-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page9/E1/Key/Key.png',
    component: T6,
    imgSize: '70%',
    inputSize: 130,
    titleImage: 'img/FriendsPlus/Page9/E1/title.jpg',
    // titleQuestion: [{ num: '5', title: ' Imagine there is a new student in your class. Write questions with be.', color: '#28b9d8', star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray;font-size: 30px;'><u> That </u></span> &ensp; photo is really nice.</p>" +
          "<p><span style='font-weight:600'>1</span> # boys are in my class.</p>" +
          "<p><span style='font-weight:600'>2</span> # video game is really cool.</p>" +
          "<p><span style='font-weight:600'>3</span> # are my new friends, Michaela and Megan.</p>" +
          "<p><span style='font-weight:600'>4</span> # camera is quite expensive.</p>" +
          "<p><span style='font-weight:600'>5</span> # is my cousin, Alex.</p>",
        answer: [
          "Those",
          "This",
          "These",
          "That",
          "This",
        ],
        type: 'longAnwser'
      },
    ]
  },

  2: { // Exercise num
    unit: 'Starter Unit',
    id: 'WB6-S-P9-E2',
    audio: '',
    video: '',
    component: D1,
    fontSize: 25,
    height: 28,
    exerciseKey: 'img/FriendsPlus/Page9/E2/Key/Key.png',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page9/E2/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page9/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page9/E2/3.jpg', input: true, answer: "'s/has" },
        { url: 'img/FriendsPlus/Page9/E2/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page9/E2/5.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page9/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page9/E2/7.jpg', input: true, answer: "haven't" },
        { url: 'img/FriendsPlus/Page9/E2/8.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page9/E2/9.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page9/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page9/E2/11.jpg', input: true, answer: "got" },
        { url: 'img/FriendsPlus/Page9/E2/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page9/E2/13.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page9/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page9/E2/15.jpg', input: true, answer: "got" },
        { url: 'img/FriendsPlus/Page9/E2/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page9/E2/17.jpg' },
        { url: 'img/FriendsPlus/Page9/E2/18.jpg', input: true, answer: "Has" },
        { url: 'img/FriendsPlus/Page9/E2/19.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page9/E2/20.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page9/E2/21.jpg' },
        { url: 'img/FriendsPlus/Page9/E2/22.jpg', input: true, answer: "Have" },
        { url: 'img/FriendsPlus/Page9/E2/23.jpg' },
        { url: 'img/FriendsPlus/Page9/E2/24.jpg', input: true, answer: "Haven't" },
        { url: 'img/FriendsPlus/Page9/E2/25.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page9/E2/26.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page9/E2/27.jpg' },
        { url: 'img/FriendsPlus/Page9/E2/28.jpg', input: true, answer: "Has" },
        { url: 'img/FriendsPlus/Page9/E2/29.jpg' },
        { url: 'img/FriendsPlus/Page9/E2/30.jpg', input: true, answer: "Hasn't" },
        { url: 'img/FriendsPlus/Page9/E2/31.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page9/E2/32.jpg' },
      ],


    ],

    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  3: { // Exercise num
    unit: 'Starter Unit',
    id: 'WB6-S-P9-E3',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page9/E3/Key/Key.png',
    component: T6,
    inputSize: 400,
    // titleImage: 'img/FriendsPlus/Page7/E5/title.jpg',
    titleQuestion: [{ num: '3', title: 'Complete the sentences using affirmative or negative forms of <i>have got</i>.', color: '#28b9d8', star: 2 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>We <span style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray'> 've got </span> a book about sport at home. ✔</p>" +
          "<p>We <span style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray'> haven't got </span> a book about animals. ✘</p>" +
          "<p><span style='font-weight:600'>1</span> I# a pen in my bag. ✘</p>" +
          "<p><span style='font-weight:600'>&#160;&#160; </span> I# a notebook. ✔</p>" +
          "<p><span style='font-weight:600'>2</span> Molly# a big table in her room. ✘</p>" +
          "<p><span style='font-weight:600'>&#160;&#160;</span> She# a small desk. ✔</p>" +
          "<p><span style='font-weight:600'>3 </span>You # an ID card in your drawer. ✔</p>" +
          "<p><span style='font-weight:600'>&#160;&#160;</span> You# a camera. ✘</p>" +
          "<p><span style='font-weight:600'>4 </span>Steve# a sister. ✔</p>" +
          "<p><span style='font-weight:600'>&#160;&#160;</span> He# a brother. ✘</p>" +
          "<p><span style='font-weight:600'>5 </span> We# cousins in Australia. ✘</p>" +
          "<p><span style='font-weight:600'>&#160;&#160;</span> We # cousins in the UK. ✔</p>" +
          "<p><span style='font-weight:600'>6 </span> Our classroom # new desks. ✔</p>" +
          "<p><span style='font-weight:600'>&#160;&#160;</span> It# a new board. ✘</p>",
        answer: [
          "haven't got",
          "'ve got",
          "hasn't got",
          "'s got",
          "'ve got",
          "haven't got ",
          "'s got",
          "hasn't got",
          "haven't got",
          "'ve got",
          "has got",
          "hasn't got",
        ],
        type: 'longAnwser'
      },
    ]
  },


  4: { // Exercise num
    unit: 'Starter Unit',
    id: 'WB6-S-P9-E4',
    audio: '',
    video: '',
    component: D1,
    fontSize: 26,
    height: 28,
    exerciseKey: 'img/FriendsPlus/Page9/E4/Key/answerKey.png',
    questionImage: [ // Row

      [//Column
        { url: 'img/FriendsPlus/Page9/E4/1.jpg' },
      ],

      [//
        { url: 'img/FriendsPlus/Page9/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page9/E4/3.jpg', input: true, answer: "Have" },
        { url: 'img/FriendsPlus/Page9/E4/4.jpg' },
        { url: 'img/FriendsPlus/Page9/E4/5.jpg', input: true, answer: "got" },
        { url: 'img/FriendsPlus/Page9/E4/6.jpg' },
      ],
      [//
        { url: 'img/FriendsPlus/Page9/E4/7.jpg' },
        { url: 'img/FriendsPlus/Page9/E4/8.jpg', input: true, answer: "Yes, they have." },
        { url: 'img/FriendsPlus/Page9/E4/9.jpg' },
      ],
      [//
        { url: 'img/FriendsPlus/Page9/E4/10.jpg' },
        { url: 'img/FriendsPlus/Page9/E4/11.jpg', input: true, answer: "Has" },
        { url: 'img/FriendsPlus/Page9/E4/12.jpg' },
        { url: 'img/FriendsPlus/Page9/E4/13.jpg', input: true, answer: "got" },
        { url: 'img/FriendsPlus/Page9/E4/14.jpg' },
      ],
      [//
        { url: 'img/FriendsPlus/Page9/E4/15.jpg' },
        { url: 'img/FriendsPlus/Page9/E4/16.jpg', input: true, answer: "Yes, she has. " },
        { url: 'img/FriendsPlus/Page9/E4/17.jpg' },
      ],
      [//
        { url: 'img/FriendsPlus/Page9/E4/18.jpg' },
        { url: 'img/FriendsPlus/Page9/E4/19.jpg', input: true, answer: "Have" },
        { url: 'img/FriendsPlus/Page9/E4/20.jpg' },
        { url: 'img/FriendsPlus/Page9/E4/21.jpg', input: true, answer: "got" },
        { url: 'img/FriendsPlus/Page9/E4/22.jpg' },
      ],
      [//
        { url: 'img/FriendsPlus/Page9/E4/23.jpg' },
        { url: 'img/FriendsPlus/Page9/E4/24.jpg', input: true, answer: "No, they haven't." },
        { url: 'img/FriendsPlus/Page9/E4/25.jpg' },
      ],
      [//
        { url: 'img/FriendsPlus/Page9/E4/26.jpg' },
        { url: 'img/FriendsPlus/Page9/E4/27.jpg', input: true, answer: "Has" },
        { url: 'img/FriendsPlus/Page9/E4/28.jpg' },
        { url: 'img/FriendsPlus/Page9/E4/29.jpg', input: true, answer: "got" },
        { url: 'img/FriendsPlus/Page9/E4/30.jpg' },
      ],
      [//
        { url: 'img/FriendsPlus/Page9/E4/31.jpg' },
        { url: 'img/FriendsPlus/Page9/E4/32.jpg', input: true, answer: "No, she hasn't." },
        { url: 'img/FriendsPlus/Page9/E4/33.jpg' },
      ],
    ],

    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  5: { // Exercise num
    unit: 'Starter Unit',
    id: 'WB6-S-P9-E5',
    audio: '',
    video: '',
    // exerciseKey: 'img/FriendsPlus/Page9/E3/Key/Key.png',
    component: T2,
    // titleImage: 'img/FriendsPlus/Page7/E5/title.jpg',
    titleQuestion: [{ num: '5', title: 'Write sentences using affirmative and negative forms of <i>have got</i>.', color: '#28b9d8', star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>In my room, I  <span style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray;font-size: 30px'>’ve got two speakers but I haven’t got a computer. </span></p>" +
          "<p><span style='font-weight:600'>1 In my pocket, I</span> # </p>" +
          "<p><span style='font-weight:600'>&#160;&#160;</span>#</p>" +
          "<p><span style='font-weight:600'>2 On my desk, I </span> # </p>" +
          "<p><span style='font-weight:600'>&#160;&#160;</span>#</p>" +
          "<p><span style='font-weight:600'>3 On my teacher’s desk, he / she</span>  # </p>" +
          "<p><span style='font-weight:600'>&#160;&#160;</span>#</p>" +
          "<p><span style='font-weight:600'>4 In my bag, I</span> # </p>" +
          "<p><span style='font-weight:600'>&#160;&#160;</span>#</p>" +
          "<p><span style='font-weight:600'>5 In our house, we</span>  # </p>" +
          "<p><span style='font-weight:600'>&#160;&#160;</span>#</p>",
        answer: [
        ],
        type: 'longAnwser'
      },
    ]
  },

}
export default json