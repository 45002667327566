import React from 'react';
import { useDrop } from 'react-dnd';

import { dragItemTypes } from '../../../../../constants';

const DropZone = ({ onDrop, ...props }) => {
  const [, drop] = useDrop({
    accept: dragItemTypes.CARD,
    drop: onDrop,
  });

  return <div {...props} ref={drop} />;
}

export default DropZone;
