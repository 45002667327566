
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';

const json = {
  1: {
    unit: 'Unit 1',
    id: 'WB6-U1-P16-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page16/E1/Key/Key.jpg',
    component: DesignUnderLine,
    totalInput: 1,
    // titleQuestion: [{ num: '1', title: 'Read the text. Tick (✔) the correct box. Miniland is ... London.', color: '#B83D3E', star: 1 }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page16/E1/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page16/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page16/E1/3.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page16/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page16/E1/5.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page16/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page16/E1/7.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page16/E1/8.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page16/E1/9.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  // 1: { // Exercise num
  unit: 'Unit 1',
  //   audio: '',
  //   video: '',
  //   component: MC4,
  //   titleQuestion: [{ num: '1', title: 'Read the text. Tick (✔) the correct box. Miniland is ... London.', color: '#B83D3E', star: 1 }],
  //   titleImage: 'img/FriendsPlus/Page16/E1/titleImage.png',
  //   questionImage: [ // Row
  //     [
  //       // Column
  //       { url: 'http://' },
  //       { url: 'http://', input: true },
  //       { url: 'http://' },
  //     ],
  //   ],
  //   // questions: [
  //   //   {
  //   //     title: 'It’s thirty minutes by train from London to Windsor.',
  //   //     answers: [
  //   //       { image: "", isCorrect: true, right: "", text: "True" },
  //   //       { image: "", isCorrect: false, right: "", text: "False" },
  //   //     ],
  //   //     no: 1,
  //   //     question: '',
  //   //     type: '',
  //   //   },
  //   // ]
  // },
  2: { // Exercise num
    unit: 'Unit 1',
    id: 'WB6-U1-P16-E2',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page16/E2/Key/answerKey.png',
    titleImage: '',
    typeInput: 'center',
    maxLength: 1,
    titleQuestion: [{ num: '2', title: 'Read the text again. Match sentences 1–4 with paragraphs A–E.', color: "#D22C37", star: 2 }],
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page16/E2/title.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page16/E2/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page16/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page16/E2/3.jpg', input: true, answer: "D" },
        { url: 'img/FriendsPlus/Page16/E2/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page16/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page16/E2/6.jpg', input: true, answer: "B" },
        { url: 'img/FriendsPlus/Page16/E2/7.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page16/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page16/E2/9.jpg', input: true, answer: "E" },
        { url: 'img/FriendsPlus/Page16/E2/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page16/E2/11.jpg' },
        { url: 'img/FriendsPlus/Page16/E2/12.jpg', input: true, answer: "A" },
        { url: 'img/FriendsPlus/Page16/E2/13.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  3: { // Exercise num
    unit: 'Unit 1',
    id: 'WB6-U1-P16-E3',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page16/E3/Key/Key.png',
    titleImage: '',
    fontSize: 25,
    titleQuestion: [{ num: '3', title: 'Read the text again. Write True or False.', color: "#D22C37", star: 2 }],
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page16/E2/title.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page16/E3/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page16/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page16/E3/3.jpg', input: true, answer: "True" },
        { url: 'img/FriendsPlus/Page16/E3/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page16/E3/5.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page16/E3/6.jpg' },
        { url: 'img/FriendsPlus/Page16/E3/7.jpg', input: true, answer: "False" },
        { url: 'img/FriendsPlus/Page16/E3/8.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page16/E3/9.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page16/E3/10.jpg' },
        { url: 'img/FriendsPlus/Page16/E3/11.jpg', input: true, answer: "True" },
        { url: 'img/FriendsPlus/Page16/E3/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page16/E3/13.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page16/E3/14.jpg' },
        { url: 'img/FriendsPlus/Page16/E3/15.jpg', input: true, answer: "False" },
        { url: 'img/FriendsPlus/Page16/E3/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page16/E3/17.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page16/E3/18.jpg' },
        { url: 'img/FriendsPlus/Page16/E3/19.jpg', input: true, answer: "True" },
        { url: 'img/FriendsPlus/Page16/E3/20.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page16/E3/21.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page16/E3/22.jpg' },
        { url: 'img/FriendsPlus/Page16/E3/23.jpg', input: true, answer: "False" },
        { url: 'img/FriendsPlus/Page16/E3/24.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  4: { // Exercise num
    unit: 'Unit 1',
    id: 'WB6-U1-P16-E4',
    audio: '',
    video: '',
    component: T1,
    titleImage: '',
    exerciseKey: 'img/FriendsPlus/Page16/E4/Key/answerKey.png',
    titleQuestion: [{ num: '4', title: 'Answer the questions. Write complete sentences.', color: "#D22C37", star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>1. What’s the name of the London train station for visitors to Windsor? # </p>" +
          "<p>2. What time is Miniland open in the morning? # </p>" +
          "<p>3. Which London park is there in Miniland? # </p>" +
          "<p>4. How many bricks are there in Miniland? # </p>" +
          "<p>5. Is there a place like Miniland in your country? Where is it? </p><p> # </p>" +
          "<p>6. Are places like Miniland interesting for you? Why / Why not?  </p><p> # </p>",
        answer: [
          "The station is called Paddington.",
          "It's open from 10 a.m.",
          "There is St James's Park in Miniland.",
          "There are about 40 million bricks.",
          "",
          "",
        ],
        type: 'longAnwser'
      },
    ]
  },
  5: { // Exercise num
    unit: 'Unit 1',
    id: 'WB6-U1-P16-E5',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page16/E5/Key/answerKey.png',
    component: T6,
    imgSize: '60%',
    inputSize: 200,
    titleImage: 'img/FriendsPlus/Page16/E5/1.jpg',
    titleQuestion: [{ num: '5', title: 'VOCABULARY PLUS Complete the sentences with the given words.', color: "#D22C37", star: 2 }],
    questionImage: [],
    questions: [
      {
        title:
          '<p>The beds on the new ship are very &ensp;<u style="font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray"><b>comfortable.</b></u></p>' +
          "<p><b>1</b> My brother and father work in a restaurant.They’re # .</p>" +
          "<p><b>2</b> ‘The food in this new café is very good.’ ‘Yes, it’s # ! </p>" +
          "<p>&ensp; But the food in that old café is # !’</p> " +
          "<p><b>3</b> There are nine hundred # on this ship and they’ve </p>" +
          "<p>&ensp; all got nice # .</p>" +
          "<p><b>4</b> The sports centre has got a modern # . It’s very tall </p>" +
          "<p>&ensp; and very # !</p>" +
          '<p><b>5</b> It’s a hot day  and there are a lot of people in the # .</p>',
        answer: ['chefs', 'fantastic', 'horrible', 'passengers', 'cabins', 'climbing wall', 'exciting', 'swimming pool'],
        type: 'longAnwser'
      },
    ]
  },
}

export default json;