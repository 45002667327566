import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T5 from '../../components/ExcerciseTypes/TypeIn/T5';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import Wordbox from '../../components/ExcerciseTypes/Design/WordBox2';

const json = {

  1: {
    id: 'WB6-U4-P32-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page32/E1/Key/key.png',
    component: Wordbox,
    fontSize: 25,
    typeInput: 'center',
    isAutofocus: true,
    // titleQuestion: [{ num: '1', title: `Listen to the street interviews and tick (✔) the correct box. What is the person’s favourite building?`, star: 2, color: '#F68025' }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page32/E1/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page32/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/3.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page32/E1/4.jpg', input: true, answer: "k" },
        { url: 'img/FriendsPlus/Page32/E1/5.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page32/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/7.jpg', input: true, answer: "o" },
        { url: 'img/FriendsPlus/Page32/E1/8.jpg', input: true, answer: "t" },
        { url: 'img/FriendsPlus/Page32/E1/9.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page32/E1/10.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page32/E1/11.jpg' },

      ],
      [
        { url: 'img/FriendsPlus/Page32/E1/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page32/E1/13.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/14.jpg', input: true, answer: "h" },
        { url: 'img/FriendsPlus/Page32/E1/15.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page32/E1/16.jpg', input: true, answer: "c" },
        { url: 'img/FriendsPlus/Page32/E1/17.jpg', input: true, answer: "k" },
        { url: 'img/FriendsPlus/Page32/E1/18.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page32/E1/19.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page32/E1/20.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/21.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page32/E1/22.jpg', input: true, answer: "p" },
        { url: 'img/FriendsPlus/Page32/E1/23.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page32/E1/24.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page32/E1/25.jpg', input: true, answer: "t" },
        { url: 'img/FriendsPlus/Page32/E1/26.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page32/E1/27.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/28.jpg', input: true, answer: "n" },
        { url: 'img/FriendsPlus/Page32/E1/29.jpg', input: true, answer: "o" },
        { url: 'img/FriendsPlus/Page32/E1/30.jpg', input: true, answer: "w" },
        { url: 'img/FriendsPlus/Page32/E1/31.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page32/E1/32.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page32/E1/33.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/34.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page32/E1/35.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page32/E1/36.jpg', input: true, answer: "r" },
        { url: 'img/FriendsPlus/Page32/E1/37.jpg', input: true, answer: "n" },
        { url: 'img/FriendsPlus/Page32/E1/38.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page32/E1/39.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/40.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page32/E1/41.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page32/E1/42.jpg', input: true, answer: "d" },
        { url: 'img/FriendsPlus/Page32/E1/43.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page32/E1/44.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page32/E1/45.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/46.jpg', input: true, answer: "n" },
        { url: 'img/FriendsPlus/Page32/E1/47.jpg', input: true, answer: "d" },
        { url: 'img/FriendsPlus/Page32/E1/48.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page32/E1/49.jpg', input: true, answer: "r" },
        { url: 'img/FriendsPlus/Page32/E1/50.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page32/E1/51.jpg', input: true, answer: "t" },
        { url: 'img/FriendsPlus/Page32/E1/52.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page32/E1/53.jpg', input: true, answer: "n" },
        { url: 'img/FriendsPlus/Page32/E1/54.jpg', input: true, answer: "d" },
        { url: 'img/FriendsPlus/Page32/E1/55.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page32/E1/56.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/57.jpg', input: true, answer: "o" },
        { url: 'img/FriendsPlus/Page32/E1/58.jpg', input: true, answer: "n" },
        { url: 'img/FriendsPlus/Page32/E1/59.jpg', input: true, answer: "c" },
        { url: 'img/FriendsPlus/Page32/E1/60.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page32/E1/61.jpg', input: true, answer: "n" },
        { url: 'img/FriendsPlus/Page32/E1/62.jpg', input: true, answer: "t" },
        { url: 'img/FriendsPlus/Page32/E1/63.jpg', input: true, answer: "r" },
        { url: 'img/FriendsPlus/Page32/E1/64.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page32/E1/65.jpg', input: true, answer: "t" },
        { url: 'img/FriendsPlus/Page32/E1/66.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page32/E1/67.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page32/E1/68.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/69.jpg', input: true, answer: "r" },
        { url: 'img/FriendsPlus/Page32/E1/70.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page32/E1/71.jpg', input: true, answer: "c" },
        { url: 'img/FriendsPlus/Page32/E1/72.jpg', input: true, answer: "t" },
        { url: 'img/FriendsPlus/Page32/E1/73.jpg', input: true, answer: "i" },
        { url: 'img/FriendsPlus/Page32/E1/74.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page32/E1/75.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page32/E1/76.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page32/E1/77.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page32/E1/78.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page32/E1/79.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/80.jpg', input: true, answer: "i" },
        { url: 'img/FriendsPlus/Page32/E1/81.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page32/E1/82.jpg', input: true, answer: "t" },
        { url: 'img/FriendsPlus/Page32/E1/83.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page32/E1/84.jpg', input: true, answer: "n" },
        { url: 'img/FriendsPlus/Page32/E1/85.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page32/E1/86.jpg' },
      ],


    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  2: { // Exercise num
    unit: 'Unit 4',
    id: 'WB6-U4-P32-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page32/E2/Key/Key.png',
    component: T6,
    imgSize: 750,
    inputSize: 280,
    titleImage: 'img/FriendsPlus/Page32/E2/title.jpg',
    // titleQuestion: [{ num: '4', title: 'Answer the questions. Write complete sentences.', color: '#db2d37', star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>Hannah &#160;&#160;<span style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px;'><u> is making notes </u></span>&#160;&#160;&#160;&#160; in her notebook.</p>" +
          "<p><span style='font-weight:600'>1 </span>Mr Brown # on the board. </p>" +
          "<p><span style='font-weight:600'>2 </span>I # a question.</p>" +
          "<p><span style='font-weight:600'>3 </span>Tony # a book.</p>" +
          "<p><span style='font-weight:600'>4 </span>Ella and Lily  # They’re chatting.</p>" +
          "<p><span style='font-weight:600'>5 </span>Max and Joseph # </p>" +
          "<p><span style='font-weight:600'>6 </span>Arjun # a word.</p>",
        answer: [
          "is writing",
          "'m asking",
          "is reading",
          "aren't concentrating.",
          "are listening.",
          "is spelling",
        ],
        type: 'longAnwser'
      },
    ]
  },

  3: {
    id: 'WB6-U4-P32-E3',
    audio: 'img/FriendsPlus/Page32/Audio/Friends Plus for Vietnam G6 WB Track 05 Trang 32.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page32/E3/Key/Key.png',
    component: D1,
    // titleQuestion: [{ num: '1', title: `Listen to the street interviews and tick (✔) the correct box. What is the person’s favourite building?`, star: 2, color: '#F68025' }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page32/E3/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page32/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page32/E3/3.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page32/E3/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page32/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page32/E3/6.jpg', input: true, answer: "d" },
        { url: 'img/FriendsPlus/Page32/E3/7.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page32/E3/8.jpg' },
        { url: 'img/FriendsPlus/Page32/E3/9.jpg', input: true, answer: "f" },
        { url: 'img/FriendsPlus/Page32/E3/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page32/E3/11.jpg' },
        { url: 'img/FriendsPlus/Page32/E3/12.jpg', input: true, answer: "b" },
        { url: 'img/FriendsPlus/Page32/E3/13.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page32/E3/14.jpg' },
        { url: 'img/FriendsPlus/Page32/E3/15.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page32/E3/16.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  4: { // Exercise num
    unit: 'Unit 4',
    id: 'WB6-U4-P32-E4',
    audio: 'img/FriendsPlus/Page32/Audio/Friends Plus for Vietnam G6 WB Track 05 Trang 32.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page32/E4/Key/Key.png',
    component: T6,
    imgSize: 850,
    inputSize: 230,
    titleImage: 'img/FriendsPlus/Page32/E4/title.jpg',
    // titleQuestion: [{ num: '4', title: 'Answer the questions. Write complete sentences.', color: '#db2d37', star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>has got an exam? &#160;&#160;<span style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px;'><u> Layla </u></span></p>" +
          "<p><span style='font-weight:600'>1 </span>is learning a language for work? # </p>" +
          "<p><span style='font-weight:600'>2 </span>is studying with his phone? # </p>" +
          "<p><span style='font-weight:600'>3 </span>is learning grammar in the library?   # </p>" +
          "<p><span style='font-weight:600'>4 </span>has got a plan to visit Việt Nam? # </p>" +
          "<p><span style='font-weight:600'>5 </span>is studying French at school? # </p>" +
          "<p><span style='font-weight:600'>6 </span>is learning English in the car? # </p>",
        answer: [
          "Alex",
          "Sam",
          "Sylvie",
          "Rebecca and Kim",
          "Layla",
          "Yusuf",
        ],
        type: 'longAnwser'
      },
    ]
  },


  5: { // Exercise num
    unit: 'Unit 4',
    id: 'WB6-U4-P32-E5',
    audio: '',
    video: '',
    // exerciseKey: 'img/FriendsPlus/Page30/E3/Key/Key.png',
    component: T5,
    inputSize: 700,
    // titleImage: 'img/FriendsPlus/Page30/E3/title.jpg',
    titleQuestion: [{ num: '5', title: 'Write sentences about how you learn different subjects at school. Say why.', color: '#f68021', star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>I concentrate in &#160;&#160;<span style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px;'><u> science </u></span> &#160;&#160; because &#160;&#160; <span style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px;'><u> it's difficult for me and I don’t always understand. </u></span></p>" +
          "<p>I make notes in &#160;&#160;<span style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px;'><u> history </u></span> &#160;&#160; because &#160;&#160; <span style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px;'><u> there's a lot of information. </u></span></p>" +
          "<p><span style='font-weight:600'>1 </span>I concentrate in #</p>" +
          "<p>&ensp;&#160; because # .</p>" +
          "<p><span style='font-weight:600'>2 </span>I make notes in #</p>" +
          "<p>&ensp;&#160;  because # .</p>" +
          "<p><span style='font-weight:600'>3 </span>I revise for my # exam</p>" +
          "<p>&ensp;&#160;   because # .</p>" +
          "<p><span style='font-weight:600'>4 </span>I sometimes ask questions in #</p>" +
          "<p>&ensp;&#160;  because # .</p>" +
          "<p><span style='font-weight:600'>5 </span>I check my # homework</p>" +
          "<p>&ensp;&#160;   because # .</p>" +
          "<p><span style='font-weight:600'>6 </span>I always listen to #</p>" +
          "<p>&ensp;&#160;  because # .</p>",
        answer: [

        ],
        type: 'longAnwser'
      },
    ]
  },
}

export default json;