
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
  1: { // Exercise num
    unit: 'Unit 1',
    id: 'WB6-U1-P17-E1',
    audio: '',
    video: '',
    component: T1,
    exerciseKey: 'img/FriendsPlus/Page17/E1/Key/Key.png',
    titleImage: '',
    titleQuestion: [{ num: '1', title: 'Rewrite the sentences with the adjective in the correct place.', color: "#00A851", star: 1 }],
    questionImage: [],
    questions: [
      {
        title: "<p>Prague is a city (famous)</p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>Prague is a famous city.</u></p>" +
          "<p>1. There are some parks near here. (pretty) # </p>" +
          "<p>2. The Shard is a building. (modern) # </p>" +
          "<p>3. Is there a café in Station Street? (friendly) # </p>" +
          "<p>4. This is a shopping centre. (really clean) # </p>" +
          "<p>5. That isn't a swimming pool. (very safe) # </p>" +
          "<p>6. My school has got a library. (really fantastic) # </p>",
        answer: [
          "There are some pretty parks near here.",
          "The Shard is a modern building.",
          "Is there a friendly café in Station Street?",
          "This is a really clean shopping centre.",
          "That isn't a very safe swimming pool.",
          "My school has got a really fantastic library.",
        ],
        type: 'longAnwser'
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 1',
    id: 'WB6-U1-P17-E2',
    audio: '',
    video: '',
    component: T1,
    fontSize: 26,
    height: 28,
    titleImage: '',
    exerciseKey: 'img/FriendsPlus/Page17/E2/Key/answerKey.png',
    titleQuestion: [{ num: '2', title: 'Order the words to make sentences.', color: "#00A851", star: 2 }],
    questionImage: [],
    questions: [
      {
        title: "<p>Is your town nice? <strong>very / a / place / Yes, / it's / friendly</strong></p><p><span style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u><b>&nbsp;&nbsp;Yes, it's a very friendly place.&nbsp;&nbsp;</b></u></span></p>" +
          "<p>1. Are these shop good?<br/><strong> they / No, / aren't / clean / very #</p>" +
          "<p>2. Is this a nice area?<br/><strong> are / Yes, / there / squares / pretty / here / some #</p>" +
          "<p>3. Is your city modern?<br/><strong> it's / really / got / new / shopping centre / a / Yes, #</p>" +
          "<p>4. Where are those hotel?<br/><strong> street / They're / quiet / a / in #</p>" +
          "<p>5. Are there any dangerous areas in that town?<br/><strong> safe / they're / No, / very #</p>" +
          "<p>6. Where is your house?<br/><strong> small / It's / a / town / really / in # </p>",
        answer: [
          "No, they aren't very clean",
          'Yes, there are some pretty squares here',
          "Yes, it's got a really new shopping centre",
          "They're in a quiet street",
          "No, they're very safe",
          "It's in a really small town",
        ],
        type: 'longAnwser'
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 1',
    id: 'WB6-U1-P17-E3',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page17/E3/Key/Key.png',
    // titleImage: 'http://',
    // titleQuestion: [{ num: '3', title: 'Read the information about Leeds. Then complete the email.', color: "#00A851", star: 2 }],
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page17/E3/2.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E3/3.jpg' },
        { url: 'img/FriendsPlus/Page17/E3/4.jpg', input: true, answer: "north of England" },
        { url: 'img/FriendsPlus/Page17/E3/5.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E3/6.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E3/7.jpg' },
        { url: 'img/FriendsPlus/Page17/E3/8.jpg', input: true, answer: "270 km" },
        { url: 'img/FriendsPlus/Page17/E3/9.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E3/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E3/11.jpg' },
        { url: 'img/FriendsPlus/Page17/E3/12.jpg', input: true, answer: "800,000" },
        { url: 'img/FriendsPlus/Page17/E3/13.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E3/14.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E3/15.jpg' },
        { url: 'img/FriendsPlus/Page17/E3/16.jpg', input: true, answer: "fantastic restaurants" },
        { url: 'img/FriendsPlus/Page17/E3/17.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E3/18.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E3/19.jpg' },
        { url: 'img/FriendsPlus/Page17/E3/20.jpg', input: true, answer: "with modern shops" },
        { url: 'img/FriendsPlus/Page17/E3/21.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E3/22.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E3/23.jpg' },
        { url: 'img/FriendsPlus/Page17/E3/24.jpg', input: true, answer: "shopping" },
        { url: 'img/FriendsPlus/Page17/E3/25.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E3/26.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E3/27.jpg' },
        { url: 'img/FriendsPlus/Page17/E3/28.jpg', input: true, answer: "football team" },
        { url: 'img/FriendsPlus/Page17/E3/29.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E3/30.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E3/31.jpg' },
        { url: 'img/FriendsPlus/Page17/E3/32.jpg', input: true, answer: "exciting" },
        { url: 'img/FriendsPlus/Page17/E3/33.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E3/34.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E3/35.jpg' },
        { url: 'img/FriendsPlus/Page17/E3/36.jpg', input: true, answer: "friendly" },
        { url: 'img/FriendsPlus/Page17/E3/37.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E3/38.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E3/39.jpg' },
        { url: 'img/FriendsPlus/Page17/E3/40.jpg', input: true, answer: "Manchester and Sheffield" },
        { url: 'img/FriendsPlus/Page17/E3/41.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E3/42.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E3/43.jpg' },
        { url: 'img/FriendsPlus/Page17/E3/44.jpg', input: true, answer: "far" },
        { url: 'img/FriendsPlus/Page17/E3/45.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E3/46.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E3/47.jpg' },
        { url: 'img/FriendsPlus/Page17/E3/48.jpg', input: true, answer: "more" },
        { url: 'img/FriendsPlus/Page17/E3/49.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E3/50.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 1',
    id: 'WB6-U1-P17-E4',
    audio: '',
    video: '',
    component: T2,
    exerciseKey: '',
    titleImage: '',
    titleQuestion: [{ num: '4', title: 'Write an email to a friend about your city. Use the text in exercise 3 to help you.', color: "#00A851", star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}

export default json;