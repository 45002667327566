import React from 'react';
import { useDrag } from 'react-dnd';
import { dragItemTypes } from '../../../../../constants';

const DragItem = ({ style, payload, disable, ...props }) => {
  const [{ isDragging }, drag] = useDrag({
    canDrag: () => !disable,
    item: { type: dragItemTypes.CARD, payload },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    }),
  });

  return (
    <div
      ref={drag}
      style={{
        ...style,
        opacity: isDragging ? 0.5 : 1,
        cursor: isDragging ? 'grabbing' : 'grab',
      }}
      {...props}
    />
  );
}

export default DragItem;
