import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T5 from '../../components/ExcerciseTypes/TypeIn/T5';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import S4 from '../../components/ExcerciseTypes/Select/Select7';


const json = {
  // 1: { // Exercise num
  unit : 'Unit 6',
  //   id : 'WB6-U6-P42-E1',
  //   // audio: 'img/FriendsPlus/Page42/E1/Audio/07- Starter Unit - Track 07.mp3',
  //   video: '',
  //   component: S4,
  //   width: 220,
  //   exerciseKey: 'img/FriendsPlus/Page42/E1/Key/answerKey.png',
  //   titleImage: 'img/FriendsPlus/Page42/E1/title.jpg',
  //   questions: [
  //     {
  //       no: "1",
  //       text: "cycling",
  //       image: "img/FriendsPlus/Page42/E1/04.jpg",
  //     },
  //     {
  //       no: "2",
  //       text: "horse-riding",
  //       image: "img/FriendsPlus/Page42/E1/05.jpg",
  //     },
  //     {
  //       no: "3",
  //       text: "wrestling",
  //       image: "img/FriendsPlus/Page42/E1/06.jpg",
  //     },
  //     {
  //       no: "4",
  //       text: "basketball",
  //       image: "img/FriendsPlus/Page42/E1/08.jpg",
  //     },
  //     {
  //       no: "5",
  //       text: "tennis",
  //       image: "img/FriendsPlus/Page42/E1/09.jpg",
  //     },
  //     {
  //       no: "6",
  //       text: "golf",
  //       image: "img/FriendsPlus/Page42/E1/10.jpg",
  //     },

  //   ]
  // },

  1: {
    unit : 'Unit 6',
    id : 'WB6-U6-P42-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page42/E1/Key/answerKey.png',
    component: D1,
    fontSize: 25,
    height: 45,
    // titleQuestion: [{ num: '1', title: `Listen to the street interviews and tick (✔) the correct box. What is the person’s favourite building?`, star: 2, color: '#F68025' }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page42/E1/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page42/E1/3.jpg', input: true, answer: "cycling" },
        { url: 'img/FriendsPlus/Page42/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page42/E1/5.jpg', input: true, answer: "horse-riding" },
        { url: 'img/FriendsPlus/Page42/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page42/E1/7.jpg', input: true, answer: "wrestling" },
        { url: 'img/FriendsPlus/Page42/E1/8.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E1/9.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page42/E1/11.jpg', input: true, answer: "basketball" },
        { url: 'img/FriendsPlus/Page42/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page42/E1/13.jpg', input: true, answer: "tennis" },
        { url: 'img/FriendsPlus/Page42/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page42/E1/15.jpg', input: true, answer: "golf" },
        { url: 'img/FriendsPlus/Page42/E1/16.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  2: {
    unit : 'Unit 6',
    id : 'WB6-U6-P42-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page42/E2/Key/Key.png',
    component: D1,
    fontSize: 25,
    // titleQuestion: [{ num: '1', title: `Listen to the street interviews and tick (✔) the correct box. What is the person’s favourite building?`, star: 2, color: '#F68025' }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page42/E2/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/3.jpg', input: true, answer: "horse-riding" },
        { url: 'img/FriendsPlus/Page42/E2/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/5.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/7.jpg', input: true, answer: "athletics" },
        { url: 'img/FriendsPlus/Page42/E2/8.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/9.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/11.jpg', input: true, answer: "volleyball" },
        { url: 'img/FriendsPlus/Page42/E2/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/13.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/15.jpg', input: true, answer: "wrestling" },
        { url: 'img/FriendsPlus/Page42/E2/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/17.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/18.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/19.jpg', input: true, answer: "football / basketball" },
        { url: 'img/FriendsPlus/Page42/E2/20.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/21.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/22.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/23.jpg', input: true, answer: "basketball / football" },
        { url: 'img/FriendsPlus/Page42/E2/24.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/25.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/26.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/27.jpg', input: true, answer: "hockey" },
        { url: 'img/FriendsPlus/Page42/E2/28.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/29.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/30.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/31.jpg', input: true, answer: "climbing" },
        { url: 'img/FriendsPlus/Page42/E2/32.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/33.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/34.jpg', input: true, answer: "skiing" },
        { url: 'img/FriendsPlus/Page42/E2/35.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/36.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  3: { // Exercise num
    unit : 'Unit 6',
    id : 'WB6-U6-P42-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page42/E3/Key/Key.png',
    imgSize: '70%',
    inputSize: 170,
    titleImage: 'img/FriendsPlus/Page42/E3/title.jpg',
    // titleQuestion: [{ num: '4', title: 'Imagine you take a special lunch to school every day. What is in your perfect lunchbox? ', color: "#036ab8", star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>These are every four years. You can watch them on TV.</p>" +
          "<p><u style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px;'>Olympic Games</u></p>" +
          "<p><span style='font-weight:600'>1 </span> This person likes a team a lot! # </p>" +
          "<p><span style='font-weight:600'>2 </span> This is a game of tennis or football. # </p>" +
          "<p><span style='font-weight:600'>3 </span> You can play golf, tennis or hockey with this thing.</p>" +
          "<p><span style='font-weight:600'></span>  # </p>" +
          "<p><span style='font-weight:600'>4 </span> This person plays a sport like tennis, hockey or  </p>" +
          "<p><span style='font-weight:600'></span> football. #</p>" +
          "<p><span style='font-weight:600'>5 </span> You go to this very big place to watch a game of  </p>" +
          "<p><span style='font-weight:600'></span> football or rugby.  #</p>" +
          "<p><span style='font-weight:600'>6 </span> This person is the best at his or her sport.</p>" +
          "<p><span style='font-weight:600'></span> #</p>" +
          "<p><span style='font-weight:600'>7 </span> This is a group of people in a game such as </p>" +
          "<p><span style='font-weight:600'></span>football.  # </p>" +
          "<p><span style='font-weight:600'>8 </span> This is when people try to run or cycle the  </p>" +
          "<p><span style='font-weight:600'></span>fastest. # </p>" +
          "<p><span style='font-weight:600'>9 </span> When a lot of people come to a place and play </p>" +
          "<p><span style='font-weight:600'></span>sport to find  the best person or team.  # </p>",
        answer: [
          "fan",
          "match",
          "ball",
          "player",
          "stadium",
          "champion",
          "team",
          "race",
          "competition",
        ],
        type: 'longAnwser'
      },
    ]
  },

  4: { // Exercise num
    unit : 'Unit 6',
    id : 'WB6-U6-P42-E4',
    audio: '',
    video: '',
    component: T5,
    exerciseKey: '',
    titleImage: '',
    inputSize: 700,
    titleQuestion: [{ num: '4', title: 'Complete the sentences.', color: "#026aaf", star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>I like playing &#160;<u style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px;'>basketball because it’s fast and exciting.</u></p>" +
          "<p><span style='font-weight:600'>1 </span>I love playing # .</p>" +
          "<p><span style='font-weight:600'>2 </span>I like watching #on TV #.</p>" +
          "<p><span style='font-weight:600'>3 </span>I don’t like watching #on TV #.</p>" +
          "<p><span style='font-weight:600'>4 </span>I don’t like playing # .</p>" +
          "<p><span style='font-weight:600'>5 </span>I don’t understand # .</p>" +
          "<p><span style='font-weight:600'>6 </span>My friend is really into # .</p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}
export default json