import MCTI1 from '../../components/ExcerciseTypes/MultipleChoice&TypeIn/MC&TI1';
import T5 from '../../components/ExcerciseTypes/TypeIn/T5';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {

  1: { // Exercise num
    unit: 'Starter Unit',
    id: 'WB6-S-P11-E1',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 6,
    exerciseKey: 'img/FriendsPlus/Page11/E1/Key/answerKey.png',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page11/E1/03.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page11/E1/05.jpg' },
        { url: 'img/FriendsPlus/Page11/E1/06.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page11/E1/07.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page11/E1/08.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page11/E1/09.jpg' },
        { url: 'img/FriendsPlus/Page11/E1/10.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page11/E1/11.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page11/E1/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page11/E1/13.jpg' },
        { url: 'img/FriendsPlus/Page11/E1/14.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page11/E1/15.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page11/E1/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page11/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page11/E1/18.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page11/E1/19.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page11/E1/20.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page11/E1/21.jpg' },
        { url: 'img/FriendsPlus/Page11/E1/22.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page11/E1/23.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page11/E1/24.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page11/E1/25.jpg' },
        { url: 'img/FriendsPlus/Page11/E1/26.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page11/E1/27.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page11/E1/28.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },


  2: { // Exercise num
    unit: 'Starter Unit',
    id: 'WB6-S-P11-E2',
    audio: '',
    video: '',
    component: MCTI1,
    exerciseKey: 'img/FriendsPlus/Page11/E2/Key/answerKey.png',
    width: '70%',
    titleImageQuestion: 'img/FriendsPlus/Page11/E2/imgQuestion.jpg',
    titleImage: 'img/FriendsPlus/Page11/E2/title.jpg',
    questionImage: [ // Row
      [
        // Column
        { url: 'http://' },
        { url: 'http://', input: true },
        { url: 'http://' },
      ],
    ],
    questions: [
      {
        title: `# the capital of Việt Nam?`,
        title1: 'It’s ....... .',
        answers: [
          { image: "", isCorrect: false, right: "", text: "Hội An" },
          { image: "", isCorrect: true, right: "", text: "Hà Nội" },
          { image: "", isCorrect: false, right: "", text: "Huế" },
        ],
        titleAnswer: `What's`,
        no: 1,
        question: '',
        type: '',
      },
      {
        title: `# Elsa and Anna?`,
        title1: 'They’re the stars of the film ....... .',
        answers: [
          { image: "", isCorrect: true, right: "", text: "<i>Frozen</i>" },
          { image: "", isCorrect: false, right: "", text: "<i>How to Train your Dragon</i>" },
          { image: "", isCorrect: false, right: "", text: "<i>Toy Story</i>" },
        ],
        titleAnswer: 'Who are',
        no: 2,
        question: '',
        type: '',
      },
      {
        title: '# the Statue of Liberty?',
        title1: 'It’s in ....... .',
        answers: [
          { image: "", isCorrect: false, right: "", text: "India" },
          { image: "", isCorrect: false, right: "", text: "Mexico" },
          { image: "", isCorrect: true, right: "", text: "the USA" },
        ],
        titleAnswer: 'Where is',
        no: 3,
        question: '',
        type: '',
      },
      {
        title: '# Maria Sharapova?',
        title1: 'She’s a popular ....... star from Russia.',
        answers: [
          { image: "", isCorrect: true, right: "", text: "tennis" },
          { image: "", isCorrect: false, right: "", text: "basketball" },
          { image: "", isCorrect: false, right: "", text: "cycling" },
        ],
        titleAnswer: 'Who is',
        no: 4,
        question: '',
        type: '',
      },
      {
        title: '# the currency of the USA?',
        title1: 'It’s the ....... .',
        answers: [
          { image: "", isCorrect: false, right: "", text: "yen" },
          { image: "", isCorrect: true, right: "", text: "dollar" },
          { image: "", isCorrect: false, right: "", text: "dinar" },
        ],
        titleAnswer: `What's`,
        no: 5,
        question: '',
        type: '',
      },
    ]
  },

  3: { // Exercise num
    unit: 'Starter Unit',
    id: 'WB6-S-P11-E3',
    audio: '',
    video: '',
    // exerciseKey: 'img/FriendsPlus/Page9/E3/Key/Key.png',
    component: T5,
    inputSize: 600,
    titleImage: 'img/FriendsPlus/Page11/E3/title.jpg',
    imgSize: '70%',
    // titleQuestion: [{ num: '5', title: 'Write sentences using affirmative and negative forms of have got.', color: '#28b9d8', star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray'>What’s your name? It’s Selin.</span></p>" +
          "<p><span style='font-weight:600'></span> # </p>" +
          "<p><span style='font-weight:600'></span> # </p>" +
          "<p><span style='font-weight:600'></span> # </p>" +
          "<p><span style='font-weight:600'></span> # </p>" +
          "<p><span style='font-weight:600'></span> # </p>" +
          "<p><span style='font-weight:600'></span> # </p>" +
          "<p><span style='font-weight:600'></span> # </p>" +
          "<p><span style='font-weight:600'></span> # </p>",
        answer: [
        ],
        type: 'longAnwser'
      },
    ]
  },

  4: { // Exercise num
    unit: 'Starter Unit',
    id: 'WB6-S-P11-E4',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 6,
    exerciseKey: 'img/FriendsPlus/Page11/E4/Key/answerKey.png',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page11/E4/03.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page11/E4/05.jpg' },
        { url: 'img/FriendsPlus/Page11/E4/06.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page11/E4/07.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page11/E4/08.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page11/E4/09.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page11/E4/10.jpg' },
        { url: 'img/FriendsPlus/Page11/E4/11.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page11/E4/12.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page11/E4/13.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page11/E4/14.jpg' },
        { url: 'img/FriendsPlus/Page11/E4/15.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page11/E4/16.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page11/E4/17.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page11/E4/18.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page11/E4/19.jpg' },
        { url: 'img/FriendsPlus/Page11/E4/20.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page11/E4/21.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page11/E4/22.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page11/E4/23.jpg' },
        { url: 'img/FriendsPlus/Page11/E4/24.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page11/E4/25.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page11/E4/26.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page11/E4/27.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page11/E4/28.jpg' },
        { url: 'img/FriendsPlus/Page11/E4/29.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page11/E4/30.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page11/E4/31.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  5: { // Exercise num
    unit: 'Starter Unit',
    id: 'WB6-S-P11-E5',
    audio: '',
    video: '',
    component: D1,
    fontSize: 26,
    height: 28,
    exerciseKey: 'img/FriendsPlus/Page11/E5/Key/answerKey.png',
    // titleQuestion: [{ num: '5', title: 'Complete the text with <i>and</i>, <i>or</i> or <i>but</i>.', color: '#28b9d8', star: 2 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page11/E5/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page11/E5/2.jpg' },
        { url: 'img/FriendsPlus/Page11/E5/3.jpg', input: true, answer: "but" },
        { url: 'img/FriendsPlus/Page11/E5/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page11/E5/5.jpg' },
        { url: 'img/FriendsPlus/Page11/E5/6.jpg', input: true, answer: "and" },
        { url: 'img/FriendsPlus/Page11/E5/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page11/E5/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page11/E5/9.jpg' },
        { url: 'img/FriendsPlus/Page11/E5/10.jpg', input: true, answer: "but" },
        { url: 'img/FriendsPlus/Page11/E5/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page11/E5/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page11/E5/13.jpg' },
        { url: 'img/FriendsPlus/Page11/E5/14.jpg', input: true, answer: "and" },
        { url: 'img/FriendsPlus/Page11/E5/15.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page11/E5/16.jpg' },
        { url: 'img/FriendsPlus/Page11/E5/17.jpg', input: true, answer: "and" },
        { url: 'img/FriendsPlus/Page11/E5/18.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page11/E5/19.jpg' },
        { url: 'img/FriendsPlus/Page11/E5/20.jpg', input: true, answer: "but" },
        { url: 'img/FriendsPlus/Page11/E5/21.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page11/E5/22.jpg' },
        { url: 'img/FriendsPlus/Page11/E5/23.jpg', input: true, answer: "or" },
        { url: 'img/FriendsPlus/Page11/E5/24.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page11/E5/25.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page11/E5/26.jpg' },
        { url: 'img/FriendsPlus/Page11/E5/27.jpg', input: true, answer: "or" },
        { url: 'img/FriendsPlus/Page11/E5/28.jpg' },
      ],
      [
        // Column13
        { url: 'img/FriendsPlus/Page11/E5/29.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

}

export default json