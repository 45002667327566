
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {

    1: { // Exercise num
        unit : 'Cumulative review Starter unit-Unit 4',
        id : 'WB6-CR2-P61-E1',
        audio: '',
        video: '',
        imgSize: 800,
        component: T1,
        exerciseKey: 'img/FriendsPlus/Page61/E1/Key/answerKey.png',
        titleImage: 'img/FriendsPlus/Page61/E1/title.jpg',
        //titleQuestion: [{ num: '2', title: ' ', color: "#4D3D9B", star: 2 }],
        questionImage: [],
        questions: [
            {
                title:

                    "<p>#</p>" +
                    "<p>#</p>",


                answer: [
                    "They're at the zoo.",
                    "They're looking at a giraffe.",

                ],
                type: 'longAnwser'
            },
        ]
    },
    2: { // Exercise num
        unit : 'Cumulative review Starter unit-Unit 4',
        id : 'WB6-CR2-P61-E2',
        audio: '',
        video: '',
        component: T6,
        inputSize: 800,
        imgSize:'75%',
        exerciseKey: 'img/FriendsPlus/Page61/E2/Key/answerKey.png',
        titleImage: 'img/FriendsPlus/Page61/E2/title.jpg',
        //titleQuestion: [{ num: '2', title: 'Read the text. What is Hannah’s project about? What is she learning about at school?', color: "#4D3D9B" }],
        questionImage: [],
        questions: [
            {
                title:

                    "<p>#</p>" +
                    "<p>#</p>",


                answer: [
                    "It's about the giraffes at London Zoo.",
                    "She's learning about some of the most important animals in danger.",

                ],
                type: 'longAnwser'
            },
        ]
    },
    3: { // Exercise num
        unit : 'Cumulative review Starter unit-Unit 4',
        id : 'WB6-CR2-P61-E3',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page61/E3/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page61/E3/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page61/E3/2.jpg' },
                { url: 'img/FriendsPlus/Page61/E3/3.jpg', input: true, answer: "How many" },
                { url: 'img/FriendsPlus/Page61/E3/4.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page61/E3/5.jpg' },
                { url: 'img/FriendsPlus/Page61/E3/6.jpg', input: true, answer: "over a million people" },
                { url: 'img/FriendsPlus/Page61/E3/7.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page61/E3/8.jpg' },
                { url: 'img/FriendsPlus/Page61/E3/9.jpg', input: true, answer: "Who" },
                { url: 'img/FriendsPlus/Page61/E3/10.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page61/E3/11.jpg' },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page61/E3/12.jpg' },
                { url: 'img/FriendsPlus/Page61/E3/13.jpg', input: true, answer: "a zookeeper" },
                { url: 'img/FriendsPlus/Page61/E3/14.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page61/E3/15.jpg' },
                { url: 'img/FriendsPlus/Page61/E3/16.jpg', input: true, answer: "What" },
                { url: 'img/FriendsPlus/Page61/E3/17.jpg' },
            ],
            [
                // Column8
                { url: 'img/FriendsPlus/Page61/E3/18.jpg' },
                { url: 'img/FriendsPlus/Page61/E3/19.jpg', input: true, answer: "hunting" },
                { url: 'img/FriendsPlus/Page61/E3/20.jpg' },
            ],
            [
                // Column9
                { url: 'img/FriendsPlus/Page61/E3/21.jpg' },
                { url: 'img/FriendsPlus/Page61/E3/22.jpg', input: true, answer: "How many" },
                { url: 'img/FriendsPlus/Page61/E3/23.jpg' },
            ],
            [
                // Column10
                { url: 'img/FriendsPlus/Page61/E3/24.jpg' },
                { url: 'img/FriendsPlus/Page61/E3/25.jpg', input: true, answer: "around 80,000" },
                { url: 'img/FriendsPlus/Page61/E3/26.jpg' },
            ],


        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    4: { // Exercise num
        unit : 'Cumulative review Starter unit-Unit 4',
        id : 'WB6-CR2-P61-E4',
        audio: 'img/FriendsPlus/Page61/E4/Audio/Friends Plus for Vietnam G6 WB Track 11.mp3',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page61/E4/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page61/E4/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page61/E4/2.jpg' },
                { url: 'img/FriendsPlus/Page61/E4/3.jpg', input: true, answer: "Yes, she can take photos." },
                { url: 'img/FriendsPlus/Page61/E4/4.jpg' },
            ],


        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    5: { // Exercise num
        unit : 'Cumulative review Starter unit-Unit 4',
        id : 'WB6-CR2-P61-E5',
        audio: 'img/FriendsPlus/Page61/E5/Audio/Friends Plus for Vietnam G6 WB Track 11.mp3',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page61/E5/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page61/E5/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page61/E5/2.jpg' },
                { url: 'img/FriendsPlus/Page61/E5/3.jpg', input: true, answer: "1,000" },
                { url: 'img/FriendsPlus/Page61/E5/4.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page61/E5/5.jpg' },
                { url: 'img/FriendsPlus/Page61/E5/6.jpg', input: true, answer: "five" },
                { url: 'img/FriendsPlus/Page61/E5/7.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page61/E5/8.jpg' },
                { url: 'img/FriendsPlus/Page61/E5/9.jpg', input: true, answer: "fifty-five" },
                { url: 'img/FriendsPlus/Page61/E5/10.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page61/E5/11.jpg' },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page61/E5/12.jpg' },
                { url: 'img/FriendsPlus/Page61/E5/13.jpg', input: true, answer: "two" },
                { url: 'img/FriendsPlus/Page61/E5/14.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page61/E5/15.jpg' },
            ],
            [
                // Column8
                { url: 'img/FriendsPlus/Page61/E5/16.jpg' },
                { url: 'img/FriendsPlus/Page61/E5/17.jpg', input: true, answer: "twenty" },
                { url: 'img/FriendsPlus/Page61/E5/18.jpg' },
            ],
            [
                // Column9
                { url: 'img/FriendsPlus/Page61/E5/19.jpg' },
            ],

        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    6: { // Exercise num
        unit : 'Cumulative review Starter unit-Unit 4',
        id : 'WB6-CR2-P61-E6',
        audio: '',
        video: '',
        component: T2,
        exerciseKey: '',
        titleImage: 'img/FriendsPlus/Page61/E6/title.jpg',
        //titleQuestion: [{ num: '4', title: '', color: '#19BED7', star: 3 }],
        questionImage: [],
        questions: [
            {
                title:
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>",
                answer: [],
                type: 'longAnwser'
            },
        ]
    },


}

export default json;