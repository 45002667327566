import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';
import Wordbox from '../../components/ExcerciseTypes/Design/WordBox2';

const json = {
    1: {
        unit: 'Unit 7',
        id: 'WB6-U7-P52-E1',
        audio: '',
        video: '',
        exerciseKey: 'img/FriendsPlus/Page52/E1/Key/answerKey.png',
        component: DesignUnderLine,
        totalInput: 2,
        // titleQuestion: [{ num: '2', title: `Listen to the street interviews and tick (✔) the correct box. What is the person’s favourite building?`, star: 2, color: '#F68025' }],
        questionImage: [ // Row

            [
                { url: 'img/FriendsPlus/Page52/E1/03.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page52/E1/05.jpg' },
                { url: 'img/FriendsPlus/Page52/E1/06.jpg', input: 1 },
                { url: 'img/FriendsPlus/Page52/E1/07.jpg', input: 1, isCorrect: true },
                { url: 'img/FriendsPlus/Page52/E1/08.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page52/E1/09.jpg' },
                { url: 'img/FriendsPlus/Page52/E1/10.jpg', input: 2 },
                { url: 'img/FriendsPlus/Page52/E1/11.jpg', input: 2, isCorrect: true },
                { url: 'img/FriendsPlus/Page52/E1/12.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page52/E1/13.jpg' },
            ],


        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    2: { // Exercise num
        unit: 'Unit 7',
        id: 'WB6-U7-P52-E2',
        audio: '',
        video: '',
        component: Wordbox,
        isAutofocus: true,
        fontSize: 25,
        typeInput: 'center',
        exerciseKey: 'img/FriendsPlus/Page52/E2/Key/Key.png',
        // titleImage: 'http://',
        titleQuestion: [{ num: '2', title: 'Read the text again. Put the story in the correct order. Number the sentences.', color: "#d52d32", star: 2 }],
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page52/E2/0.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page52/E2/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page52/E2/2.jpg' },
                { url: 'img/FriendsPlus/Page52/E2/3.jpg', input: true, answer: "7" },
                { url: 'img/FriendsPlus/Page52/E2/4.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page52/E2/5.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page52/E2/6.jpg' },
                { url: 'img/FriendsPlus/Page52/E2/7.jpg', input: true, answer: "3" },
                { url: 'img/FriendsPlus/Page52/E2/8.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page52/E2/9.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page52/E2/10.jpg' },
                { url: 'img/FriendsPlus/Page52/E2/11.jpg', input: true, answer: "5" },
                { url: 'img/FriendsPlus/Page52/E2/12.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page52/E2/13.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page52/E2/14.jpg' },
                { url: 'img/FriendsPlus/Page52/E2/15.jpg', input: true, answer: "4" },
                { url: 'img/FriendsPlus/Page52/E2/16.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page52/E2/17.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page52/E2/18.jpg' },
                { url: 'img/FriendsPlus/Page52/E2/19.jpg', input: true, answer: "8" },
                { url: 'img/FriendsPlus/Page52/E2/20.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page52/E2/21.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page52/E2/22.jpg' },
                { url: 'img/FriendsPlus/Page52/E2/23.jpg', input: true, answer: "6" },
                { url: 'img/FriendsPlus/Page52/E2/24.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page52/E2/25.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page52/E2/26.jpg' },
                { url: 'img/FriendsPlus/Page52/E2/27.jpg', input: true, answer: "2" },
                { url: 'img/FriendsPlus/Page52/E2/28.jpg' },
            ],
        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    3: { // Exercise num
        unit: 'Unit 7',
        id: 'WB6-U7-P52-E3',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page52/E3/Key/Key.png',
        // titleImage: 'http://',
        titleQuestion: [{ num: '3', title: 'Read the text again. Write True or False.', color: "#d52d32", star: 2 }],
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page52/E3/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page52/E3/2.jpg' },
                { url: 'img/FriendsPlus/Page52/E3/3.jpg', input: true, answer: "True" },
                { url: 'img/FriendsPlus/Page52/E3/4.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page52/E3/5.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page52/E3/6.jpg' },
                { url: 'img/FriendsPlus/Page52/E3/7.jpg', input: true, answer: "False" },
                { url: 'img/FriendsPlus/Page52/E3/8.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page52/E3/9.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page52/E3/10.jpg' },
                { url: 'img/FriendsPlus/Page52/E3/11.jpg', input: true, answer: "True" },
                { url: 'img/FriendsPlus/Page52/E3/12.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page52/E3/13.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page52/E3/14.jpg' },
                { url: 'img/FriendsPlus/Page52/E3/15.jpg', input: true, answer: "False" },
                { url: 'img/FriendsPlus/Page52/E3/16.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page52/E3/17.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page52/E3/18.jpg' },
                { url: 'img/FriendsPlus/Page52/E3/19.jpg', input: true, answer: "False" },
                { url: 'img/FriendsPlus/Page52/E3/20.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page52/E3/21.jpg' },
            ],
        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    4: { // Exercise num
        unit: 'Unit 7',
        id: 'WB6-U7-P52-E4',
        audio: '',
        video: '',
        component: T1,
        exerciseKey: 'img/FriendsPlus/Page52/E4/Key/Key.png',
        // titleImage: 'img/FriendsPlus/Page51/E4/title.jpg',
        titleQuestion: [{ num: '4', title: ' Answer the questions. Write complete sentences.', color: '#d52d32', star: 3 }],
        questionImage: [],
        questions: [
            {
                title: "<p>How old was Chris Hesford?</p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>He was twenty-two years old.</u></p>" +
                    "<p>1 Why was Chris very sad about the camera?</p><p style='font-weight:500'>#</p>" +
                    "<p>2  Where was the camera when Steve found it? </p><p style='font-weight:500'>#</p>" +
                    "<p>3  Where was Chris in a lot of the photos?</p><p style='font-weight:500'>#</p>" +
                    "<p>4  Where is Chris now?</p><p style='font-weight:500'>#</p>" +
                    "<p>5  What type of person do you think Steve is?</p><p style='font-weight:500'>#</p>",
                answer: [
                    "He was sad because his holiday photos were on it./Because it went into the water.",
                    "It was ninety-seven km from Byron Bay.",
                    "He was in front of a monument or in a famous place.",
                    "He's home in the UK.",
                    "He's a nice person.",
                ],
                type: 'longAnwser'
            },
        ]
    },

    5: { // Exercise num
        unit: 'Unit 7',
        id: 'WB6-U7-P52-E5',
        audio: '',
        video: '',
        component: T6,
        exerciseKey: 'img/FriendsPlus/Page52/E5/Key/Key.png',
        imgSize: '60%',
        inputSize: 180,
        titleImage: 'img/FriendsPlus/Page52/E5/title.jpg',
        // titleQuestion: [{ num: '4', title: 'Imagine you take a special lunch to school every day. What is in your perfect lunchbox? ', color: "#036ab8", star: 3 }],
        questionImage: [],
        questions: [
            {
                title:
                    "<p>When Dad was young he &#160;&#160;<u style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px;'> had </u>&#160;&#160; curly hair. </p>" +
                    "<p><span style='font-weight:600'>1 </span>Paul is tall. He # a lot last year.</p>" +
                    "<p><span style='font-weight:600'>2 </span>I # a nice present for Jane. </p>" +
                    "<p><span style='font-weight:600'>3 </span>My aunt # me some money.</p>" +
                    "<p><span style='font-weight:600'>4 </span>My mum # a photo of me.</p>" +
                    "<p><span style='font-weight:600'>5 </span>I # photos of my favourite film star.</p>" +
                    "<p>I’ve got a lot of them!</p>",
                answer: [
                    "grew",
                    "made",
                    "gave",
                    "took",
                    "collected",
                ],
                type: 'longAnwser'
            },
        ]
    },
}
export default json;