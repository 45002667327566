
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {

  1: { // Exercise num
    unit: 'Starter Unit',
    id: 'WB6-S-P10-E1',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 7,
    exerciseKey: 'img/FriendsPlus/Page10/E1/Key/answerKey.png',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page10/E1/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page10/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/3.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page10/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/5.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page10/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/7.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page10/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/9.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page10/E1/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page10/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/12.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page10/E1/13.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/14.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page10/E1/15.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/16.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page10/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/18.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page10/E1/19.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page10/E1/20.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/21.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page10/E1/22.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/23.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page10/E1/24.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/25.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page10/E1/26.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/27.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page10/E1/28.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page10/E1/29.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/30.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page10/E1/31.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/32.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page10/E1/33.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/34.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page10/E1/35.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/36.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page10/E1/37.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page10/E1/38.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/39.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page10/E1/40.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/41.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page10/E1/42.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/43.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page10/E1/44.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/45.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page10/E1/46.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page10/E1/47.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/48.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page10/E1/49.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/50.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page10/E1/51.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/52.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page10/E1/53.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/54.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page10/E1/55.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page10/E1/56.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/57.jpg', input: 7 },
        { url: 'img/FriendsPlus/Page10/E1/58.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/59.jpg', input: 7 },
        { url: 'img/FriendsPlus/Page10/E1/60.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/61.jpg', input: 7, isCorrect: true },
        { url: 'img/FriendsPlus/Page10/E1/62.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/63.jpg', input: 7 },
        { url: 'img/FriendsPlus/Page10/E1/64.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page10/E1/65.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  2: { // Exercise num
    unit: 'Starter Unit',
    id: 'WB6-S-P10-E2',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page10/E2/Key/answerKey.jpg',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page10/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page10/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page10/E2/3.jpg', input: true, answer: "American" },
        { url: 'img/FriendsPlus/Page10/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page10/E2/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page10/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page10/E2/7.jpg', input: true, answer: "Spanish" },
        { url: 'img/FriendsPlus/Page10/E2/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page10/E2/9.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page10/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page10/E2/11.jpg', input: true, answer: "Brazil" },
        { url: 'img/FriendsPlus/Page10/E2/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page10/E2/13.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page10/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page10/E2/15.jpg', input: true, answer: "Australian" },
        { url: 'img/FriendsPlus/Page10/E2/16.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page10/E2/17.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page10/E2/18.jpg' },
        { url: 'img/FriendsPlus/Page10/E2/19.jpg', input: true, answer: "Japan" },
        { url: 'img/FriendsPlus/Page10/E2/20.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page10/E2/21.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page10/E2/22.jpg' },
        { url: 'img/FriendsPlus/Page10/E2/23.jpg', input: true, answer: "British" },
        { url: 'img/FriendsPlus/Page10/E2/24.jpg' },
      ],



    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  3: { // Exercise num
    unit: 'Starter Unit',
    id: 'WB6-S-P10-E3',
    audio: '',
    video: '',
    component: D1,
    fontSize: 26,
    height: 28,
    exerciseKey: 'img/FriendsPlus/Page10/E3/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page10/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page10/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page10/E3/3.jpg', input: true, answer: "Mexican" },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page10/E3/4.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page10/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page10/E3/6.jpg', input: true, answer: "Egyptian" },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page10/E3/7.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page10/E3/8.jpg' },
        { url: 'img/FriendsPlus/Page10/E3/9.jpg', input: true, answer: "Turkish" },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page10/E3/10.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page10/E3/11.jpg' },
        { url: 'img/FriendsPlus/Page10/E3/12.jpg', input: true, answer: "Indian" },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page10/E3/13.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page10/E3/14.jpg' },
        { url: 'img/FriendsPlus/Page10/E3/15.jpg', input: true, answer: "Vietnamese" },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page10/E3/16.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page10/E3/17.jpg' },
        { url: 'img/FriendsPlus/Page10/E3/18.jpg', input: true, answer: "Brazilian" },
      ],



    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  4: { // Exercise num
    unit: 'Starter Unit',
    id: 'WB6-S-P10-E4',
    audio: '',
    video: '',
    component: T2,
    exerciseKey: '',
    titleImage: 'img/FriendsPlus/Page10/E4/title.png',
    //titleQuestion: [{ num: '4', title: 'Write sentences about the capital city of your country or a big city near you.', color: '#66A0B4', star: 3 }],
    questionImage: [],
    questions: [
      {
        title: "<p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>My school friend, Ahmed, is from Egypt. The Turkish flag is red and white.</u></p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },


}
export default json