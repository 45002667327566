import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import Wordbox from '../../components/ExcerciseTypes/Design/WordBox2';


const json = {

    1: { // Exercise num
        unit: 'Unit 3',
        id: 'WB6-U3-P26-E1',
        audio: '',
        video: '',
        component: Wordbox,
        fontSize: 30,
        typeInput: 'center',
        isAutofocus: true,
        exerciseKey: 'img/FriendsPlus/Page26/E1/Key/Key.png',
        // titleImage: 'img/FriendsPlus/Page26/E1/title.jpg',
        questionImage: [ // Row

            [
                { url: 'img/FriendsPlus/Page26/E1/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page26/E1/2.jpg' },
                { url: 'img/FriendsPlus/Page26/E1/3.jpg', input: true, answer: "l" },
                { url: 'img/FriendsPlus/Page26/E1/4.jpg', input: true, answer: "y" },
                { url: 'img/FriendsPlus/Page26/E1/5.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page26/E1/6.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page26/E1/7.jpg' },
                { url: 'img/FriendsPlus/Page26/E1/8.jpg', input: true, answer: "l" },
                { url: 'img/FriendsPlus/Page26/E1/9.jpg', input: true, answer: "i" },
                { url: 'img/FriendsPlus/Page26/E1/10.jpg', input: true, answer: "m" },
                { url: 'img/FriendsPlus/Page26/E1/11.jpg', input: true, answer: "b" },
                { url: 'img/FriendsPlus/Page26/E1/12.jpg' },
                { url: 'img/FriendsPlus/Page26/E1/13.jpg', input: true, answer: "r" },
                { url: 'img/FriendsPlus/Page26/E1/14.jpg', input: true, answer: "i" },
                { url: 'img/FriendsPlus/Page26/E1/15.jpg', input: true, answer: "n" },
                { url: 'img/FriendsPlus/Page26/E1/16.jpg', input: true, answer: "k" },
                { url: 'img/FriendsPlus/Page26/E1/17.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page26/E1/18.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page26/E1/19.jpg' },
                { url: 'img/FriendsPlus/Page26/E1/20.jpg', input: true, answer: "u" },
                { url: 'img/FriendsPlus/Page26/E1/21.jpg', input: true, answer: "m" },
                { url: 'img/FriendsPlus/Page26/E1/22.jpg', input: true, answer: "p" },
                { url: 'img/FriendsPlus/Page26/E1/23.jpg' },
                { url: 'img/FriendsPlus/Page26/E1/24.jpg', input: true, answer: "e" },
                { url: 'img/FriendsPlus/Page26/E1/25.jpg', input: true, answer: "a" },
                { url: 'img/FriendsPlus/Page26/E1/26.jpg', input: true, answer: "r" },
                { url: 'img/FriendsPlus/Page26/E1/27.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page26/E1/28.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page26/E1/29.jpg' },
                { url: 'img/FriendsPlus/Page26/E1/30.jpg', input: true, answer: "u" },
                { url: 'img/FriendsPlus/Page26/E1/31.jpg', input: true, answer: "n" },
                { url: 'img/FriendsPlus/Page26/E1/32.jpg' },
                { url: 'img/FriendsPlus/Page26/E1/33.jpg', input: true, answer: "i" },
                { url: 'img/FriendsPlus/Page26/E1/34.jpg', input: true, answer: "l" },
                { url: 'img/FriendsPlus/Page26/E1/35.jpg', input: true, answer: "l" },
                { url: 'img/FriendsPlus/Page26/E1/36.jpg' },
            ],

        ],

        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },

    2: { // Exercise num
        unit: 'Unit 3',
        id: 'WB6-U3-P26-E2',
        audio: '',
        video: '',
        exerciseKey: 'img/FriendsPlus/Page26/E2/Key/Key.png',
        component: T6,
        imgSize: 700,
        inputSize: 200,
        titleImage: 'img/FriendsPlus/Page26/E2/title.jpg',
        // titleQuestion: [{ num: '5', title: ' Imagine there is a new student in your class. Write questions with be.', color: '#28b9d8', star: 3 }],
        questionImage: [],
        questions: [
            {
                title:
                    "<p>‘How often do giraffes<span style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px;'><u> drink </u></span> ?’ </p>" +
                    "<p><span style='font-weight:600'></span>‘Usually every two or three days.’</p>" +
                    "<p><span style='font-weight:600'>1 </span>‘How high can some birds # ?’</p>" +
                    "<p><span style='font-weight:600'></span>&#160;&#160;&#160;‘Up to around 10,000 metres in the sky!’</p>" +
                    "<p><span style='font-weight:600'>2 </span>‘How do blue whales # ?’</p>" +
                    "<p><span style='font-weight:600'></span>&#160;&#160;&#160;‘They sing songs.’</p>" +
                    "<p><span style='font-weight:600'>3 </span>‘How fast can some snakes # ?’</p>" +
                    "<p><span style='font-weight:600'></span>&#160;&#160;&#160;‘At around twenty-two kilometres an hour.’</p>" +
                    "<p><span style='font-weight:600'>4 </span>‘How big can a spider # ?’</p>" +
                    "<p><span style='font-weight:600'></span>&#160;&#160;&#160;‘Some spiders are thirty centimetres across.’</p>" +
                    "<p><span style='font-weight:600'>5 </span>‘How fast can a whale # ?’</p>" +
                    "<p><span style='font-weight:600'></span>&#160;&#160;&#160;‘At around sixty-five kilometres an hour.’</p>" +
                    "<p><span style='font-weight:600'>6 </span>‘How long can a scorpion #</p>" +
                    "<p><span style='font-weight:600'></span>&#160;&#160;&#160;without food?’ ‘For twelve months!’</p>",
                answer: [
                    "fly",
                    "communicate",
                    "move",
                    "grow",
                    "swim",
                    "survive",
                ],
                type: 'longAnwser'
            },
        ]
    },

    3: { // Exercise num
        unit: 'Unit 3',
        id: 'WB6-U3-P26-E3',
        audio: 'img/FriendsPlus/Page26/E3/Audio/Friends Plus for Vietnam G6 WB Track 04.mp3',
        video: '',
        component: D1,
        fontSize: 25,
        exerciseKey: 'img/FriendsPlus/Page26/E3/Key/Key.png',
        // titleImage: 'img/FriendsPlus/Page26/E1/title.jpg',
        questionImage: [ // Row

            [
                { url: 'img/FriendsPlus/Page26/E3/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page26/E3/2.jpg' },
                { url: 'img/FriendsPlus/Page26/E3/3.jpg', input: true, answer: "a thousand" },
                { url: 'img/FriendsPlus/Page26/E3/4.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page26/E3/5.jpg' },
                { url: 'img/FriendsPlus/Page26/E3/6.jpg', input: true, answer: "six" },
                { url: 'img/FriendsPlus/Page26/E3/7.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page26/E3/8.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page26/E3/9.jpg' },
                { url: 'img/FriendsPlus/Page26/E3/10.jpg', input: true, answer: "900" },
                { url: 'img/FriendsPlus/Page26/E3/11.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page26/E3/12.jpg' },
                { url: 'img/FriendsPlus/Page26/E3/13.jpg', input: true, answer: "run" },
                { url: 'img/FriendsPlus/Page26/E3/14.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page26/E3/15.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page26/E3/16.jpg' },
                { url: 'img/FriendsPlus/Page26/E3/17.jpg', input: true, answer: "swim" },
                { url: 'img/FriendsPlus/Page26/E3/18.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page26/E3/19.jpg' },
                { url: 'img/FriendsPlus/Page26/E3/20.jpg', input: true, answer: "fish" },
                { url: 'img/FriendsPlus/Page26/E3/21.jpg' },
                { url: 'img/FriendsPlus/Page26/E3/22.jpg', input: true, answer: "birds" },
                { url: 'img/FriendsPlus/Page26/E3/23.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page26/E3/24.jpg' },
            ],

        ],

        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },

    4: { // Exercise num
        unit: 'Unit 3',
        id: 'WB6-U3-P26-E4',
        audio: 'img/FriendsPlus/Page26/E4/Audio/Friends Plus for Vietnam G6 WB Track 04.mp3',
        video: '',
        component: D1,
        fontSize: 25,
        exerciseKey: 'img/FriendsPlus/Page26/E4/Key/Key.png',
        // titleImage: 'img/FriendsPlus/Page26/E4/title.jpg',
        questionImage: [ // Row

            [
                { url: 'img/FriendsPlus/Page26/E4/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page26/E4/2.jpg' },
                { url: 'img/FriendsPlus/Page26/E4/3.jpg', input: true, answer: "are" },
                { url: 'img/FriendsPlus/Page26/E4/4.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page26/E4/5.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page26/E4/6.jpg' },
                { url: 'img/FriendsPlus/Page26/E4/7.jpg', input: true, answer: "can't" },
                { url: 'img/FriendsPlus/Page26/E4/8.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page26/E4/9.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page26/E4/10.jpg' },
                { url: 'img/FriendsPlus/Page26/E4/11.jpg', input: true, answer: "isn't" },
                { url: 'img/FriendsPlus/Page26/E4/12.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page26/E4/13.jpg' },
                { url: 'img/FriendsPlus/Page26/E4/14.jpg', input: true, answer: "are" },
                { url: 'img/FriendsPlus/Page26/E4/15.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page26/E4/16.jpg' },
                { url: 'img/FriendsPlus/Page26/E4/17.jpg', input: true, answer: "aren't" },
                { url: 'img/FriendsPlus/Page26/E4/18.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page26/E4/19.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page26/E4/20.jpg' },
                { url: 'img/FriendsPlus/Page26/E4/21.jpg', input: true, answer: "can" },
                { url: 'img/FriendsPlus/Page26/E4/22.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page26/E4/23.jpg' },
            ],

        ],

        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },

    5: { // Exercise num
        unit: 'Unit 3',
        id: 'WB6-U3-P26-E5',
        audio: '',
        video: '',
        exerciseKey: 'img/FriendsPlus/Page26/E5/Key/Key.png',
        component: T2,
        imgSize: 1000,
        // inputSize: 200,
        titleImage: 'img/FriendsPlus/Page26/E5/title.jpg',
        // titleQuestion: [{ num: '5', title: ' Imagine there is a new student in your class. Write questions with be.', color: '#28b9d8', star: 3 }],
        questionImage: [],
        questions: [
            {
                title:
                    "<p><span style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px;'><u> Snakes sometimes kill small birds. </u></span></p>" +
                    "<p><span style='font-weight:600'></span>#</p>" +
                    "<p><span style='font-weight:600'></span>#</p>" +
                    "<p><span style='font-weight:600'></span>#</p>" +
                    "<p><span style='font-weight:600'></span>#</p>" +
                    "<p><span style='font-weight:600'></span>#</p>" +
                    "<p><span style='font-weight:600'></span>#</p>" +
                    "<p><span style='font-weight:600'></span>#</p>" +
                    "<p><span style='font-weight:600'></span>#</p>",
                answer: [

                ],
                type: 'longAnwser'
            },
        ]
    },

}

export default json