
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';

const json = {
  1: {
    unit: 'Unit 4',
    id: 'WB6-U4-P35-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page35/E1/Key/answerKey.png',
    component: DesignUnderLine,
    totalInput: 8,
    // titleQuestion: [{ num: '2', title: `Listen to the street interviews and tick (✔) the correct box. What is the person’s favourite building?`, star: 2, color: '#F68025' }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page35/E1/03.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page35/E1/05.jpg' },
        { url: 'img/FriendsPlus/Page35/E1/06.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page35/E1/07.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page35/E1/08.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page35/E1/09.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page35/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page35/E1/11.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page35/E1/12.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page35/E1/13.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page35/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page35/E1/15.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page35/E1/16.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page35/E1/17.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page35/E1/18.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page35/E1/19.jpg' },
        { url: 'img/FriendsPlus/Page35/E1/20.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page35/E1/21.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page35/E1/22.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page35/E1/23.jpg' },
        { url: 'img/FriendsPlus/Page35/E1/24.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page35/E1/25.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page35/E1/26.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page35/E1/27.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page35/E1/28.jpg' },
        { url: 'img/FriendsPlus/Page35/E1/29.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page35/E1/30.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page35/E1/31.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page35/E1/32.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page35/E1/33.jpg' },
        { url: 'img/FriendsPlus/Page35/E1/34.jpg', input: 7, },
        { url: 'img/FriendsPlus/Page35/E1/35.jpg', input: 7, isCorrect: true },
        { url: 'img/FriendsPlus/Page35/E1/36.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page35/E1/37.jpg' },
        { url: 'img/FriendsPlus/Page35/E1/38.jpg', input: 8 },
        { url: 'img/FriendsPlus/Page35/E1/39.jpg', input: 8, isCorrect: true },
        { url: 'img/FriendsPlus/Page35/E1/40.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page35/E1/41.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 4',
    id: 'WB6-U4-P35-E2',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page35/E2/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page35/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page35/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page35/E2/3.jpg', input: true, answer: "good at art, so I help my sister." },
        { url: 'img/FriendsPlus/Page35/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page35/E2/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page35/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page35/E2/7.jpg', input: true, answer: "elephant is old, so it can't run." },
        { url: 'img/FriendsPlus/Page35/E2/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page35/E2/9.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page35/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page35/E2/11.jpg', input: true, answer: "into films, so I go to the cinema." },
        { url: 'img/FriendsPlus/Page35/E2/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page35/E2/13.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page35/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page35/E2/15.jpg', input: true, answer: "hasn't got a pen, so he can't write." },
        { url: 'img/FriendsPlus/Page35/E2/16.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page35/E2/17.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page35/E2/18.jpg' },
        { url: 'img/FriendsPlus/Page35/E2/19.jpg', input: true, answer: "got an exam, so we're revising." },
        { url: 'img/FriendsPlus/Page35/E2/20.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page35/E2/21.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page35/E2/22.jpg' },
        { url: 'img/FriendsPlus/Page35/E2/23.jpg', input: true, answer: "flat is noisy, so I go to the library." },
        { url: 'img/FriendsPlus/Page35/E2/24.jpg' },
      ],



    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 4',
    id: 'WB6-U4-P35-E3',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page35/E3/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page35/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page35/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page35/E3/3.jpg', input: true, answer: "mountains" },
        { url: 'img/FriendsPlus/Page35/E3/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page35/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page35/E3/6.jpg', input: true, answer: "beautiful" },
        { url: 'img/FriendsPlus/Page35/E3/7.jpg' },
        { url: 'img/FriendsPlus/Page35/E3/8.jpg', input: true, answer: "quiet" },
        { url: 'img/FriendsPlus/Page35/E3/9.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page35/E3/10.jpg' },
        { url: 'img/FriendsPlus/Page35/E3/11.jpg', input: true, answer: "medium" },
        { url: 'img/FriendsPlus/Page35/E3/12.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page35/E3/13.jpg' },
        { url: 'img/FriendsPlus/Page35/E3/14.jpg', input: true, answer: "200 students" },
        { url: 'img/FriendsPlus/Page35/E3/15.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page35/E3/16.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page35/E3/17.jpg' },
        { url: 'img/FriendsPlus/Page35/E3/18.jpg', input: true, answer: "7.00" },
        { url: 'img/FriendsPlus/Page35/E3/19.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page35/E3/20.jpg' },
        { url: 'img/FriendsPlus/Page35/E3/21.jpg', input: true, answer: "breakfast" },
        { url: 'img/FriendsPlus/Page35/E3/22.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page35/E3/23.jpg' },
        { url: 'img/FriendsPlus/Page35/E3/24.jpg', input: true, answer: "start lessons" },
        { url: 'img/FriendsPlus/Page35/E3/25.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page35/E3/26.jpg' },
        { url: 'img/FriendsPlus/Page35/E3/27.jpg', input: true, answer: "break" },
        { url: 'img/FriendsPlus/Page35/E3/28.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page35/E3/29.jpg' },
        { url: 'img/FriendsPlus/Page35/E3/30.jpg', input: true, answer: "have lunch" },
        { url: 'img/FriendsPlus/Page35/E3/31.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page35/E3/32.jpg' },
      ],
      [
        // Column13
        { url: 'img/FriendsPlus/Page35/E3/33.jpg' },
        { url: 'img/FriendsPlus/Page35/E3/34.jpg', input: true, answer: "photography" },
        { url: 'img/FriendsPlus/Page35/E3/35.jpg' },
      ],
      [
        // Column_add
        { url: 'img/FriendsPlus/Page35/E3/53.jpg' },
        { url: 'img/FriendsPlus/Page35/E3/54.jpg', input: true, answer: "art and geography" },
        { url: 'img/FriendsPlus/Page35/E3/55.jpg' },
      ],
      [
        // Column14
        { url: 'img/FriendsPlus/Page35/E3/36.jpg' },
        { url: 'img/FriendsPlus/Page35/E3/37.jpg', input: true, answer: "are working" },
        { url: 'img/FriendsPlus/Page35/E3/38.jpg' },
      ],
      [
        // Column15
        { url: 'img/FriendsPlus/Page35/E3/39.jpg' },
        { url: 'img/FriendsPlus/Page35/E3/40.jpg', input: true, answer: "are taking" },
        { url: 'img/FriendsPlus/Page35/E3/41.jpg' },
      ],
      [
        // Column16
        { url: 'img/FriendsPlus/Page35/E3/42.jpg' },
      ],
      [
        // Column17
        { url: 'img/FriendsPlus/Page35/E3/43.jpg' },
        { url: 'img/FriendsPlus/Page35/E3/44.jpg', input: true, answer: "climbing, swimming, fishing and running" },
        { url: 'img/FriendsPlus/Page35/E3/45.jpg' },
      ],
      [
        // Column18
        { url: 'img/FriendsPlus/Page35/E3/46.jpg' },
        { url: 'img/FriendsPlus/Page35/E3/47.jpg', input: true, answer: "climbing" },
        { url: 'img/FriendsPlus/Page35/E3/48.jpg' },
      ],
      [
        // Column19
        { url: 'img/FriendsPlus/Page35/E3/49.jpg' },
      ],




    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 4',
    id: 'WB6-U4-P35-E4',
    audio: '',
    video: '',
    component: T2,
    exerciseKey: '',
    titleImage: '',
    titleQuestion: [{ num: '4', title: 'Imagine that you are on a summer camp. Write an email to a friend telling about your activities there. Use the text in exercise 3 to help you.', color: "#00A851", star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },



}

export default json;