
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  1: { // Exercise num
    unit: 'Unit 1',
    id: 'WB6-U1-P15-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page15/E1/Key/Key.jpg',
    component: D1,
    titleImage: 'http://',
    // titleQuestion: [{ num: '1', title: 'Write the comparative forms of the given adjectives', color: "#5DC8DA", star: 1 }],
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page15/E1/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page15/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page15/E1/3.jpg', input: true, answer: "bigger/dirtier/nicer/older/uglier" },
        { url: 'img/FriendsPlus/Page15/E1/4.jpg', input: true, answer: "bigger/dirtier/nicer/older/uglier" },
        { url: 'img/FriendsPlus/Page15/E1/5.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page15/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page15/E1/7.jpg', input: true, answer: "bigger/dirtier/nicer/older/uglier" },
        { url: 'img/FriendsPlus/Page15/E1/8.jpg', input: true, answer: "bigger/dirtier/nicer/older/uglier" },
        { url: 'img/FriendsPlus/Page15/E1/9.jpg', input: true, answer: "bigger/dirtier/nicer/older/uglier" },
        { url: 'img/FriendsPlus/Page15/E1/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page15/E1/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page15/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page15/E1/13.jpg', input: true, answer: "more comfortable/more exciting/more interesting" },
        { url: 'img/FriendsPlus/Page15/E1/14.jpg', input: true, answer: "more comfortable/more exciting/more interesting" },
        { url: 'img/FriendsPlus/Page15/E1/15.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page15/E1/16.jpg' },
        { url: 'img/FriendsPlus/Page15/E1/17.jpg', input: true, answer: "more comfortable/more exciting/more interesting" },
        { url: 'img/FriendsPlus/Page15/E1/18.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page15/E1/19.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page15/E1/20.jpg' },
        { url: 'img/FriendsPlus/Page15/E1/21.jpg', input: true, answer: "worse/further/better" },
        { url: 'img/FriendsPlus/Page15/E1/22.jpg', input: true, answer: "worse/further/better" },
        { url: 'img/FriendsPlus/Page15/E1/23.jpg', input: true, answer: "worse/further/better" },
        { url: 'img/FriendsPlus/Page15/E1/24.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page15/E1/25.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 1',
    id: 'WB6-U1-P15-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page15/E2/Key/Key.jpg',
    component: T6,
    titleImage: '',
    inputSize: 650,
    titleQuestion: [{ num: '2', title: ' Complete each sentence so that it means the same as the first sentence. Use the comparative form of the adjectives.', star: 2 }],
    questionImage: [],
    questions: [
      {
        title: "<div style='margin-left:15px'><p>That train is<span style='font-weight:600'> faster</span> than this bus. (slow)</p><" +
          "p>This bus <span style='font-style: italic;font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u><b>&nbsp;&nbsp;is slower than that train.&nbsp;&nbsp;</b></u></span></p><p><span style='font-weight:600'>1</span>  Markets are <span style='font-weight:600'>cheaper</span>  than shops. (expensive) </p><p>Shops #</p><p><span style='font-weight:600'>2</span>  The library is <span style='font-weight:600'>quieter</span> than the shopping centre. (noisy) </p><p>The shopping centre #</p><p><span style='font-weight:600'>3</span>  This square is <span style='font-weight:600'>smaller</span> than that park. (big) </p><p>That park #</p><p><span style='font-weight:600'>4</span> The cinema is <span style='font-weight:600'>nearer</span> to my house than the train station. (far) </p><p>The train station #</p><p><span style='font-weight:600'>5</span> These flats are <span style='font-weight:600'>better</span>  than those houses. (bad) </p><p>Those houses #</p><p><span style='font-weight:600'>6</span> The swimming pool is <span style='font-weight:600'>dirtier</span> than the sports centre. (clean)</p><p>The sports centre #</p><p><span style='font-weight:600'>7</span>  Britain is <span style='font-weight:600'>colder</span> than Turkey. (hot) </p><p>Turkey #</p></div>",
        answer: [
          'are more expensive than markets.',
          'is noisier than the library.',
          'is bigger than this square.',
          'is further from my house than the cinema.',
          'are worse than these flats.',
          'is cleaner than the swimming pool.',
          'is hotter than Britain.'
        ],
        type: 'longAnwserInLine'
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 1',
    id: 'WB6-U1-P15-E3',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page15/E3/Key/Key.jpg',
    component: T6,
    imgSize: '80%',
    inputSize: 640,
    titleImage: 'img/FriendsPlus/Page15/E3/1.jpg',
    titleQuestion: [{ num: '3', title: ' Look at the pictures of two towns, Burley and Darlton. Then write sentences about them using the comparative form of the adjectives.', star: 3 }],
    questionImage: [],
    questions: [
      {
        title: "<p>(noisy) <u style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'>Burley is noisier than Darlton.</u></p>" +
          "<p><span style='font-weight:600'>1</span> (quiet) # </p>" +
          "<p><span style='font-weight:600'>2</span> (clean) # </p><p><span style='font-weight:600'>3</span> (dangerous) # </p><p><span style='font-weight:600'>4</span> (safe) # </p><p><span style='font-weight:600'>5</span> (ugly) # </p><p><span style='font-weight:600'>6</span> (cheap) # </p><p><span style='font-weight:600'>7</span> (expensive) # </p><p><span style='font-weight:600'>8</span> (friendly) # </p>",
        answer: [
          "Darlton is quieter than Burley.",
          "Darlton is cleaner than Burley.",
          "Burley is more dangerous than Darlton.",
          "Darlton is safer than Burley.",
          "Burley is uglier than Darlton.",
          "Burley is cheaper than Darlton.",
          "Darlton is more expensive than Burley.",
          "Darlton is friendlier than Burley.",
        ],
        type: 'longAnwserInLine'
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 1',
    id: 'WB6-U1-P15-E4',
    audio: '',
    video: '',
    exerciseKey: '',
    component: T2,
    titleImage: '',
    titleQuestion: [{ num: '4', title: 'Write comparative sentences that are true for you.', star: 3 }],
    questionImage: [],
    questions: [
      {
        // eslint-disable-next-line no-useless-concat
        title: '<p>the new supermarket dsdsdsdin our area / the local shop </p>' +
          '<p style="font-style: italic;font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray"><u><b>&nbsp;&nbsp;The new supermarket in our area is better than the local shop.&nbsp;&nbsp;</b></u></p>'
          + "<p>1. our town / London # </p><p>2. my school / the other schools in my town  # </p><p>3. my parents’ bedroom / my bedroom # </p><p>4. the library at my school / the town library # </p><p>5. our class / the other classes at school # </p><p>6. climbing walls / swimming pools # </p>",
        answer: ['', '', '', '', '', ''],
        type: 'longAnwser'
      },
    ]
  },
}

export default json;