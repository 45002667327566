
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import T5 from '../../components/ExcerciseTypes/TypeIn/T5';

const json = {
    1: { // Exercise num
        unit : 'Unit 5',
        id: 'WB6-U5-P39-E1',
        audio: '',
        video: '',
        component: D1,

        exerciseKey: 'img/FriendsPlus/Page39/E1/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page39/E1/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page39/E1/2.jpg' },
                { url: 'img/FriendsPlus/Page39/E1/3.jpg', input: true, answer: "swimming" },
                { url: 'img/FriendsPlus/Page39/E1/4.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page39/E1/5.jpg' },
                { url: 'img/FriendsPlus/Page39/E1/6.jpg', input: true, answer: "doing" },
                { url: 'img/FriendsPlus/Page39/E1/7.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page39/E1/8.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page39/E1/9.jpg' },
                { url: 'img/FriendsPlus/Page39/E1/10.jpg', input: true, answer: "sitting" },
                { url: 'img/FriendsPlus/Page39/E1/11.jpg' },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page39/E1/12.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page39/E1/13.jpg' },
                { url: 'img/FriendsPlus/Page39/E1/14.jpg', input: true, answer: "revising" },
                { url: 'img/FriendsPlus/Page39/E1/15.jpg' },
            ],
            [
                // Column8
                { url: 'img/FriendsPlus/Page39/E1/16.jpg' },
                { url: 'img/FriendsPlus/Page39/E1/17.jpg', input: true, answer: "cycling" },
                { url: 'img/FriendsPlus/Page39/E1/18.jpg' },
            ],
            [
                // Column9
                { url: 'img/FriendsPlus/Page39/E1/19.jpg' },
                { url: 'img/FriendsPlus/Page39/E1/20.jpg', input: true, answer: "eating" },
                { url: 'img/FriendsPlus/Page39/E1/21.jpg' },
            ],
            [
                // Column10
                { url: 'img/FriendsPlus/Page39/E1/22.jpg' },
            ],



        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    2: { // Exercise num
        unit : 'Unit 5',
        id: 'WB6-U5-P39-E2',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page39/E2/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page39/E2/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page39/E2/2.jpg' },
                { url: 'img/FriendsPlus/Page39/E2/3.jpg', input: true, answer: "hate getting up" },
                { url: 'img/FriendsPlus/Page39/E2/4.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page39/E2/5.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page39/E2/6.jpg' },
                { url: 'img/FriendsPlus/Page39/E2/7.jpg', input: true, answer: "loves cycling" },
                { url: 'img/FriendsPlus/Page39/E2/8.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page39/E2/9.jpg' },
                { url: 'img/FriendsPlus/Page39/E2/10.jpg', input: true, answer: "don't mind cycling" },
                { url: 'img/FriendsPlus/Page39/E2/11.jpg' },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page39/E2/12.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page39/E2/13.jpg' },
                { url: 'img/FriendsPlus/Page39/E2/14.jpg', input: true, answer: "hates chatting" },
                { url: 'img/FriendsPlus/Page39/E2/15.jpg' },
            ],
            [
                // Column8
                { url: 'img/FriendsPlus/Page39/E2/16.jpg' },
                { url: 'img/FriendsPlus/Page39/E2/17.jpg', input: true, answer: "like chatting" },
                { url: 'img/FriendsPlus/Page39/E2/18.jpg' },
            ],
            [
                // Column9
                { url: 'img/FriendsPlus/Page39/E2/19.jpg' },
            ],
            [
                // Column10
                { url: 'img/FriendsPlus/Page39/E2/20.jpg' },
                { url: 'img/FriendsPlus/Page39/E2/21.jpg', input: true, answer: "love playing" },
                { url: 'img/FriendsPlus/Page39/E2/22.jpg' },
            ],
            [
                // Column11
                { url: 'img/FriendsPlus/Page39/E2/23.jpg' },
            ],
            [
                // Column12
                { url: 'img/FriendsPlus/Page39/E2/24.jpg' },
                { url: 'img/FriendsPlus/Page39/E2/25.jpg', input: true, answer: "likes playing" },
                { url: 'img/FriendsPlus/Page39/E2/26.jpg' },
            ],




        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    3: { // Exercise num
        unit : 'Unit 5',
        id: 'WB6-U5-P39-E3',
        audio: '',
        video: '',
        component: T5,
        imgSize: '70%',
        inputSize: 600,
        exerciseKey: '',
        titleImage: 'img/FriendsPlus/Page39/E3/title.jpg',
        // titleQuestion: [{ num: '3', title: 'Write about things you like or don’t like doing at these different times. Use the given words to help you.', color: '#19BED7', star: 3 }],
        questionImage: [],
        questions: [
            {
                title: "<p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray;'><u style='font-size:30px;'>On Saturdays, I love going to the sports centre with my dad.</u></p>" +
                    "<p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u style='font-size:30px;'> At weekends, I don’t mind helping my parents with the</u></p>" +
                    "<p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u style='font-size:30px;'> housework.</u></p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>",
                answer: [],
                type: 'longAnwser'
            },
        ]
    },
    4: { // Exercise num
        unit : 'Unit 5',
        id: 'WB6-U5-P39-E4',
        audio: '',
        video: '',
        component: T1,
        exerciseKey: 'img/FriendsPlus/Page39/E4/Key/answerKey.png',
        titleImage: '',
        titleQuestion: [{ num: '4', title: 'Put the words in order to make sentences.', color: "#1ABED7", star: 2 }],
        questionImage: [],
        questions: [
            {
                title: "<p>shouldn’t / a lot / eat / You / bed / before / go / you / to</p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>You shouldn’t eat a lot before you go to bed.</u></p>" +
                    "<p><span style='font-weight:600'>1</span>. revise / We / exams / our / for / should  # </p>" +
                    "<p><span style='font-weight:600'>2</span>. try / should / be / You / more / to / active  # </p>" +
                    "<p><span style='font-weight:600'>3</span>. school / lazy / at / be / shouldn’t / You  # </p>" +
                    "<p><span style='font-weight:600'>4</span>. eat / every day / You / food / should / healthy  # </p>" +
                    "<p><span style='font-weight:600'>5</span>. hours / I / sleep / night / should / for / every / eight # </p>",


                answer: [
                    "We should revise for our exams.",
                    "You should try to be more active.",
                    "You shouldn't be lazy at school.",
                    "You should eat healthy food every day.",
                    "I should sleep for eight hours every night.",

                ],
                type: 'longAnwser'
            },
        ]
    },
    5: { // Exercise num
        unit : 'Unit 5',
        id: 'WB6-U5-P39-E5',
        audio: '',
        video: '',
        fontSize: 30,
        height: 45,
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page39/E5/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page39/E5/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page39/E5/2.jpg' },
                { url: 'img/FriendsPlus/Page39/E5/3.jpg', input: true, answer: "wear" },
                { url: 'img/FriendsPlus/Page39/E5/4.jpg' },
            ],
            [
                // Column1
                { url: 'img/FriendsPlus/Page39/E5/5.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page39/E5/6.jpg' },
                { url: 'img/FriendsPlus/Page39/E5/7.jpg', input: true, answer: "Don't be" },
                { url: 'img/FriendsPlus/Page39/E5/8.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page39/E5/9.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page39/E5/10.jpg' },
                { url: 'img/FriendsPlus/Page39/E5/11.jpg', input: true, answer: "don't chat" },
                { url: 'img/FriendsPlus/Page39/E5/12.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page39/E5/13.jpg' },
                { url: 'img/FriendsPlus/Page39/E5/14.jpg', input: true, answer: "listen" },
                { url: 'img/FriendsPlus/Page39/E5/15.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page39/E5/16.jpg' },
            ],
            [
                // Column8
                { url: 'img/FriendsPlus/Page39/E5/17.jpg' },
                { url: 'img/FriendsPlus/Page39/E5/18.jpg', input: true, answer: "Don't write" },
                { url: 'img/FriendsPlus/Page39/E5/19.jpg' },
            ],
            [
                // Column9
                { url: 'img/FriendsPlus/Page39/E5/20.jpg' },
            ],
            [
                // Column10
                { url: 'img/FriendsPlus/Page39/E5/21.jpg' },
                { url: 'img/FriendsPlus/Page39/E5/22.jpg', input: true, answer: "don't use" },
                { url: 'img/FriendsPlus/Page39/E5/23.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page39/E5/24.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page39/E5/25.jpg' },
                { url: 'img/FriendsPlus/Page39/E5/26.jpg', input: true, answer: "Don't eat" },
                { url: 'img/FriendsPlus/Page39/E5/27.jpg' },
            ],
            [
                // Column11
                { url: 'img/FriendsPlus/Page39/E5/28.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page39/E5/29.jpg' },
                { url: 'img/FriendsPlus/Page39/E5/30.jpg', input: true, answer: "do" },
                { url: 'img/FriendsPlus/Page39/E5/31.jpg' },
            ],
            [
                // Column11
                { url: 'img/FriendsPlus/Page39/E5/32.jpg' },
            ],



        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },



}

export default json;