import DD2 from '../../components/ExcerciseTypes/DragAndDrop/DD2';
import MC2 from '../../components/ExcerciseTypes/MultipleChoice/MC2';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
  1: { // Exercise num
     unit : 'Unit 2',
    id : 'WB6-U2-P18-E1',
    audio: '',
    video: '',
    component: DD2,
    exerciseKey: 'img/FriendsPlus/Page18/E1/Key/Key.png',
    titleImage: 'img/FriendsPlus/Page18/E1/title.jpg',

    // titleQuestion: [{ num: '1', title: 'Complete the table with the given words.', color: "#095BA3", star: 1 }],
    questionImage: [
      'img/FriendsPlus/Page18/E1/1.png',
      'img/FriendsPlus/Page18/E1/2.png',
    ],
    questions: [
      {
        text: "get up",
        group: 'In the morning',
      },
      {
        text: "do my homework",
        group: 'In the evening',
      },
      {
        text: "go to bed",
        group: 'In the evening',
      },
      {
        text: "go to school",
        group: 'In the morning',
      },
      {
        text: "have breakfast",
        group: 'In the morning',
      },
      {
        text: "have classes",
        group: 'In the morning',
      },
      {
        text: "have dinner",
        group: 'In the evening',
      },
      {
        text: "relax on the sofa",
        group: 'In the evening',
      },
      {
        text: "sleep",
        group: 'In the evening',
      },
    ]
  },
  2: { // Exercise num
     unit : 'Unit 2',
    id : 'WB6-U2-P18-E2',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page18/E2/Key/answerKey.png',
    titleImage: '',
    titleQuestion: [{ num: '2', title: 'Look at the pictures and complete the sentences with the given words.', color: "#095BA3", star: 2 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page18/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page18/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page18/E2/3.jpg', input: true, answer: "go to school" },
        { url: 'img/FriendsPlus/Page18/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page18/E2/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page18/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page18/E2/7.jpg', input: true, answer: "have classes" },
        { url: 'img/FriendsPlus/Page18/E2/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page18/E2/9.jpg' },
        { url: 'img/FriendsPlus/Page18/E2/10.jpg', input: true, answer: "chat with friends" },
        { url: 'img/FriendsPlus/Page18/E2/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page18/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page18/E2/13.jpg', input: true, answer: "get home" },
        { url: 'img/FriendsPlus/Page18/E2/14.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page18/E2/15.jpg' },
        { url: 'img/FriendsPlus/Page18/E2/16.jpg', input: true, answer: "do my homework" },
        { url: 'img/FriendsPlus/Page18/E2/17.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page18/E2/18.jpg' },
        { url: 'img/FriendsPlus/Page18/E2/19.jpg', input: true, answer: "help with the housework" },
        { url: 'img/FriendsPlus/Page18/E2/20.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  3: { // Exercise num
     unit : 'Unit 2',
    id : 'WB6-U2-P18-E3',
    audio: '',
    video: '',
    component: MC2,
    exerciseKey: 'img/FriendsPlus/Page18/E3/Key/answerKey.png',
    // titleQuestion: [{ num: '3', title: 'Choose the correct answers.', color: '#115D98', star: 2 }],
    titleImage: 'img/FriendsPlus/Page18/E3/title.jpg',
    marginLeft: 60,
    widthButton: '',
    questionImage: [ // Row
      [
        // Column
        { url: 'http://' },
        { url: 'http://', input: true },
        { url: 'http://' },
      ],
    ],
    questions: [
      {
        title: 'I always ....... my face in the morning.',
        answers: [
          { image: "", isCorrect: false, right: "", text: "watch" },
          { image: "", isCorrect: true, right: "", text: "wash" },
          { image: "", isCorrect: false, right: "", text: "brush" },
          { image: "", isCorrect: false, right: "", text: "help" },
        ],
        no: 1,
        question: '',
        type: '',
      },
      {
        title: 'We ....... lunch at school at 1 p.m.',
        answers: [
          { image: "", isCorrect: false, right: "", text: "help" },
          { image: "", isCorrect: false, right: "", text: "go" },
          { image: "", isCorrect: false, right: "", text: "relax" },
          { image: "", isCorrect: true, right: "", text: "have" },
        ],
        no: 2,
        question: '',
        type: '',
      },
      {
        title: 'After school, I ....... in the library.',
        answers: [
          { image: "", isCorrect: true, right: "", text: "study" },
          { image: "", isCorrect: false, right: "", text: "brush" },
          { image: "", isCorrect: false, right: "", text: "do" },
          { image: "", isCorrect: false, right: "", text: "get up" },
        ],
        no: 3,
        question: '',
        type: '',
      },
      {
        title: 'I ....... video games with my friends.',
        answers: [
          { image: "", isCorrect: false, right: "", text: "get" },
          { image: "", isCorrect: false, right: "", text: "wash" },
          { image: "", isCorrect: true, right: "", text: "play" },
          { image: "", isCorrect: false, right: "", text: "go" },
        ],
        no: 4,
        question: '',
        type: '',
      },
      {
        title: `We often sit on the sofa and ........ videos.`,
        answers: [
          { image: "", isCorrect: false, right: "", text: "relax" },
          { image: "", isCorrect: false, right: "", text: "help" },
          { image: "", isCorrect: false, right: "", text: "wash" },
          { image: "", isCorrect: true, right: "", text: "watch" },
        ],
        no: 5,
        question: '',
        type: '',
      },
      {
        title: `I ........ my teeth before bed.`,
        answers: [
          { image: "", isCorrect: false, right: "", text: "wash" },
          { image: "", isCorrect: true, right: "", text: "brush" },
          { image: "", isCorrect: false, right: "", text: "have" },
          { image: "", isCorrect: false, right: "", text: "go" },
        ],
        no: 6,
        question: '',
        type: '',
      },
    ]
  },
  4: { // Exercise num
     unit : 'Unit 2',
    id : 'WB6-U2-P18-E4',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page18/E4/Key/answerKey.png',
    titleImage: '',
    titleQuestion: [{ num: '4', title: 'Complete the text with the given words. There are five extra words.', color: "#115D98", star: 3 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page18/E4/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page18/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page18/E4/3.jpg', input: true, answer: "teeth" },
        { url: 'img/FriendsPlus/Page18/E4/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page18/E4/5.jpg' },
        { url: 'img/FriendsPlus/Page18/E4/6.jpg', input: true, answer: "face" },
        { url: 'img/FriendsPlus/Page18/E4/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page18/E4/8.jpg' },
        { url: 'img/FriendsPlus/Page18/E4/9.jpg', input: true, answer: "breakfast" },
        { url: 'img/FriendsPlus/Page18/E4/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page18/E4/11.jpg' },
        { url: 'img/FriendsPlus/Page18/E4/12.jpg', input: true, answer: "sister" },
        { url: 'img/FriendsPlus/Page18/E4/13.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page18/E4/14.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page18/E4/15.jpg' },
        { url: 'img/FriendsPlus/Page18/E4/16.jpg', input: true, answer: "housework" },
        { url: 'img/FriendsPlus/Page18/E4/17.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page18/E4/18.jpg' },
        { url: 'img/FriendsPlus/Page18/E4/19.jpg', input: true, answer: "bed" },
        { url: 'img/FriendsPlus/Page18/E4/20.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page18/E4/21.jpg' },
        { url: 'img/FriendsPlus/Page18/E4/22.jpg', input: true, answer: "minutes" },
        { url: 'img/FriendsPlus/Page18/E4/23.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page18/E4/24.jpg' },
        { url: 'img/FriendsPlus/Page18/E4/25.jpg', input: true, answer: "foot" },
        { url: 'img/FriendsPlus/Page18/E4/26.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page18/E4/27.jpg' },
        { url: 'img/FriendsPlus/Page18/E4/28.jpg', input: true, answer: "next" },
        { url: 'img/FriendsPlus/Page18/E4/29.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page18/E4/30.jpg' },
        { url: 'img/FriendsPlus/Page18/E4/31.jpg', input: true, answer: "home" },
        { url: 'img/FriendsPlus/Page18/E4/32.jpg' },
      ],
      [
        // Column13
        { url: 'img/FriendsPlus/Page18/E4/33.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
}

export default json;