
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';

const json = {
    1: { // Exercise num
        unit : 'Unit 3',
        id : 'WB6-U3-P29-E1',
        audio: '',
        video: '',
        component: T1,
        exerciseKey: 'img/FriendsPlus/Page29/E1/Key/answerKey.png',
        titleImage: '',
        titleQuestion: [{ num: '1', title: 'Rewrite the sentences with the phrases in brackets.', color: "#00A850", star: 1 }],
        questionImage: [],
        questions: [
            {
                title: "<p>In the national park, there are exciting birds. There are ostriches and eagles. (for example)</p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>In the national park, there are exciting birds. For example, there are ostriches and eagles.</u></p>" +
                    "<p><span style='font-weight:600'>1</span>. Are you interested in small creatures rabbits? (such as)# </p>" +
                    "<p><span style='font-weight:600'>2</span>. You can help animals in different ways. You can adopt a gorilla. (for example)# </p>" +
                    "<p><span style='font-weight:600'>3</span>. I love TV programmes about big animals camels or giraffes. (like)# </p>" +
                    "<p><span style='font-weight:600'>4</span>. At this zoo, you can see different bears black bears and pandas. (such as)# </p>" +
                    "<p><span style='font-weight:600'>5</span>. They often visit hot places Australia and India. (like)# </p>",

                answer: [
                    "Are you interested in small creatures such as rabbits?",
                    "You can help animals in different ways. For example, you can adopt a gorilla.",
                    "I love TV programmes about big animals like camels or giraffes.",
                    "At this zoo, you can see different bears such as black bears and pandas.",
                    "They often visit hot places like Australia and India.",
                ],
                type: 'longAnwser'
            },
        ]
    },
    2: { // Exercise num
        unit : 'Unit 3',
        id : 'WB6-U3-P29-E2',
        audio: '',
        video: '',
        component: T1,
        exerciseKey: 'img/FriendsPlus/Page29/E2/Key/answerKey.png',
        titleImage: '',
        titleQuestion: [{ num: '2', title: 'Write the sentences. Use the phrases in brackets.', color: "#00A850", star: 2 }],
        questionImage: [],
        questions: [
            {
                title: "<p>Jack / like / sports / basketball and football (such as)</p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>Jack likes sports such as basketball and football.</u></p>" +
                    "<p><span style='font-weight:600'>1</span>. she / love / exciting books / Harry Potter (like)# </p>" +
                    "<p><span style='font-weight:600'>2</span>. Mary / have got / interests / photography and music (such as)# </p>" +
                    "<p><span style='font-weight:600'>3</span>. some modern cities / be / very big / London and New York (for example)# </p>" +
                    "<p><span style='font-weight:600'>4</span>. my uncle / go / to different countries / Turkey and Italy (such as)# </p>" +
                    "<p><span style='font-weight:600'>5</span>. some animals / be / in danger / elephants and gorillas (for example)# </p>",


                answer: [
                    "She loves exciting books like Harry Potter.",
                    "Mary has got interests such as photography and music.",
                    "Some modern cities are very big. For example, London and New York.",
                    "My uncle goes to different countries such as Turkey and Italy.",
                    "Some animals are in danger. For example, elephants and gorillas.",
                ],
                type: 'longAnwser'
            },
        ]
    },
    3: { // Exercise num
        unit : 'Unit 3',
        id : 'WB6-U3-P29-E3',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page29/E3/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page29/E3/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page29/E3/2.jpg' },
                { url: 'img/FriendsPlus/Page29/E3/3.jpg', input: true, answer: "South Africa" },
                { url: 'img/FriendsPlus/Page29/E3/4.jpg' },
                { url: 'img/FriendsPlus/Page29/E3/5.jpg', input: true, answer: "Tanzania" },
                { url: 'img/FriendsPlus/Page29/E3/6.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page29/E3/7.jpg' },
                { url: 'img/FriendsPlus/Page29/E3/8.jpg', input: true, answer: "one metre" },
                { url: 'img/FriendsPlus/Page29/E3/9.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page29/E3/10.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page29/E3/11.jpg' },
                { url: 'img/FriendsPlus/Page29/E3/12.jpg', input: true, answer: "tallest" },
                { url: 'img/FriendsPlus/Page29/E3/13.jpg' },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page29/E3/14.jpg' },
                { url: 'img/FriendsPlus/Page29/E3/15.jpg', input: true, answer: "eighteen kilos" },
                { url: 'img/FriendsPlus/Page29/E3/16.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page29/E3/17.jpg' },
            ],
            [
                // Column8
                { url: 'img/FriendsPlus/Page29/E3/18.jpg' },
                { url: 'img/FriendsPlus/Page29/E3/19.jpg', input: true, answer: "eighty kilometres an hour" },
                { url: 'img/FriendsPlus/Page29/E3/20.jpg' },
            ],
            [
                // Column9
                { url: 'img/FriendsPlus/Page29/E3/21.jpg' },
            ],
            [
                // Column10
                { url: 'img/FriendsPlus/Page29/E3/22.jpg' },
                { url: 'img/FriendsPlus/Page29/E3/23.jpg', input: true, answer: "frogs/snakes" },
                { url: 'img/FriendsPlus/Page29/E3/24.jpg' },
            ],
            [
                // Column11
                { url: 'img/FriendsPlus/Page29/E3/25.jpg' },
                { url: 'img/FriendsPlus/Page29/E3/26.jpg', input: true, answer: "snakes/frogs " },
                { url: 'img/FriendsPlus/Page29/E3/27.jpg' },
                { url: 'img/FriendsPlus/Page29/E3/28.jpg', input: true, answer: "can jump" },
                { url: 'img/FriendsPlus/Page29/E3/29.jpg' },
            ],
            [
                // Column12
                { url: 'img/FriendsPlus/Page29/E3/30.jpg' },
                { url: 'img/FriendsPlus/Page29/E3/31.jpg', input: true, answer: "kill" },
                { url: 'img/FriendsPlus/Page29/E3/32.jpg' },
            ],
            [
                // Column13
                { url: 'img/FriendsPlus/Page29/E3/33.jpg' },
            ],
            [
                // Column14
                { url: 'img/FriendsPlus/Page29/E3/34.jpg' },
                { url: 'img/FriendsPlus/Page29/E3/35.jpg', input: true, answer: "smaller numbers " },
                { url: 'img/FriendsPlus/Page29/E3/36.jpg' },
            ],
            [
                // Column15
                { url: 'img/FriendsPlus/Page29/E3/37.jpg' },
            ],
            [
                // Column16
                { url: 'img/FriendsPlus/Page29/E3/38.jpg' },
                { url: 'img/FriendsPlus/Page29/E3/39.jpg', input: true, answer: "hunting" },
                { url: 'img/FriendsPlus/Page29/E3/40.jpg' },
            ],
            [
                // Column17
                { url: 'img/FriendsPlus/Page29/E3/41.jpg' },
            ],

        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    4: { // Exercise num
        unit : 'Unit 3',
        id : 'WB6-U3-P29-E4',
        audio: '',
        video: '',
        component: T2,
        exerciseKey: '',
        titleImage: '',
        titleQuestion: [{ num: '4', title: 'Write a factfile about an interesting wild animal in Việt Nam. Use the text in exercise 3 to help you.', color: "#00A851", star: 3 }],
        questionImage: [],
        questions: [
            {
                title:
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>",
                answer: [],
                type: 'longAnwser'
            },
        ]
    },



}

export default json;