import React from 'react';
import uniqBy from 'lodash.uniqby';
import { CardFooter, Row } from 'reactstrap';

import DragItem from './DragItem';
import DropZone from './DropZone';
import TitleQuestion from '../../../TitleQuestion';
import FooterIeltsMindset from '../../../FooterModal';
import styles from './styles.module.css';
import moment from 'moment';
import 'moment/locale/vi';

moment.locale('vi');

const DD2 = (props) => {
  const [state, setState] = React.useState({
    submitted: false,
    booleanArray: [],
    studentAnswer: null,
    words: JSON.parse(JSON.stringify(props.questions)),
  });
  React.useEffect(() => {
    const stateModel = {};

    props.questions.forEach((q) => {
      stateModel[q.group] = [];
    });

    setState((prevState) => ({
      ...prevState,
      ...stateModel,
    }));
  }, [props.questions]);

  const renderItem = React.useCallback((listName) => (item, index) => {
    const customStyles = {};

    if (state.submitted) {
      const isCorrect = item.group === listName;
      customStyles.color = isCorrect ? 'green' : 'red';
    }

    return (
      <DragItem
        key={index}
        style={customStyles}
        className={styles.item}
        disable={state.submitted}
        payload={{ text: item, listName }}
      >
        {item.text}
      </DragItem>
    );
  }, [state.submitted]);

  const onDrop = React.useCallback((listName) => (item, monitor) => {
    if (listName === item.payload.listName) return;

    const fromListName = item.payload.listName;
    const toListName = listName;

    setState((prevState) => {
      const stateModel = {
        [fromListName]: prevState[fromListName],
        [toListName]: prevState[toListName],
      };

      const itemIndex = stateModel[fromListName].findIndex((x) => x === item.payload.text);

      const [removedItem] = stateModel[fromListName].splice(itemIndex, 1);

      stateModel[toListName].unshift(removedItem);

      return ({
        ...prevState,
        ...stateModel,
      });
    });
  }, []);

  const onSubmit = React.useCallback(() => {
    const list_group = props.questions.map(x => x.group);
    const Group = [...new Set(list_group)];
    ///
    let booleanArray = [];
    let studentAnswer = [];
    for (const Group_x of Group) {
      state[Group_x].forEach(item => {
        const isCorrect = item.group === Group_x;
        booleanArray.push(isCorrect);
        studentAnswer.push({ text: item.text, group: Group_x });
      });
    };

    let correctAnswer = 0;

    booleanArray.forEach((item) => {
      if (item) {
        correctAnswer++
      }
    })

    const percent = parseInt(correctAnswer / booleanArray.length * 100)
    // const resultString = `${correctAnswer}/${booleanArray.length} (${percent}%)`
    const result_String = `${correctAnswer}/${booleanArray.length}`

    const params = {
      score: percent,
      unit: props.unit,
      results: result_String,
      exerciseId: props.id,
      sreatedDate: moment().format(),
      studentAnswer: JSON.stringify(studentAnswer),
    }
    props.postAnswerToApi(params)

    setState((prevState) => ({ ...prevState, submitted: true, booleanArray, studentAnswer }));
  }, [props, state]);

  const onRetry = React.useCallback(() => {
    const stateModel = {};

    props.questions.forEach((q) => {
      stateModel[q.group] = [];
    });

    setState({
      ...stateModel,
      submitted: false,
      words: JSON.parse(JSON.stringify(props.questions)),
    });
  }, [props.questions]);

  const onPlayVideo = React.useCallback(() => {

  }, []);

  const renderDropZone = React.useCallback((item, index) => {
    const listName = item.group;

    let classes = `${styles.container} ${styles.columnContainer} ${styles.centerColumn} flex-column`;

    if (index === 0) {
      classes += ` ${styles.borderLeft}`;
    }

    return (
      <DropZone
        key={index}
        className={classes}
        onDrop={onDrop(listName)}
      >
        <div className={styles.columnHeader}>
          {!props.questionImage[index] && listName}
          {!!props.questionImage[index] && <img alt="" src={props.questionImage[index]} className={styles.headerImage} />}
        </div>
        {state[listName]?.map(renderItem(listName))}
      </DropZone>
    )
  }, [onDrop, props.questionImage, renderItem, state]);

  const isDisabledSubmit = state.words.length > 0 || state.submitted;

  return (
    <div className={`${styles.container} flex-column`} style={{ alignItems: 'center' }}>
      <div className={`${styles.container} flex-column`} style={{ width: '40vw' }}>
        <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: 200 }}>
          <TitleQuestion color={props.color} titleQuestion={props.titleQuestion} />
          <img alt="" src={props.titleImage} style={{ width: '100%' }} />
        </Row>
        {!state.submitted && (
          <DropZone onDrop={onDrop('words')} className={styles.wordContainer} style={{ fontSize: 25 }}>
            {state.words.map(renderItem('words'))}
          </DropZone>
        )}
        <div className={`${styles.container} ${styles.table}`} style={{ fontSize: 25 }}>
          {uniqBy(props.questions, 'group').map(renderDropZone)}
        </div>
        <CardFooter>
          <FooterIeltsMindset
            isDisabledSubmit={isDisabledSubmit}
            isDisabledRetry={!state.submitted}
            exerciseKey={props.exerciseKey}
            result={state?.booleanArray}
            onSubmit={onSubmit}
            onRetry={onRetry}
            onPlayVideo={onPlayVideo}
          />
        </CardFooter>
      </div>
    </div>
  );
}

export default DD2;
