import T5 from '../../components/ExcerciseTypes/TypeIn/T5';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {

    1: { // Exercise num
        unit : 'Unit 8',
        id : 'WB6-U8-P57-E1',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page57/E1/Key/Key.png',
        // titleImage: 'http://',
        // titleQuestion: [{ num: '1', title: 'Complete the table with the given words.', color: "#19bdd6", star: 1 }],
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page57/E1/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E1/2.jpg' },
                { url: 'img/FriendsPlus/Page57/E1/3.jpg', input: true, answer: "leave" },
                { url: 'img/FriendsPlus/Page57/E1/4.jpg' },
                { url: 'img/FriendsPlus/Page57/E1/5.jpg', input: true, answer: "won't" },
                { url: 'img/FriendsPlus/Page57/E1/6.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E1/7.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E1/8.jpg' },
                { url: 'img/FriendsPlus/Page57/E1/9.jpg', input: true, answer: "don't" },
                { url: 'img/FriendsPlus/Page57/E1/10.jpg' },
                { url: 'img/FriendsPlus/Page57/E1/11.jpg', input: true, answer: "will" },
                { url: 'img/FriendsPlus/Page57/E1/12.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E1/13.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E1/14.jpg' },
                { url: 'img/FriendsPlus/Page57/E1/15.jpg', input: true, answer: "Will" },
                { url: 'img/FriendsPlus/Page57/E1/16.jpg' },
                { url: 'img/FriendsPlus/Page57/E1/17.jpg', input: true, answer: "finish" },
                { url: 'img/FriendsPlus/Page57/E1/18.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E1/19.jpg' },
            ],
        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },

    2: { // Exercise num
        unit : 'Unit 8',
        id : 'WB6-U8-P57-E2',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page57/E2/Key/Key.png',
        // titleImage: 'http://',
        titleQuestion: [{ num: '2', title: 'Complete the sentences with the correct form of the words.', color: "#19bdd6", star: 2 }],
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page57/E2/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E2/2.jpg' },
                { url: 'img/FriendsPlus/Page57/E2/3.jpg', input: true, answer: "won't hear" },
                { url: 'img/FriendsPlus/Page57/E2/4.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E2/5.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E2/6.jpg' },
                { url: 'img/FriendsPlus/Page57/E2/7.jpg', input: true, answer: "finds" },
                { url: 'img/FriendsPlus/Page57/E2/8.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E2/9.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E2/10.jpg' },
                { url: 'img/FriendsPlus/Page57/E2/11.jpg', input: true, answer: "gets up" },
                { url: 'img/FriendsPlus/Page57/E2/12.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E2/13.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E2/14.jpg' },
                { url: 'img/FriendsPlus/Page57/E2/15.jpg', input: true, answer: "sees" },
                { url: 'img/FriendsPlus/Page57/E2/16.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E2/17.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E2/18.jpg' },
                { url: 'img/FriendsPlus/Page57/E2/19.jpg', input: true, answer: "won't go" },
                { url: 'img/FriendsPlus/Page57/E2/20.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E2/21.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E2/22.jpg' },
                { url: 'img/FriendsPlus/Page57/E2/23.jpg', input: true, answer: "will they sit" },
                { url: 'img/FriendsPlus/Page57/E2/24.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E2/25.jpg' },
            ],

        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },

    3: { // Exercise num
        unit : 'Unit 8',
        id : 'WB6-U8-P57-E3',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page57/E3/Key/Key.png',
        // titleImage: 'http://',
        titleQuestion: [{ num: '3', title: 'Order the words to make sentences. Insert a comma where necessary.', color: "#19bdd6", star: 2 }],
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page57/E3/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E3/2.jpg' },
                { url: 'img/FriendsPlus/Page57/E3/3.jpg', input: true, answer: "it's hot, I'll wear a T-shirt." },
                { url: 'img/FriendsPlus/Page57/E3/4.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E3/5.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E3/6.jpg' },
                { url: 'img/FriendsPlus/Page57/E3/7.jpg', input: true, answer: "catch the train if we run." },
                { url: 'img/FriendsPlus/Page57/E3/8.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E3/9.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E3/10.jpg' },
                { url: 'img/FriendsPlus/Page57/E3/11.jpg', input: true, answer: "won't play football if it rains." },
                { url: 'img/FriendsPlus/Page57/E3/12.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E3/13.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E3/14.jpg' },
                { url: 'img/FriendsPlus/Page57/E3/15.jpg', input: true, answer: "you don't eat, you'll feel hungry." },
                { url: 'img/FriendsPlus/Page57/E3/16.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E3/17.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E3/18.jpg' },
                { url: 'img/FriendsPlus/Page57/E3/19.jpg', input: true, answer: "will they do if it's very cold?" },
                { url: 'img/FriendsPlus/Page57/E3/20.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E3/21.jpg' },
            ],
        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },

    4: { // Exercise num
        unit : 'Unit 8',
        id : 'WB6-U8-P57-E4',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page57/E4/Key/Key.png',
        // titleImage: 'http://',
        titleQuestion: [{ num: '4', title: 'Complete the email with the correct form of the verbs.', color: "#19bdd6", star: 2 }],
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page57/E4/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E4/2.jpg' },
                { url: 'img/FriendsPlus/Page57/E4/3.jpg', input: true, answer: "'ll bake" },
                { url: 'img/FriendsPlus/Page57/E4/4.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E4/5.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E4/6.jpg' },
                { url: 'img/FriendsPlus/Page57/E4/7.jpg', input: true, answer: "will we do" },
                { url: 'img/FriendsPlus/Page57/E4/8.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E4/9.jpg' },
                { url: 'img/FriendsPlus/Page57/E4/10.jpg', input: true, answer: "rains" },
                { url: 'img/FriendsPlus/Page57/E4/11.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E4/12.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E4/13.jpg' },
                { url: 'img/FriendsPlus/Page57/E4/14.jpg', input: true, answer: "ask" },
                { url: 'img/FriendsPlus/Page57/E4/15.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E4/16.jpg' },
                { url: 'img/FriendsPlus/Page57/E4/17.jpg', input: true, answer: "'ll say" },
                { url: 'img/FriendsPlus/Page57/E4/18.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E4/19.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E4/20.jpg' },
                { url: 'img/FriendsPlus/Page57/E4/21.jpg', input: true, answer: "doesn't feel" },
                { url: 'img/FriendsPlus/Page57/E4/22.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E4/23.jpg' },
                { url: 'img/FriendsPlus/Page57/E4/24.jpg', input: true, answer: "won't come" },
                { url: 'img/FriendsPlus/Page57/E4/25.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page57/E4/26.jpg' },
            ],

        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },

    5: { // Exercise num
        unit : 'Unit 8',
        id : 'WB6-U8-P57-E5',
        audio: '',
        video: '',
        component: T5,
        inputSize: 500,
        exerciseKey: '',
        // titleImage: 'img/FriendsPlus/Page56/E6/1.jpg',
        titleQuestion: [{ num: '5', title: 'Complete the sentences with your own ideas. Use the first conditional.', color: '#19bdd6', star: 3 }],
        questionImage: [],
        questions: [
            {
                title:
                    "<p >If we don’t get up early, <span style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px'>we won’t arrive at the airport on time.</span></p>" +
                    "<p>1.   If I don’t tidy my room,  #</p>" +
                    "<p>2.  Our teacher won’t be happy if  #</p>" +
                    "<p>3.  If I work hard at school,  #</p>" +
                    "<p>4.  We won’t go to the park if  #</p>" +
                    "<p>5.  If I see my favourite singer on TV,  #</p>",
                answer: [],
                type: 'longAnwser'
            },
        ]
    },
}
export default json;