
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';


const json = {
  1: { // Exercise num
    unit: 'Unit 2',
    id: 'WB6-U2-P22-E1',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 2,
    exerciseKey: 'img/FriendsPlus/Page22/E1/Key/answerKey.png',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page22/E1/01.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page22/E1/02.jpg' },
        { url: 'img/FriendsPlus/Page22/E1/03.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page22/E1/04.jpg' },
        { url: 'img/FriendsPlus/Page22/E1/05.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page22/E1/06.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page22/E1/07.jpg' },
        { url: 'img/FriendsPlus/Page22/E1/08.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page22/E1/09.jpg' },
        { url: 'img/FriendsPlus/Page22/E1/10.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page22/E1/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page22/E1/12.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 2',
    id: 'WB6-U2-P22-E2',
    audio: '',
    video: '',
    component: D1,
    fontSize: 25,
    exerciseKey: 'img/FriendsPlus/Page22/E2/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page22/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page22/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page22/E2/3.jpg', input: true, answer: "True" },
        { url: 'img/FriendsPlus/Page22/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page22/E2/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page22/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page22/E2/7.jpg', input: true, answer: "False" },
        { url: 'img/FriendsPlus/Page22/E2/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page22/E2/9.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page22/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page22/E2/11.jpg', input: true, answer: "True" },
        { url: 'img/FriendsPlus/Page22/E2/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page22/E2/13.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page22/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page22/E2/15.jpg', input: true, answer: "False" },
        { url: 'img/FriendsPlus/Page22/E2/16.jpg' },
      ],



    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 2',
    id: 'WB6-U2-P22-E3',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page22/E3/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page22/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page22/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page22/E3/3.jpg', input: true, answer: "closed" },
        { url: 'img/FriendsPlus/Page22/E3/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page22/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page22/E3/6.jpg', input: true, answer: "dangerous" },
        { url: 'img/FriendsPlus/Page22/E3/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page22/E3/8.jpg' },
        { url: 'img/FriendsPlus/Page22/E3/9.jpg', input: true, answer: "clothes" },
        { url: 'img/FriendsPlus/Page22/E3/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page22/E3/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page22/E3/12.jpg' },
        { url: 'img/FriendsPlus/Page22/E3/13.jpg', input: true, answer: "early" },
        { url: 'img/FriendsPlus/Page22/E3/14.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page22/E3/15.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page22/E3/16.jpg' },
        { url: 'img/FriendsPlus/Page22/E3/17.jpg', input: true, answer: "chat" },
        { url: 'img/FriendsPlus/Page22/E3/18.jpg' },
      ],




    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 2',
    id: 'WB6-U2-P22-E4',
    audio: '',
    video: '',
    component: T1,
    exerciseKey: 'img/FriendsPlus/Page22/E4/Key/answerKey.png',
    titleImage: '',
    titleQuestion: [{ num: '4', title: 'Answer the questions. Write complete sentences.', color: "#DB2D36", star: 3 }],
    questionImage: [],
    questions: [
      {
        title: "<p>Where is Oymyakon?</p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>Oymyakon is in north-east Siberia, Russia.</u></p>" +
          "<p><span style='font-weight:600'>1</span>. Why is the journey to school dangerous? # </p>" +
          "<p><span style='font-weight:600'>2</span>. How long is the day in winter? # </p>" +
          "<p><span style='font-weight:600'>3</span>. What do the children do when school finishes? # </p>" +
          "<p><span style='font-weight:600'>4</span>. How long is your journey to school? # </p>" +
          "<p><span style='font-weight:600'>5</span>. Would you like the journey to school in Oymyakon? Why / Why not? # </p>",
        answer: [
          "It is dangerous because it is dark and very cold.",
          "The day in winter is three hours long.",
          "When school finishes, the children put on their warm clothes and walk home.",
          "",
          "",
        ],
        type: 'longAnwser'
      },
    ]
  },
  5: { // Exercise num
    unit: 'Unit 2',
    id: 'WB6-U2-P22-E5',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page22/E5/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page22/E5/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page22/E5/2.jpg' },
        { url: 'img/FriendsPlus/Page22/E5/3.jpg', input: true, answer: "father" },
        { url: 'img/FriendsPlus/Page22/E5/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page22/E5/5.jpg' },
        { url: 'img/FriendsPlus/Page22/E5/6.jpg', input: true, answer: "aunt" },
        { url: 'img/FriendsPlus/Page22/E5/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page22/E5/8.jpg' },
        { url: 'img/FriendsPlus/Page22/E5/9.jpg', input: true, answer: "uncle" },
        { url: 'img/FriendsPlus/Page22/E5/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page22/E5/11.jpg' },
        { url: 'img/FriendsPlus/Page22/E5/12.jpg', input: true, answer: "grandson" },
        { url: 'img/FriendsPlus/Page22/E5/13.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page22/E5/14.jpg' },
        { url: 'img/FriendsPlus/Page22/E5/15.jpg', input: true, answer: "granddaughter" },
        { url: 'img/FriendsPlus/Page22/E5/16.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page22/E5/17.jpg' },
        { url: 'img/FriendsPlus/Page22/E5/18.jpg', input: true, answer: "brother" },
        { url: 'img/FriendsPlus/Page22/E5/19.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page22/E5/20.jpg' },
        { url: 'img/FriendsPlus/Page22/E5/21.jpg', input: true, answer: "sister" },
        { url: 'img/FriendsPlus/Page22/E5/22.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page22/E5/23.jpg' },
        { url: 'img/FriendsPlus/Page22/E5/24.jpg', input: true, answer: "daughter" },
        { url: 'img/FriendsPlus/Page22/E5/25.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page22/E5/26.jpg' },
        { url: 'img/FriendsPlus/Page22/E5/27.jpg', input: true, answer: "son" },
        { url: 'img/FriendsPlus/Page22/E5/28.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page22/E5/29.jpg' },
        { url: 'img/FriendsPlus/Page22/E5/30.jpg', input: true, answer: "grandmother" },
        { url: 'img/FriendsPlus/Page22/E5/31.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page22/E5/32.jpg' },
        { url: 'img/FriendsPlus/Page22/E5/33.jpg', input: true, answer: "grandfather" },
        { url: 'img/FriendsPlus/Page22/E5/34.jpg' },
      ],



    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },


}
export default json