import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
    1: { // Exercise num
        unit: 'Language Focus Practice Unit 1',
        id: 'WB6-LFP1-P66-E1',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page66/E1/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page66/E1/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page66/E1/2.jpg' },
                { url: 'img/FriendsPlus/Page66/E1/3.jpg', input: true, answer: "isn't" },
                { url: 'img/FriendsPlus/Page66/E1/4.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page66/E1/5.jpg' },
                { url: 'img/FriendsPlus/Page66/E1/6.jpg', input: true, answer: "are" },
                { url: 'img/FriendsPlus/Page66/E1/7.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page66/E1/8.jpg' },
                { url: 'img/FriendsPlus/Page66/E1/9.jpg', input: true, answer: "is" },
                { url: 'img/FriendsPlus/Page66/E1/10.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page66/E1/11.jpg' },
                { url: 'img/FriendsPlus/Page66/E1/12.jpg', input: true, answer: "aren't" },
                { url: 'img/FriendsPlus/Page66/E1/13.jpg' },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page66/E1/14.jpg' },
                { url: 'img/FriendsPlus/Page66/E1/15.jpg', input: true, answer: "isn't" },
                { url: 'img/FriendsPlus/Page66/E1/16.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page66/E1/17.jpg' },
                { url: 'img/FriendsPlus/Page66/E1/18.jpg', input: true, answer: "are" },
                { url: 'img/FriendsPlus/Page66/E1/19.jpg' },
            ],



        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },

    2: { // Exercise num
        unit: 'Language Focus Practice Unit 1',
        id: 'WB6-LFP1-P66-E2',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page66/E2/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page66/E2/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page66/E2/2.jpg' },
                { url: 'img/FriendsPlus/Page66/E2/3.jpg', input: true, answer: "any " },
                { url: 'img/FriendsPlus/Page66/E2/4.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page66/E2/5.jpg' },
                { url: 'img/FriendsPlus/Page66/E2/6.jpg', input: true, answer: "any " },
                { url: 'img/FriendsPlus/Page66/E2/7.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page66/E2/8.jpg' },
                { url: 'img/FriendsPlus/Page66/E2/9.jpg', input: true, answer: "a " },
                { url: 'img/FriendsPlus/Page66/E2/10.jpg' },
                { url: 'img/FriendsPlus/Page66/E2/11.jpg', input: true, answer: "a " },
                { url: 'img/FriendsPlus/Page66/E2/12.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page66/E2/13.jpg' },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page66/E2/14.jpg' },
                { url: 'img/FriendsPlus/Page66/E2/15.jpg', input: true, answer: "some" },
                { url: 'img/FriendsPlus/Page66/E2/16.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page66/E2/17.jpg' },
                { url: 'img/FriendsPlus/Page66/E2/18.jpg', input: true, answer: "a" },
                { url: 'img/FriendsPlus/Page66/E2/19.jpg' },
                { url: 'img/FriendsPlus/Page66/E2/20.jpg', input: true, answer: "a" },
                { url: 'img/FriendsPlus/Page66/E2/21.jpg' },
            ],
            [
                // Column8
                { url: 'img/FriendsPlus/Page66/E2/22.jpg' },
                { url: 'img/FriendsPlus/Page66/E2/23.jpg', input: true, answer: "the" },
                { url: 'img/FriendsPlus/Page66/E2/24.jpg' },
                { url: 'img/FriendsPlus/Page66/E2/25.jpg', input: true, answer: "an" },
                { url: 'img/FriendsPlus/Page66/E2/26.jpg' },
            ],
            [
                // Column9
                { url: 'img/FriendsPlus/Page66/E2/27.jpg' },
                { url: 'img/FriendsPlus/Page66/E2/28.jpg', input: true, answer: "-" },
                { url: 'img/FriendsPlus/Page66/E2/29.jpg' },
            ],
            [
                // Column10
                { url: 'img/FriendsPlus/Page66/E2/30.jpg' },
                { url: 'img/FriendsPlus/Page66/E2/31.jpg', input: true, answer: "some" },
                { url: 'img/FriendsPlus/Page66/E2/32.jpg' },
                { url: 'img/FriendsPlus/Page66/E2/33.jpg', input: true, answer: "an" },
                { url: 'img/FriendsPlus/Page66/E2/34.jpg' },
            ],
            [
                // Column11
                { url: 'img/FriendsPlus/Page66/E2/35.jpg' },
                { url: 'img/FriendsPlus/Page66/E2/36.jpg', input: true, answer: "a" },
                { url: 'img/FriendsPlus/Page66/E2/37.jpg' },
                { url: 'img/FriendsPlus/Page66/E2/38.jpg', input: true, answer: "the" },
                { url: 'img/FriendsPlus/Page66/E2/39.jpg' },
            ],
            [
                // Column12
                { url: 'img/FriendsPlus/Page66/E2/40.jpg' },
            ],


        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    3: { // Exercise num
        unit: 'Language Focus Practice Unit 1',
        id: 'WB6-LFP1-P66-E3',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page66/E3/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page66/E3/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page66/E3/2.jpg' },
                { url: 'img/FriendsPlus/Page66/E3/3.jpg', input: true, answer: "How" },
                { url: 'img/FriendsPlus/Page66/E3/4.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page66/E3/5.jpg' },
                { url: 'img/FriendsPlus/Page66/E3/6.jpg', input: true, answer: "two " },
                { url: 'img/FriendsPlus/Page66/E3/7.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page66/E3/8.jpg' },
                { url: 'img/FriendsPlus/Page66/E3/9.jpg', input: true, answer: "Is" },
                { url: 'img/FriendsPlus/Page66/E3/10.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page66/E3/11.jpg' },
                { url: 'img/FriendsPlus/Page66/E3/12.jpg', input: true, answer: "is" },
                { url: 'img/FriendsPlus/Page66/E3/13.jpg' },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page66/E3/14.jpg' },
                { url: 'img/FriendsPlus/Page66/E3/15.jpg', input: true, answer: "there" },
                { url: 'img/FriendsPlus/Page66/E3/16.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page66/E3/17.jpg' },
                { url: 'img/FriendsPlus/Page66/E3/18.jpg', input: true, answer: "there" },
                { url: 'img/FriendsPlus/Page66/E3/19.jpg' },
            ],
            [
                // Column8
                { url: 'img/FriendsPlus/Page66/E3/20.jpg' },
                { url: 'img/FriendsPlus/Page66/E3/21.jpg', input: true, answer: "How" },
                { url: 'img/FriendsPlus/Page66/E3/22.jpg' },
            ],
            [
                // Column9
                { url: 'img/FriendsPlus/Page66/E3/23.jpg' },
                { url: 'img/FriendsPlus/Page66/E3/24.jpg', input: true, answer: "many" },
                { url: 'img/FriendsPlus/Page66/E3/25.jpg' },
            ],
            [
                // Column10
                { url: 'img/FriendsPlus/Page66/E3/26.jpg' },
                { url: 'img/FriendsPlus/Page66/E3/27.jpg', input: true, answer: "many" },
                { url: 'img/FriendsPlus/Page66/E3/28.jpg' },
            ],
            [
                // Column11
                { url: 'img/FriendsPlus/Page66/E3/29.jpg' },
                { url: 'img/FriendsPlus/Page66/E3/30.jpg', input: true, answer: "There's" },
                { url: 'img/FriendsPlus/Page66/E3/31.jpg' },
            ],



        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    4: { // Exercise num
        unit: 'Language Focus Practice Unit 1',
        id: 'WB6-LFP1-P66-E4',
        audio: '',
        video: '',
        component: T1,
        exerciseKey: 'img/FriendsPlus/Page66/E4/Key/answerKey.png',
        titleImage: 'img/FriendsPlus/Page66/E4/title.jpg',
        //titleQuestion: [{ num: '2', title: 'Read the text. What is Hannah’s project about? What is she learning about at school?', color: "#4D3D9B" }],
        questionImage: [],
        questions: [
            {
                title: "<p>there / is / in / good / a / cinema / area / your / ?</p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>Is there a good cinema in your area? Yes, there is.</u></p>" +

                    "<p><span style='font-weight:600'>1</span> pens / there / are / any / bag / your / in / ? </p><p style='font-weight:500'>#</p>" +
                    "<p><span style='font-weight:600'>2</span> library / is / a / there / in / school / your / ? </p><p style='font-weight:500'>#</p>" +
                    "<p><span style='font-weight:600'>3</span> class / many / students / there / are / your / in / how / ? </p><p style='font-weight:500'>#</p>" +
                    "<p><span style='font-weight:600'>4</span> your / any / school / are / interesting / places / near / there / ? </p><p style='font-weight:500'>#</p>" +
                    "<p><span style='font-weight:600'>5</span> many / how / posters / in / bedroom / are / your / there / ?</p><p style='font-weight:500'>#</p>",

                answer: [
                    "Are there any pens in your bag? Yes, there are./Are there any pens in your bag? No, there aren't.",
                    "Is there a library in your school? Yes, there is./Is there a library in your school? No, there isn't.",
                    "How many students are there in your class? There are …",
                    "Are there any interesting places near your school? Yes, there are./Are there any interesting places near your school? No, there aren't.",
                    "How many posters are there in your bedroom? There are …",

                ],
                type: 'longAnwser'
            },
        ]
    },
    5: { // Exercise num
        unit: 'Language Focus Practice Unit 1',
        id: 'WB6-LFP1-P66-E5',
        audio: '',
        video: '',
        exerciseKey: 'img/FriendsPlus/Page66/E5/Key/answerKey.png',
        component: T6,
        inputSize: 230,
        imgSize: '80%',
        titleImage: 'img/FriendsPlus/Page66/E5/title.jpg',
        // titleQuestion: [{ num: '5', title: 'Write the comparative adjectives.', color: '#41CADC', star: 2 }],
        questionImage: [],
        questions: [
            {
                title:
                    "<p>small &emsp;<span style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray;font-size: 30px'><u> smaller</u></span></p>" +
                    "<p><span style='font-weight:600'>1</span>.  pretty # <span style='font-weight:600'>6</span>. comfortable #</p>" +
                    "<p><span style='font-weight:600'>2</span>.  easy #   &ensp;<span style='font-weight:600'>7</span>. safe &emsp; #</p>" +
                    "<p><span style='font-weight:600'>3</span>.  fast #   &emsp;<span style='font-weight:600'>8</span>. far  &emsp; #</p>" +
                    "<p><span style='font-weight:600'>4</span>.  bad #    &emsp;<span style='font-weight:600'>9</span>. expensive #</p>" +
                    "<p><span style='font-weight:600'>5</span>.  hot #    &emsp;<span style='font-weight:600'>10</span>. new &emsp; #</p>",
                answer: [
                    "prettier ", "more comfortable ",
                    "easier ", "safer ",
                    "faster ", "further ",
                    "worse ", "more expensive ",
                    "hotter ", "newer",
                ],
                type: 'longAnwser'
            },
        ]
    },
    6: { // Exercise num
        unit: 'Language Focus Practice Unit 1',
        id: 'WB6-LFP1-P66-E6',
        audio: '',
        video: '',
        component: D1,

        exerciseKey: 'img/FriendsPlus/Page66/E6/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page66/E6/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page66/E6/2.jpg' },
                { url: 'img/FriendsPlus/Page66/E6/3.jpg', input: true, answer: "more popular than" },
                { url: 'img/FriendsPlus/Page66/E6/4.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page66/E6/5.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page66/E6/6.jpg' },
                { url: 'img/FriendsPlus/Page66/E6/7.jpg', input: true, answer: "nicer than" },
                { url: 'img/FriendsPlus/Page66/E6/8.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page66/E6/9.jpg' },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page66/E6/10.jpg' },
                { url: 'img/FriendsPlus/Page66/E6/11.jpg', input: true, answer: "bigger than" },
                { url: 'img/FriendsPlus/Page66/E6/12.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page66/E6/13.jpg' },
                { url: 'img/FriendsPlus/Page66/E6/14.jpg', input: true, answer: "friendlier than" },
                { url: 'img/FriendsPlus/Page66/E6/15.jpg' },
            ],
            [
                // Column8
                { url: 'img/FriendsPlus/Page66/E6/16.jpg' },
            ],
            [
                // Column9
                { url: 'img/FriendsPlus/Page66/E6/17.jpg' },
                { url: 'img/FriendsPlus/Page66/E6/18.jpg', input: true, answer: "better than" },
                { url: 'img/FriendsPlus/Page66/E6/19.jpg' },
            ],
            [
                // Column10
                { url: 'img/FriendsPlus/Page66/E6/20.jpg' },
            ],
            [
                // Column11
                { url: 'img/FriendsPlus/Page66/E6/21.jpg' },
                { url: 'img/FriendsPlus/Page66/E6/22.jpg', input: true, answer: "more interesting than" },
                { url: 'img/FriendsPlus/Page66/E6/23.jpg' },
            ],
            [
                // Column12
                { url: 'img/FriendsPlus/Page66/E6/24.jpg' },
            ],
            [
                // Column13
                { url: 'img/FriendsPlus/Page66/E6/25.jpg' },
                { url: 'img/FriendsPlus/Page66/E6/26.jpg', input: true, answer: "younger" },
                { url: 'img/FriendsPlus/Page66/E6/27.jpg' },
            ],

        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },


}

export default json;