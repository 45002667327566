
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
    1: { // Exercise num
        unit: 'Unit 2',
        id: 'WB6-U2-P19-E1',
        audio: '',
        video: '',
        component: T1,
        exerciseKey: 'img/FriendsPlus/Page19/E1/Key/answerKey.png',
        titleImage: '',
        titleQuestion: [{ num: '1', title: 'Order the words to make sentences.', color: "#1ABED7", star: 2 }],
        questionImage: [],
        questions: [
            {
                title: "<p>sometimes / watch / videos / We</p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>We sometimes watch videos.</u></p>" +
                    "<p><span style='font-weight:600'>1</span>. football / plays / Robert / never # </p>" +
                    "<p><span style='font-weight:600'>2</span>. friendly / They / always / are # </p>" +
                    "<p><span style='font-weight:600'>3</span>. my / normally / homework / I / do # </p>" +
                    "<p><span style='font-weight:600'>4</span>. often / Marina and Julia / late / are # </p>" +
                    "<p><span style='font-weight:600'>5</span>. dinner / usually / have / We # </p>" +
                    "<p><span style='font-weight:600'>6</span>. class / your / Is / quiet / often / ? # </p>",
                answer: [
                    "Robert never plays football",
                    "They are always friendly",
                    "I normally do my homework",
                    "Marina and Julia are often late",
                    "We usually have dinner",
                    "Is your class often quiet?",
                ],
                type: 'longAnwser'
            },
        ]
    },
    2: { // Exercise num
        unit: 'Unit 2',
        id: 'WB6-U2-P19-E2',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page19/E2/Key/answerKey.png',
        titleImage: '',
        titleQuestion: [{ num: '2', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page19/E2/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page19/E2/2.jpg' },
                { url: 'img/FriendsPlus/Page19/E2/3.jpg', input: true, answer: "plays" },
                { url: 'img/FriendsPlus/Page19/E2/4.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page19/E2/5.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page19/E2/6.jpg' },
                { url: 'img/FriendsPlus/Page19/E2/7.jpg', input: true, answer: "play" },
                { url: 'img/FriendsPlus/Page19/E2/8.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page19/E2/9.jpg' },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page19/E2/10.jpg' },
                { url: 'img/FriendsPlus/Page19/E2/11.jpg', input: true, answer: "don't" },
                { url: 'img/FriendsPlus/Page19/E2/12.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page19/E2/13.jpg' },
                { url: 'img/FriendsPlus/Page19/E2/14.jpg', input: true, answer: "doesn't" },
                { url: 'img/FriendsPlus/Page19/E2/15.jpg' },
            ],
            [
                // Column8
                { url: 'img/FriendsPlus/Page19/E2/16.jpg' },
                { url: 'img/FriendsPlus/Page19/E2/17.jpg', input: true, answer: "don't" },
                { url: 'img/FriendsPlus/Page19/E2/18.jpg' },
            ],
            [
                // Column9
                { url: 'img/FriendsPlus/Page19/E2/19.jpg' },
            ],


        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    3: { // Exercise num
        unit: 'Unit 2',
        id: 'WB6-U2-P19-E3',
        audio: '',
        video: '',
        component: T1,
        exerciseKey: 'img/FriendsPlus/Page19/E3/Key/answerKey.png',
        titleImage: '',
        titleQuestion: [{ num: '3', title: 'Write affirmative or negative sentences using the present simple.', color: "#1ABED7", star: 2 }],
        questionImage: [],
        questions: [
            {
                title: "<p>We / have dinner / early ✘</p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>We don’t have dinner early.</u></p>" +
                    "<p><span style='font-weight:600'>1</span>. my sister / watch / TV a lot ✔ # </p>" +
                    "<p><span style='font-weight:600'>2</span>. I / get up late / on Mondays ✘ # </p>" +
                    "<p><span style='font-weight:600'>3</span>. my dad / make breakfast / at the weekend ✔ # </p>" +
                    "<p><span style='font-weight:600'>4</span>. my older brothers / go to bed / early ✘ # </p>" +
                    "<p><span style='font-weight:600'>5</span>. you / help / with the housework ✔ # </p>" +
                    "<p><span style='font-weight:600'>6</span>. my mum / play / video games ✘ # </p>",
                answer: [
                    "My sister watches TV a lot",
                    "I don't get up late on Mondays",
                    "My dad makes breakfast at the weekend",
                    "My older brothers don't go to bed early",
                    "You help with the housework",
                    "My mum doesn't play video games",
                ],
                type: 'longAnwser'
            },
        ]
    },
    4: { // Exercise num
        unit: 'Unit 2',
        id: 'WB6-U2-P19-E4',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page19/E4/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page19/E4/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page19/E4/2.jpg' },
                { url: 'img/FriendsPlus/Page19/E4/3.jpg', input: true, answer: "get up" },
                { url: 'img/FriendsPlus/Page19/E4/4.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page19/E4/5.jpg' },
                { url: 'img/FriendsPlus/Page19/E4/6.jpg', input: true, answer: "8.00" },
                { url: 'img/FriendsPlus/Page19/E4/7.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page19/E4/8.jpg' },
                { url: 'img/FriendsPlus/Page19/E4/9.jpg', input: true, answer: "plays football " },
                { url: 'img/FriendsPlus/Page19/E4/10.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page19/E4/11.jpg' },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page19/E4/12.jpg' },
                { url: 'img/FriendsPlus/Page19/E4/13.jpg', input: true, answer: "play tennis" },
                { url: 'img/FriendsPlus/Page19/E4/14.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page19/E4/15.jpg' },
            ],
            [
                // Column8
                { url: 'img/FriendsPlus/Page19/E4/16.jpg' },
                { url: 'img/FriendsPlus/Page19/E4/17.jpg', input: true, answer: "goes" },
                { url: 'img/FriendsPlus/Page19/E4/18.jpg' },
                { url: 'img/FriendsPlus/Page19/E4/19.jpg', input: true, answer: "train" },
                { url: 'img/FriendsPlus/Page19/E4/20.jpg' },
            ],
            [
                // Column9
                { url: 'img/FriendsPlus/Page19/E4/21.jpg' },
                { url: 'img/FriendsPlus/Page19/E4/22.jpg', input: true, answer: "go" },
                { url: 'img/FriendsPlus/Page19/E4/23.jpg' },
            ],
            [
                // Column10
                { url: 'img/FriendsPlus/Page19/E4/24.jpg' },
                { url: 'img/FriendsPlus/Page19/E4/25.jpg', input: true, answer: "bus" },
                { url: 'img/FriendsPlus/Page19/E4/26.jpg' },
            ],
            [
                // Column11
                { url: 'img/FriendsPlus/Page19/E4/27.jpg' },
                { url: 'img/FriendsPlus/Page19/E4/28.jpg', input: true, answer: "studies" },
                { url: 'img/FriendsPlus/Page19/E4/29.jpg' },
                { url: 'img/FriendsPlus/Page19/E4/30.jpg', input: true, answer: "French" },
                { url: 'img/FriendsPlus/Page19/E4/31.jpg' },
            ],
            [
                // Column12
                { url: 'img/FriendsPlus/Page19/E4/32.jpg' },
            ],
            [
                // Column13
                { url: 'img/FriendsPlus/Page19/E4/33.jpg' },
                { url: 'img/FriendsPlus/Page19/E4/34.jpg', input: true, answer: "study" },
                { url: 'img/FriendsPlus/Page19/E4/35.jpg' },
            ],
            [
                // Column14
                { url: 'img/FriendsPlus/Page19/E4/36.jpg' },
                { url: 'img/FriendsPlus/Page19/E4/37.jpg', input: true, answer: "Spanish" },
                { url: 'img/FriendsPlus/Page19/E4/38.jpg' },
            ],
            [
                // Column15
                { url: 'img/FriendsPlus/Page19/E4/39.jpg' },
                { url: 'img/FriendsPlus/Page19/E4/40.jpg', input: true, answer: "finishes" },
                { url: 'img/FriendsPlus/Page19/E4/41.jpg' },
                { url: 'img/FriendsPlus/Page19/E4/42.jpg', input: true, answer: "3.00" },
                { url: 'img/FriendsPlus/Page19/E4/43.jpg' },
            ],
            [
                // Column16
                { url: 'img/FriendsPlus/Page19/E4/44.jpg' },
            ],
            [
                // Column17
                { url: 'img/FriendsPlus/Page19/E4/45.jpg' },
                { url: 'img/FriendsPlus/Page19/E4/46.jpg', input: true, answer: "finish" },
                { url: 'img/FriendsPlus/Page19/E4/47.jpg' },
            ],
            [
                // Column18
                { url: 'img/FriendsPlus/Page19/E4/48.jpg' },
                { url: 'img/FriendsPlus/Page19/E4/49.jpg', input: true, answer: "4.00" },
                { url: 'img/FriendsPlus/Page19/E4/50.jpg' },
            ],



        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    5: { // Exercise num
        unit: 'Unit 2',
        id: 'WB6-U2-P19-E5',
        audio: '',
        video: '',
        component: T2,
        exerciseKey: '',
        imgSize: '80%',
        titleImage: 'img/FriendsPlus/Page19/E5/title.png',
        //titleQuestion: [{ num: '4', title: 'Write sentences about the capital city of your country or a big city near you.', color: '#66A0B4', star: 3 }],
        questionImage: [],
        questions: [
            {
                title: "<p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>My sister goes to the sports centre.</u></p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>",
                answer: [],
                type: 'longAnwser'
            },
        ]
    },



}

export default json;