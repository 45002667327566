import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
    1: { // Exercise num
        unit : 'Unit 2',
        id : 'WB6-U2-P21-E1',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page21/E1/Key/Key.png',
        titleImage: '',
        questionImage: [ // Row
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E1/1.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E1/2.jpg' },
                { url: 'img/FriendsPlus/Page21/E1/3.jpg', input: true, answer: "do" },
                { url: 'img/FriendsPlus/Page21/E1/4.jpg' },
                { url: 'img/FriendsPlus/Page21/E1/5.jpg', input: true, answer: "don't" },
                { url: 'img/FriendsPlus/Page21/E1/6.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E1/7.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E1/8.jpg' },
                { url: 'img/FriendsPlus/Page21/E1/9.jpg', input: true, answer: "Does" },
                { url: 'img/FriendsPlus/Page21/E1/10.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E1/11.jpg' },
                { url: 'img/FriendsPlus/Page21/E1/12.jpg', input: true, answer: "does" },
                { url: 'img/FriendsPlus/Page21/E1/13.jpg' },
                { url: 'img/FriendsPlus/Page21/E1/14.jpg', input: true, answer: "doesn't" },
                { url: 'img/FriendsPlus/Page21/E1/15.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E1/16.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E1/17.jpg' },
                { url: 'img/FriendsPlus/Page21/E1/18.jpg', input: true, answer: "Do" },
                { url: 'img/FriendsPlus/Page21/E1/19.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E1/20.jpg' },
                { url: 'img/FriendsPlus/Page21/E1/21.jpg', input: true, answer: "do" },
                { url: 'img/FriendsPlus/Page21/E1/22.jpg' },
                { url: 'img/FriendsPlus/Page21/E1/23.jpg', input: true, answer: "don't" },
                { url: 'img/FriendsPlus/Page21/E1/24.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E1/25.jpg' },
            ],
        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },

    2: { // Exercise num
        unit : 'Unit 2',
        id : 'WB6-U2-P21-E2',
        audio: '',
        video: '',
        component: D1,
        fontSize: 25,
        exerciseKey: 'img/FriendsPlus/Page21/E2/Key/Key.png',
        titleImage: '',
        questionImage: [ // Row
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E2/1.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E2/2.jpg' },
                { url: 'img/FriendsPlus/Page21/E2/3.jpg', input: true, answer: "Do" },
                { url: 'img/FriendsPlus/Page21/E2/4.jpg' },
                { url: 'img/FriendsPlus/Page21/E2/5.jpg', input: true, answer: "sing" },
                { url: 'img/FriendsPlus/Page21/E2/6.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E2/7.jpg' },
                { url: 'img/FriendsPlus/Page21/E2/8.jpg', input: true, answer: "don't" },
                { url: 'img/FriendsPlus/Page21/E2/9.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E2/10.jpg' },
                { url: 'img/FriendsPlus/Page21/E2/11.jpg', input: true, answer: "Do" },
                { url: 'img/FriendsPlus/Page21/E2/12.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E2/13.jpg' },
                { url: 'img/FriendsPlus/Page21/E2/14.jpg', input: true, answer: "wear" },
                { url: 'img/FriendsPlus/Page21/E2/15.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E2/16.jpg' },
                { url: 'img/FriendsPlus/Page21/E2/17.jpg', input: true, answer: "do" },
                { url: 'img/FriendsPlus/Page21/E2/18.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E2/19.jpg' },
                { url: 'img/FriendsPlus/Page21/E2/20.jpg', input: true, answer: "Does" },
                { url: 'img/FriendsPlus/Page21/E2/21.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E2/22.jpg' },
                { url: 'img/FriendsPlus/Page21/E2/23.jpg', input: true, answer: "play" },
                { url: 'img/FriendsPlus/Page21/E2/24.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E2/25.jpg' },
                { url: 'img/FriendsPlus/Page21/E2/26.jpg', input: true, answer: "doesn't" },
                { url: 'img/FriendsPlus/Page21/E2/27.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E2/28.jpg' },
                { url: 'img/FriendsPlus/Page21/E2/29.jpg', input: true, answer: "Do" },
                { url: 'img/FriendsPlus/Page21/E2/30.jpg' },
                { url: 'img/FriendsPlus/Page21/E2/31.jpg', input: true, answer: "visit" },
                { url: 'img/FriendsPlus/Page21/E2/32.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E2/33.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E2/34.jpg' },
                { url: 'img/FriendsPlus/Page21/E2/35.jpg', input: true, answer: "do" },
                { url: 'img/FriendsPlus/Page21/E2/36.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E2/37.jpg' },
                { url: 'img/FriendsPlus/Page21/E2/38.jpg', input: true, answer: "Does" },
                { url: 'img/FriendsPlus/Page21/E2/39.jpg' },
                { url: 'img/FriendsPlus/Page21/E2/40.jpg', input: true, answer: "go" },
                { url: 'img/FriendsPlus/Page21/E2/41.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E2/42.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E2/43.jpg' },
                { url: 'img/FriendsPlus/Page21/E2/44.jpg', input: true, answer: "does" },
                { url: 'img/FriendsPlus/Page21/E2/45.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E2/46.jpg' },
                { url: 'img/FriendsPlus/Page21/E2/47.jpg', input: true, answer: "Do" },
                { url: 'img/FriendsPlus/Page21/E2/48.jpg' },
                { url: 'img/FriendsPlus/Page21/E2/49.jpg', input: true, answer: "give" },
                { url: 'img/FriendsPlus/Page21/E2/50.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E2/51.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E2/52.jpg' },
                { url: 'img/FriendsPlus/Page21/E2/53.jpg', input: true, answer: "don't" },
                { url: 'img/FriendsPlus/Page21/E2/54.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E2/55.jpg' },
                { url: 'img/FriendsPlus/Page21/E2/56.jpg', input: true, answer: "Does" },
                { url: 'img/FriendsPlus/Page21/E2/57.jpg' },
                { url: 'img/FriendsPlus/Page21/E2/58.jpg', input: true, answer: "watch" },
                { url: 'img/FriendsPlus/Page21/E2/59.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E2/60.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E2/61.jpg' },
                { url: 'img/FriendsPlus/Page21/E2/62.jpg', input: true, answer: "doesn't" },
                { url: 'img/FriendsPlus/Page21/E2/63.jpg' },
            ],
        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },

    3: { // Exercise num
        unit : 'Unit 2',
        id : 'WB6-U2-P21-E3',
        audio: '',
        video: '',
        component: D1,
        fontSize: 25,
        exerciseKey: 'img/FriendsPlus/Page21/E3/Key/Key.png',
        titleImage: '',
        questionImage: [ // Row
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E3/1.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E3/2.jpg' },
                { url: 'img/FriendsPlus/Page21/E3/3.jpg', input: true, answer: "How do you go to school" },
                { url: 'img/FriendsPlus/Page21/E3/4.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E3/5.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E3/6.jpg' },
                { url: 'img/FriendsPlus/Page21/E3/7.jpg', input: true, answer: "Do you play football" },
                { url: 'img/FriendsPlus/Page21/E3/8.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E3/9.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E3/10.jpg' },
                { url: 'img/FriendsPlus/Page21/E3/11.jpg', input: true, answer: "do" },
                { url: 'img/FriendsPlus/Page21/E3/12.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E3/13.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E3/14.jpg' },
                { url: 'img/FriendsPlus/Page21/E3/15.jpg', input: true, answer: "What time do you start" },
                { url: 'img/FriendsPlus/Page21/E3/16.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E3/17.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E3/18.jpg' },
                { url: 'img/FriendsPlus/Page21/E3/19.jpg', input: true, answer: "Does he like football" },
                { url: 'img/FriendsPlus/Page21/E3/20.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E3/21.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E3/22.jpg' },
                { url: 'img/FriendsPlus/Page21/E3/23.jpg', input: true, answer: "does" },
                { url: 'img/FriendsPlus/Page21/E3/24.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page21/E3/25.jpg' },
            ],
        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },

    4: { // Exercise num
        unit : 'Unit 2',
        id : 'WB6-U2-P21-E4',
        audio: '',
        video: '',
        component: T6,
        exerciseKey: 'img/FriendsPlus/Page21/E4/Key/Key.png',
        imgSize: "70%",
        inputSize: 900,
        titleImage: 'img/FriendsPlus/Page21/E4/title.jpg',
        //titleQuestion: [{ num: '4', title: 'Write sentences about the capital city of your country or a big city near you.', color: '#66A0B4', star: 3 }],
        questionImage: [],
        questions: [
            {
                title: "<p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>Do Sarah and Ann get up late? Yes, they do.</u></p>" +
                    "<p style='font-weight:500'>#.</p>" +
                    "<p style='font-weight:500'>#.</p>" +
                    "<p style='font-weight:500'>#.</p>" +
                    "<p style='font-weight:500'>#.</p>" +
                    "<p style='font-weight:500'>#.</p>" +
                    "<p style='font-weight:500'>#.</p>" +
                    "<p style='font-weight:500'>#.</p>" +
                    "<p style='font-weight:500'>#.</p>",
                answer: [
                    "Does Khalid get up late? No, he doesn't",
                    "Do you get up late? Yes, I do / Do you get up late? No, I don't",
                    "Do Sarah and Ann watch videos? No, they don't",
                    "Does Khalid watch videos? Yes, he does",
                    "Do you watch videos? Yes, I do / Do you watch videos? No, I don't",
                    "Do Sarah and Ann go out with friends? Yes, they do",
                    "Does Khalid go out with friends? No, he doesn't",
                    "Do you go out with friends? Yes, I do / Do you go out with friends? No, I don't",
                ],
                type: 'longAnwser'
            },
        ]
    },

}

export default json;