import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import WordsBox from '../../components/ExcerciseTypes/Design/WordBox';
import WordBox from '../../components/ExcerciseTypes/Design/WordBox2';

const json = {
  1: { // Exercise num
    unit: 'Unit 2',
    id: 'WB6-U2-P20-E1',
    audio: '',
    video: '',
    component: WordsBox,
    exerciseKey: 'img/FriendsPlus/Page20/E1/key.png',
    titleImage: '',
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page20/E1/1.png' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E1/2.png' },
        { url: 'img/FriendsPlus/Page20/E1/3.png', input: true, answer: 'g' },
        { url: 'img/FriendsPlus/Page20/E1/4.png', input: true, answer: 'i' },
        { url: 'img/FriendsPlus/Page20/E1/5.png', input: true, answer: 'v' },
        { url: 'img/FriendsPlus/Page20/E1/6.png' },
        { url: 'img/FriendsPlus/Page20/E1/7.png' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E1/8.png' },
        { url: 'img/FriendsPlus/Page20/E1/9.png', input: true, answer: 'o' },
        { url: 'img/FriendsPlus/Page20/E1/10.png' },
        { url: 'img/FriendsPlus/Page20/E1/11.png' },
        { url: 'img/FriendsPlus/Page20/E1/12.png', input: true, answer: 'd' },
        { url: 'img/FriendsPlus/Page20/E1/13.png' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E1/14.png' },
        { url: 'img/FriendsPlus/Page20/E1/15.png', input: true, answer: 'o' },
        { url: 'img/FriendsPlus/Page20/E1/16.png' },
        { url: 'img/FriendsPlus/Page20/E1/17.png' },
        { url: 'img/FriendsPlus/Page20/E1/18.png', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page20/E1/19.png' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E1/20.png' },
        { url: 'img/FriendsPlus/Page20/E1/21.png', input: true, answer: 's' },
        { url: 'img/FriendsPlus/Page20/E1/22.png' },
        { url: 'img/FriendsPlus/Page20/E1/23.png', input: true, answer: 'u' },
        { url: 'img/FriendsPlus/Page20/E1/24.png' },
        { url: 'img/FriendsPlus/Page20/E1/25.png' },
        { url: 'img/FriendsPlus/Page20/E1/26.png', input: true, answer: 'n' },
        { url: 'img/FriendsPlus/Page20/E1/27.png' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E1/28.png' },
        { url: 'img/FriendsPlus/Page20/E1/29.png', input: true, answer: 'v' },
        { url: 'img/FriendsPlus/Page20/E1/30.png', input: true, answer: 'i' },
        { url: 'img/FriendsPlus/Page20/E1/31.png', input: true, answer: 's' },
        { url: 'img/FriendsPlus/Page20/E1/32.png', input: true, answer: 'i' },
        { url: 'img/FriendsPlus/Page20/E1/33.png', input: true, answer: 't' },
        { url: 'img/FriendsPlus/Page20/E1/34.png' },
        { url: 'img/FriendsPlus/Page20/E1/35.png' },
        { url: 'img/FriendsPlus/Page20/E1/36.png', input: true, answer: 'c' },
        { url: 'img/FriendsPlus/Page20/E1/37.png' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E1/38.png' },
        { url: 'img/FriendsPlus/Page20/E1/39.png', input: true, answer: 'n' },
        { url: 'img/FriendsPlus/Page20/E1/40.png' },
        { url: 'img/FriendsPlus/Page20/E1/41.png', input: true, answer: 'm' },
        { url: 'img/FriendsPlus/Page20/E1/42.png' },
        { url: 'img/FriendsPlus/Page20/E1/43.png', input: true, answer: 'k' },
        { url: 'img/FriendsPlus/Page20/E1/44.png', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page20/E1/45.png' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E1/46.png' },
        { url: 'img/FriendsPlus/Page20/E1/47.png', input: true, answer: 'g' },
        { url: 'img/FriendsPlus/Page20/E1/48.png' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E1/49.png' },
        { url: 'img/FriendsPlus/Page20/E1/50.png', input: true, answer: 'i' },
        { url: 'img/FriendsPlus/Page20/E1/51.png', input: true, answer: 'n' },
        { url: 'img/FriendsPlus/Page20/E1/52.png', input: true, answer: 'v' },
        { url: 'img/FriendsPlus/Page20/E1/53.png', input: true, answer: 'i' },
        { url: 'img/FriendsPlus/Page20/E1/54.png', input: true, answer: 't' },
        { url: 'img/FriendsPlus/Page20/E1/55.png' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E1/56.png' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  2: { // Exercise num
    unit: 'Unit 2',
    id: 'WB6-U2-P20-E2',
    audio: '',
    video: '',
    component: WordBox,
    fontSize: 25,
    typeInput: 'center',
    isAutofocus: true,
    // titleQuestion: [{ num: '2', title: 'Complete the words in the text.', color: "#f57e26", star: 2 }],
    exerciseKey: 'img/FriendsPlus/Page20/E2/Key/Key.png',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page20/E2/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page20/E2/3.jpg', input: true, answer: 'l' },
        { url: 'img/FriendsPlus/Page20/E2/4.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page20/E2/5.jpg', input: true, answer: 'y' },
        { url: 'img/FriendsPlus/Page20/E2/6.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E2/7.jpg' },
        { url: 'img/FriendsPlus/Page20/E2/8.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page20/E2/9.jpg', input: true, answer: 'l' },
        { url: 'img/FriendsPlus/Page20/E2/10.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page20/E2/11.jpg', input: true, answer: 'b' },
        { url: 'img/FriendsPlus/Page20/E2/12.jpg', input: true, answer: 'r' },
        { url: 'img/FriendsPlus/Page20/E2/13.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page20/E2/14.jpg', input: true, answer: 't' },
        { url: 'img/FriendsPlus/Page20/E2/15.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page20/E2/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E2/17.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E2/18.jpg' },
        { url: 'img/FriendsPlus/Page20/E2/19.jpg', input: true, answer: 'n' },
        { url: 'img/FriendsPlus/Page20/E2/20.jpg', input: true, answer: 'v' },
        { url: 'img/FriendsPlus/Page20/E2/21.jpg', input: true, answer: 'i' },
        { url: 'img/FriendsPlus/Page20/E2/22.jpg', input: true, answer: 't' },
        { url: 'img/FriendsPlus/Page20/E2/23.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page20/E2/24.jpg', input: true, answer: 's' },
        { url: 'img/FriendsPlus/Page20/E2/25.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E2/26.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E2/27.jpg' },
        { url: 'img/FriendsPlus/Page20/E2/28.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page20/E2/29.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page20/E2/30.jpg', input: true, answer: 'r' },
        { url: 'img/FriendsPlus/Page20/E2/31.jpg', input: true, answer: 's' },
        { url: 'img/FriendsPlus/Page20/E2/32.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E2/33.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E2/34.jpg' },
        { url: 'img/FriendsPlus/Page20/E2/35.jpg', input: true, answer: 'i' },
        { url: 'img/FriendsPlus/Page20/E2/36.jpg', input: true, answer: 's' },
        { url: 'img/FriendsPlus/Page20/E2/37.jpg', input: true, answer: 'i' },
        { url: 'img/FriendsPlus/Page20/E2/38.jpg', input: true, answer: 't' },
        { url: 'img/FriendsPlus/Page20/E2/39.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E2/40.jpg' },
        { url: 'img/FriendsPlus/Page20/E2/41.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page20/E2/42.jpg', input: true, answer: 'v' },
        { url: 'img/FriendsPlus/Page20/E2/43.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page20/E2/44.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E2/45.jpg' },
      ],
    ]
  },

  3: { // Exercise num
    unit: 'Unit 2',
    id: 'WB6-U2-P20-E3',
    audio: 'img/FriendsPlus/Page20/E3/Audio/Friends Plus for Vietnam G6 WB Track 03.mp3',
    video: '',
    component: DesignUnderLine,
    totalInput: 5,
    exerciseKey: 'img/FriendsPlus/Page20/E3/Key/answerKey.png',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page20/E3/03.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E3/05.jpg' },
        { url: 'img/FriendsPlus/Page20/E3/06.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page20/E3/07.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E3/08.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E3/09.jpg' },
        { url: 'img/FriendsPlus/Page20/E3/10.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page20/E3/11.jpg' },
        { url: 'img/FriendsPlus/Page20/E3/12.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page20/E3/13.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E3/14.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E3/15.jpg' },
        { url: 'img/FriendsPlus/Page20/E3/16.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page20/E3/17.jpg' },
        { url: 'img/FriendsPlus/Page20/E3/18.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page20/E3/19.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E3/20.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E3/21.jpg' },
        { url: 'img/FriendsPlus/Page20/E3/22.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page20/E3/23.jpg' },
        { url: 'img/FriendsPlus/Page20/E3/24.jpg', input: 7 },
        { url: 'img/FriendsPlus/Page20/E3/25.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },


  4: { // Exercise num
    unit: 'Unit 2',
    id: 'WB6-U2-P20-E4',
    audio: 'img/FriendsPlus/Page20/E4/Audio/Friends Plus for Vietnam G6 WB Track 03.mp3',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page20/E4/Key/Key.png',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page20/E4/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page20/E4/3.jpg', input: true, answer: "False" },
        { url: 'img/FriendsPlus/Page20/E4/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E4/5.jpg' },
        { url: 'img/FriendsPlus/Page20/E4/6.jpg', input: true, answer: "False" },
        { url: 'img/FriendsPlus/Page20/E4/7.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E4/8.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E4/9.jpg' },
        { url: 'img/FriendsPlus/Page20/E4/10.jpg', input: true, answer: "True" },
        { url: 'img/FriendsPlus/Page20/E4/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E4/12.jpg' },
        { url: 'img/FriendsPlus/Page20/E4/13.jpg', input: true, answer: "True" },
        { url: 'img/FriendsPlus/Page20/E4/14.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E4/15.jpg' },
        { url: 'img/FriendsPlus/Page20/E4/16.jpg', input: true, answer: "False" },
        { url: 'img/FriendsPlus/Page20/E4/17.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E4/18.jpg' },
        { url: 'img/FriendsPlus/Page20/E4/19.jpg', input: true, answer: "True" },
        { url: 'img/FriendsPlus/Page20/E4/20.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  5: { // Exercise num
    unit: 'Unit 2',
    id: 'WB6-U2-P20-E5',
    audio: '',
    video: '',
    component: T2,
    exerciseKey: '',
    imgSize: '85%',
    titleImage: 'img/FriendsPlus/Page20/E5/title.jpg',
    //titleQuestion: [{ num: '4', title: 'Write sentences about the capital city of your country or a big city near you.', color: '#66A0B4', star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p># is a special day in my country.</p>" +
          "<p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>We give presents to ...</u></p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>" +
          "<p style='font-weight:500'>#</p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}

export default json;