import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {

    1: { // Exercise num
        unit : 'Cumulative review Starter unit-Unit 6',
        id : 'WB6-CR3-P62-E1',
        audio: '',
        video: '',
        component: T2,
        exerciseKey: '',
        titleImage: 'img/FriendsPlus/Page62/E1/title.jpg',
        //titleQuestion: [{ num: '4', title: '', color: '#19BED7', star: 3 }],
        questionImage: [],
        questions: [
            {
                title:
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>",
                answer: [],
                type: 'longAnwser'
            },
        ]
    },
    2: { // Exercise num
        unit : 'Cumulative review Starter unit-Unit 6',
        id : 'WB6-CR3-P62-E2',
        audio: '',
        video: '',
        component: T6,
        inputSize: 900,
        imgSize: '70%',
        exerciseKey: 'img/FriendsPlus/Page62/E2/Key/answerKey.png',
        titleImage: 'img/FriendsPlus/Page62/E2/title.jpg',
        //titleQuestion: [{ num: '2', title: 'Read the text. What is Hannah’s project about? What is she learning about at school?', color: "#4D3D9B" }],
        questionImage: [],
        questions: [
            {
                title:

                    "<p>#</p>",

                answer: [
                    "She's training because she's in a beach volleyball competition tomorrow.",

                ],
                type: 'longAnwser'
            },
        ]
    },
    // 3: { // Exercise num
    unit : 'Cumulative review Starter unit-Unit 6',
    //     audio: '',
    //     video: '',
    //     component: D1,
    //     exerciseKey: 'img/FriendsPlus/Page62/E2/Key/answerKey.png',
    //     titleImage: 'img/FriendsPlus/Page62/E3/title.jpg',
    //     //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    //     questionImage: [ // Row
    //         [
    //             // Column1
    //             { url: 'img/FriendsPlus/Page62/E3/1.jpg' },
    //         ],
    //         [
    //             // Column2
    //             { url: 'img/FriendsPlus/Page62/E3/2.jpg' },
    //             { url: 'img/FriendsPlus/Page62/E3/3.jpg', input: true, answer: "really" },
    //             { url: 'img/FriendsPlus/Page62/E3/4.jpg' },
    //         ],
    //         [
    //             // Column3
    //             { url: 'img/FriendsPlus/Page62/E3/5.jpg' },
    //         ],
    //         [
    //             // Column4
    //             { url: 'img/FriendsPlus/Page62/E3/6.jpg' },
    //             { url: 'img/FriendsPlus/Page62/E3/7.jpg', input: true, answer: "doesn't mind" },
    //             { url: 'img/FriendsPlus/Page62/E3/8.jpg' },
    //         ],
    //         [
    //             // Column5
    //             { url: 'img/FriendsPlus/Page62/E3/9.jpg' },
    //         ],
    //         [
    //             // Column6
    //             { url: 'img/FriendsPlus/Page62/E3/10.jpg' },
    //             { url: 'img/FriendsPlus/Page62/E3/11.jpg', input: true, answer: "cycling" },
    //             { url: 'img/FriendsPlus/Page62/E3/12.jpg' },
    //         ],
    //         [
    //             // Column7
    //             { url: 'img/FriendsPlus/Page62/E3/13.jpg' },
    //         ],
    //         [
    //             // Column8
    //             { url: 'img/FriendsPlus/Page62/E3/14.jpg' },
    //             { url: 'img/FriendsPlus/Page62/E3/15.jpg', input: true, answer: "fast" },
    //             { url: 'img/FriendsPlus/Page62/E3/16.jpg' },
    //         ],
    //         [
    //             // Column9
    //             { url: 'img/FriendsPlus/Page62/E3/17.jpg' },
    //         ],
    //         [
    //             // Column10
    //             { url: 'img/FriendsPlus/Page62/E3/18.jpg' },
    //             { url: 'img/FriendsPlus/Page62/E3/19.jpg', input: true, answer: "healthy" },
    //             { url: 'img/FriendsPlus/Page62/E3/20.jpg' },
    //         ],
    //         [
    //             // Column11
    //             { url: 'img/FriendsPlus/Page62/E3/21.jpg' },
    //         ],
    //         [
    //             // Column12
    //             { url: 'img/FriendsPlus/Page62/E3/22.jpg' },
    //             { url: 'img/FriendsPlus/Page62/E3/23.jpg', input: true, answer: "afternoon" },
    //             { url: 'img/FriendsPlus/Page62/E3/24.jpg' },
    //         ],
    //         [
    //             // Column13
    //             { url: 'img/FriendsPlus/Page62/E3/25.jpg' },
    //         ],
    //         [
    //             // Column14
    //             { url: 'img/FriendsPlus/Page62/E3/26.jpg' },
    //             { url: 'img/FriendsPlus/Page62/E3/27.jpg', input: true, answer: "is" },
    //             { url: 'img/FriendsPlus/Page62/E3/28.jpg' },
    //         ],



    //     ],
    //     questions: [
    //         {
    //             title: 'The dictionaries are # the shelf',
    //             answer: ['table']
    //         },
    //     ]
    // },

    3: { // Exercise num
        unit : 'Cumulative review Starter unit-Unit 6',
        id : 'WB6-CR3-P62-E3',
        audio: '',
        video: '',
        component: T6,
        inputSize: 170,
        imgSize: '70%',
        exerciseKey: 'img/FriendsPlus/Page62/E3//Key/answerKey.png',
        titleImage: 'img/FriendsPlus/Page62/E3/title.jpg',
        // titleQuestion: [{ num: '4', title: 'Answer the questions. Write complete sentences.', color: '#d72d36', star: 3 }],
        questionImage: [],
        questions: [
          {
            title: "<p> In the competition, there are <span style = 'text-decoration: line-through;'>seven</span> teams. </p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>eight</u></p>" +
              "<p><span style='font-weight:600'>1</span>.  Naomi is quite good at beach volleyball.  #  </p>" +
            //   "<p>#</p>"+
              "<p><span style='font-weight:600'>2</span>.  Jasmine hates getting up early in the morning.  </p>" +
              "<p>#</p>"+
              "<p><span style='font-weight:600'>3</span>.  Jasmine loves swimming because she wants to be fit.     </p>" +
              "<p>#</p>"+
              "<p><span style='font-weight:600'>4</span>.  Beach volleyball is a slow sport.  # </p>" +
              "<p><span style='font-weight:600'>5</span>.   Jasmine had unhealthy food for lunch.  # </p>" +
              "<p><span style='font-weight:600'>6</span>. Jasmine and Naomi played matches in the morning.   </p>" +
              "<p>#</p>"+
              "<p><span style='font-weight:600'>7</span>.  Jasmine isn’t very excited about the competition.    </p>" +
              "<p>#</p>",
    
    
            answer: [
              "really",
              "doesn't mind",
              "cycling",
              "fast",
              "healthy",
              "afternoon",
              "is",
            ],
            type: 'longAnwser'
          },
        ]
      },

    4: { // Exercise num
        unit : 'Cumulative review Starter unit-Unit 6',
        id : 'WB6-CR3-P62-E4',
        audio: 'img/FriendsPlus/Page62/E4/Audio/Friends Plus for Vietnam G6 WB Track 12.mp3',
        video: '',
        inputSize: 650,
        component: T6,
        imgSize: '70%',
        exerciseKey: 'img/FriendsPlus/Page62/E4/Key/answerKey.png',
        titleImage: 'img/FriendsPlus/Page62/E4/title.jpg',
        //titleQuestion: [{ num: '2', title: 'Read the text. What is Hannah’s project about? What is she learning about at school?', color: "#4D3D9B" }],
        questionImage: [],
        questions: [
            {
                title:
                    "<br/>" +
                    "<br/>" +
                    "<br/>" +
                    "<p>#</p>",

                answer: [
                    "The other team were the champions.",

                ],
                type: 'longAnwser'
            },
        ]
    },
    5: { // Exercise num
        unit : 'Cumulative review Starter unit-Unit 6',
        id : 'WB6-CR3-P62-E5',
        audio: 'img/FriendsPlus/Page62/E5/Audio/Friends Plus for Vietnam G6 WB Track 12.mp3',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page62/E5/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page62/E5/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page62/E5/2.jpg' },
                { url: 'img/FriendsPlus/Page62/E5/3.jpg', input: true, answer: "False" },
                { url: 'img/FriendsPlus/Page62/E5/4.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page62/E5/5.jpg' },
                { url: 'img/FriendsPlus/Page62/E5/6.jpg', input: true, answer: "True" },
                { url: 'img/FriendsPlus/Page62/E5/7.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page62/E5/8.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page62/E5/9.jpg' },
                { url: 'img/FriendsPlus/Page62/E5/10.jpg', input: true, answer: "False" },
                { url: 'img/FriendsPlus/Page62/E5/11.jpg' },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page62/E5/12.jpg' },
                { url: 'img/FriendsPlus/Page62/E5/13.jpg', input: true, answer: "True" },
                { url: 'img/FriendsPlus/Page62/E5/14.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page62/E5/15.jpg' },
            ],
            [
                // Column8
                { url: 'img/FriendsPlus/Page62/E5/16.jpg' },
                { url: 'img/FriendsPlus/Page62/E5/17.jpg', input: true, answer: "False" },
                { url: 'img/FriendsPlus/Page62/E5/18.jpg' },
            ],

        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    6: { // Exercise num
        unit : 'Cumulative review Starter unit-Unit 6',
        id : 'WB6-CR3-P62-E6',
        audio: '',
        video: '',
        component: T2,
        exerciseKey: '',
        titleImage: 'img/FriendsPlus/Page62/E6/title.jpg',
        //titleQuestion: [{ num: '4', title: '', color: '#19BED7', star: 3 }],
        questionImage: [],
        questions: [
            {
                title:
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>",
                answer: [],
                type: 'longAnwser'
            },
        ]
    },



}

export default json;