import T5 from '../../components/ExcerciseTypes/TypeIn/T5';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';

const json = {
  1: { // Exercise num
    unit: 'Unit 1',
    id: 'WB6-U1-P14-E1',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page14/E1/Key/Key.png',
    // titleImage: 'img/FriendsPlus/Page14/E1/2.jpg',
    // titleQuestion: [{ num: '1', title: "Find eight more adjectives in the wordsearch. Write the adjectives under positive or negative .", star: 1, color: '#F68025' }],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page14/E1/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page14/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page14/E1/3.jpg', input: true, answer: "dangerous / dirty / noisy / ugly" },
        { url: 'img/FriendsPlus/Page14/E1/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page14/E1/5.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page14/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page14/E1/7.jpg', input: true, answer: "friendly / safe / pretty / quiet" },
        { url: 'img/FriendsPlus/Page14/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page14/E1/9.jpg', input: true, answer: "dangerous / dirty / noisy / ugly" },
        { url: 'img/FriendsPlus/Page14/E1/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page14/E1/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page14/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page14/E1/13.jpg', input: true, answer: "friendly / safe / pretty / quiet" },
        { url: 'img/FriendsPlus/Page14/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page14/E1/15.jpg', input: true, answer: "dangerous / dirty / noisy / ugly" },
        { url: 'img/FriendsPlus/Page14/E1/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page14/E1/17.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page14/E1/18.jpg' },
        { url: 'img/FriendsPlus/Page14/E1/19.jpg', input: true, answer: "friendly / safe / pretty / quiet" },
        { url: 'img/FriendsPlus/Page14/E1/20.jpg' },
        { url: 'img/FriendsPlus/Page14/E1/21.jpg', input: true, answer: "dangerous / dirty / noisy / ugly" },
        { url: 'img/FriendsPlus/Page14/E1/22.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page14/E1/23.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page14/E1/24.jpg' },
        { url: 'img/FriendsPlus/Page14/E1/25.jpg', input: true, answer: "friendly / safe / pretty / quiet" },
        { url: 'img/FriendsPlus/Page14/E1/26.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page14/E1/27.jpg' },
      ],
    ],
    questions: [
      {
        title: 'positive',
        answerInputs: ['clean', 'friendly', 'safe', 'pretty', 'quiet']
      },
      {
        title: 'negative',
        answerInputs: ['dangerous', 'dirty', 'noisy', 'ugly']
      }
    ]
  },
  2: {
    unit: 'Unit 1',
    id: 'WB6-U1-P14-E2',
    audio: 'img/FriendsPlus/Page14/E2/Audio/Friends Plus for Vietnam G6 WB Track 02.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page14/E2/Key/Key.png',
    component: DesignUnderLine,
    totalInput: 4,
    // titleQuestion: [{ num: '2', title: `Listen to the street interviews and tick (✔) the correct box. What is the person’s favourite building?`, star: 2, color: '#F68025' }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page14/E2/02.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page14/E2/03.jpg' },
        { url: 'img/FriendsPlus/Page14/E2/04.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page14/E2/05.jpg' },
        { url: 'img/FriendsPlus/Page14/E2/06.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page14/E2/07.jpg' },
        { url: 'img/FriendsPlus/Page14/E2/08.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page14/E2/09.jpg' },
        { url: 'img/FriendsPlus/Page14/E2/10.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page14/E2/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page14/E2/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page14/E2/13.jpg' },
        { url: 'img/FriendsPlus/Page14/E2/14.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page14/E2/15.jpg' },
        { url: 'img/FriendsPlus/Page14/E2/16.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page14/E2/17.jpg' },
        { url: 'img/FriendsPlus/Page14/E2/18.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page14/E2/19.jpg' },
        { url: 'img/FriendsPlus/Page14/E2/20.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page14/E2/21.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page14/E2/22.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page14/E2/23.jpg' },
        { url: 'img/FriendsPlus/Page14/E2/24.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page14/E2/25.jpg' },
        { url: 'img/FriendsPlus/Page14/E2/26.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page14/E2/27.jpg' },
        { url: 'img/FriendsPlus/Page14/E2/28.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page14/E2/29.jpg' },
        { url: 'img/FriendsPlus/Page14/E2/30.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page14/E2/31.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page14/E2/32.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  3: { // Exercise num
    unit: 'Unit 1',
    id: 'WB6-U1-P14-E3',
    audio: 'img/FriendsPlus/Page14/E2/Audio/Friends Plus for Vietnam G6 WB Track 02.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page14/E3/Key/Key.jpg',
    component: T6,
    imgSize: '70%',
    inputSize: 250,
    titleImage: 'img/FriendsPlus/Page14/E3/title.jpg',
    // titleQuestion: [{ num: '3', title: 'Listen again. Correct the sentences.', color: '#F58024', star: 2 }],
    questionImage: [],
    questions: [
      {
        title: "<p>The Heron Tower building is <span style='font-weight:600'>old</span>.</p>" +
          "<p> <span style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u><b>&nbsp;&nbsp;modern&nbsp;&nbsp;</b></u></span> </p>" +
          "<p><span style='font-weight:600'>1</span> The Heron Tower building is in <span style='font-weight:600'>New York.</span> </p>" +
          "<p>#</p><p><span style='font-weight:600'>2</span> The café is <span style='font-weight:600'>Italian</span>. # </p>" +
          "<p><span style='font-weight:600'>3</span> The café is opposite the <span style='font-weight:600'>station</span>. </p>" +
          "<p># </p><p><span style='font-weight:600'>4</span> York station is <span style='font-weight:600'>modern</span>. # </p>" +
          "<p><span style='font-weight:600'>5</span> The sports centre is <span style='font-weight:600'>opposite</span> the school.</p>" +
          "<p> # </p><p><span style='font-weight:600'>6</span> The new cinema is a very <span style='font-weight:600'>pretty</span> building. </p>" +
          "<p> # </p>",
        answer: [
          "London",
          "French",
          "cinema",
          "old",
          "next to",
          "ugly",
        ],
        type: 'longAnwser'
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 1',
    id: 'WB6-U1-P14-E4',
    audio: '',
    video: '',
    exerciseKey: '',
    component: T5,
    imgSize: '90%',
    titleImage: 'img/FriendsPlus/Page14/E4/title.jpg',
    // titleQuestion: [{ num: '4', title: 'Complete the sentences with be and one of the adjectives from this page.', color: '#F58024', star: 3 }],
    questionImage: [],
    questions: [
      {
        title: "<p>The shopping centre in my town <span style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u><b>&nbsp;&nbsp;is modern.&nbsp;&nbsp;</b></u></span></p>" +
          "<p><span style='font-weight:600'>1</span> My house / flat # </p>" +
          "<p><span style='font-weight:600'>2</span> Our school # </p>" +
          "<p><span style='font-weight:600'>3</span> My area of town # </p>" +
          "<p><span style='font-weight:600'>4</span> The streets near my school # </p>" +
          "<p><span style='font-weight:600'>5</span> The nearest train station # </p>" +
          "<p><span style='font-weight:600'>6</span> The cafés in our area # </p>" +
          "<p><span style='font-weight:600'>7</span> The library in my school # </p>" +
          "<p><span style='font-weight:600'>8</span> The office buildings in our capital # </p>",
        answer: [
        ],
        type: 'longAnwser'
      },
    ]
  },


}

export default json;