import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
    1: { // Exercise num
        unit: 'Unit 4',
        id: 'WB6-U4-P31-E1',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page31/E1/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page31/E1/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page31/E1/2.jpg' },
                { url: 'img/FriendsPlus/Page31/E1/3.jpg', input: true, answer: "is" },
                { url: 'img/FriendsPlus/Page31/E1/4.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page31/E1/5.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page31/E1/6.jpg' },
                { url: 'img/FriendsPlus/Page31/E1/7.jpg', input: true, answer: "are" },
                { url: 'img/FriendsPlus/Page31/E1/8.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page31/E1/9.jpg' },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page31/E1/10.jpg' },
                { url: 'img/FriendsPlus/Page31/E1/11.jpg', input: true, answer: "am not " },
                { url: 'img/FriendsPlus/Page31/E1/12.jpg' },
                { url: 'img/FriendsPlus/Page31/E1/13.jpg', input: true, answer: "running" },
                { url: 'img/FriendsPlus/Page31/E1/14.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page31/E1/15.jpg' },
                { url: 'img/FriendsPlus/Page31/E1/16.jpg', input: true, answer: "isn't" },
                { url: 'img/FriendsPlus/Page31/E1/17.jpg' },
            ],
            [
                // Column8
                { url: 'img/FriendsPlus/Page31/E1/18.jpg' },
            ],
            [
                // Column9
                { url: 'img/FriendsPlus/Page31/E1/19.jpg' },
                { url: 'img/FriendsPlus/Page31/E1/20.jpg', input: true, answer: "aren't" },
                { url: 'img/FriendsPlus/Page31/E1/21.jpg' },
            ],
            [
                // Column10
                { url: 'img/FriendsPlus/Page31/E1/22.jpg' },
            ],



        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    2: { // Exercise num
        unit: 'Unit 4',
        id: 'WB6-U4-P31-E2',
        audio: '',
        video: '',
        imgSize: 850,
        exerciseKey: 'img/FriendsPlus/Page31/E2/Key/answerKey.png',
        component: T6,
        titleImage: 'img/FriendsPlus/Page31/E2/title.png',
        titleQuestion: [{ num: '2', title: 'Match the names and the pictures. Write sentences using the present continuous form of the given verbs.', color: '#41CADC', star: 2 }],
        questionImage: [],
        questions: [
            {
                title:
                    '<p>Pavel <u style="font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray"><b>&nbsp;&nbsp; is playing tennis.&nbsp;&nbsp;</b></u></p>' +

                    "<p><span style='font-weight:600'>1</span> Sarah #</p>" +
                    "<p><span style='font-weight:600'>2</span> Emrah and Murat #</p>" +
                    "<p><span style='font-weight:600'>3</span> Ruby #</p>" +
                    "<p><span style='font-weight:600'>4</span> I #</p>" +
                    "<p><span style='font-weight:600'>5</span> Igor and Vadim #</p>" +
                    "<p><span style='font-weight:600'>6</span> Adam #</p>" +
                    "<p><span style='font-weight:600'>7</span> Mia and Poppy #</p>" +
                    "<p><span style='font-weight:600'>8</span> Minh #</p>",
                answer: [
                    "is watching TV.",
                    "are running in the park.",
                    "is dancing.",
                    "'m singing a song.",
                    "are having lunch.",
                    "is sitting on the bus.",
                    "are making clothes.",
                    "is doing homework.",

                ],
                type: 'longAnwser'
            },
        ]
    },
    3: { // Exercise num
        unit: 'Unit 4',
        id: 'WB6-U4-P31-E3',
        audio: '',
        video: '',
        component: T1,
        exerciseKey: 'img/FriendsPlus/Page31/E3/Key/answerKey.png',
        titleImage: 'img/FriendsPlus/Page31/E3/title.png',
        titleQuestion: [{ num: '3', title: 'Write affirmative and negative sentences using the present continuous.', color: "#1ABED7", star: 2 }],
        questionImage: [],
        questions: [
            {
                title: "<p>Amy and Clare / not study / chat</p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>Amy and Clare aren’t studying. They’re chatting.</u></p>" +
                    "<p><span style='font-weight:600'>1</span>. Ahmed / ask a question / not read # </p>" +
                    "<p><span style='font-weight:600'>2</span>. I / not listen / play a game with Richard # </p>" +
                    "<p><span style='font-weight:600'>3</span>. you / talk to the teacher / not sit down # </p>" +
                    "<p><span style='font-weight:600'>4</span>. Mary and Vicky / not answer the questions / help a new student # </p>" +
                    "<p><span style='font-weight:600'>5</span>. Harry / work on the computer / not write in his notebook # </p>" +
                    "<p><span style='font-weight:600'>6</span>. we / not speak English / look in the dictionary # </p>",


                answer: [
                    "Ahmed is asking a question. He isn't reading.",
                    "I'm not listening. I'm playing a game with Richard.",
                    "You're talking to the teacher. You aren't sitting down.",
                    "Mary and Vicky aren't answering the questions. They're helping a new student.",
                    "Harry is working on the computer. He isn't writing in his notebook.",
                    "We aren't speaking English. We're looking in the dictionary.",

                ],
                type: 'longAnwser'
            },
        ]
    },
    4: { // Exercise num
        unit: 'Unit 4',
        id: 'WB6-U4-P31-E4',
        audio: '',
        video: '',
        component: T2,
        exerciseKey: '',
        titleImage: 'img/FriendsPlus/Page31/E4/title.png',
        titleQuestion: [{ num: '4', title: 'Where are the people? What do you think they are doing? Write sentences using the present continuous.', color: '#19BED7', star: 3 }],
        questionImage: [],
        questions: [
            {
                title: "<p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>My sister is in the library. She’s reading a new book. She isn't talking to her friends.</u></p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>",
                answer: [],
                type: 'longAnwser'
            },
        ]
    },



}

export default json;