import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import MC2 from '../../components/ExcerciseTypes/MultipleChoice/MC2';

const json = {
  1: { // Exercise num
     unit : 'Unit 6',
    id : 'WB6-U6-P44-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page44/E1/Key/Key.png',
    imgSize: '70%',
    inputSize: 170,
    titleImage: 'img/FriendsPlus/Page44/E1/title.jpg',
    // titleQuestion: [{ num: '4', title: 'Imagine you take a special lunch to school every day. What is in your perfect lunchbox? ', color: "#036ab8", star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<u style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px;'>learned </u>&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160; 3 # 6 #</p>" +
          "<p><span style='font-weight:600'></span>1 # 4 # 7 # </p>" +
          "<p><span style='font-weight:600'></span>2 # 5 # 8 # </p>",
        answer: [
          "did",
          "travelled",
          "became",
          "went",
          "watched",
          "decided",
          "started",
          "won",
        ],
        type: 'longAnwser'
      },
    ]
  },

  2: { // Exercise num
     unit : 'Unit 6',
    id : 'WB6-U6-P44-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page44/E2/Key/Key.png',
    component: D1,
    fontSize: 25,
    titleImage: 'http://',
    // titleQuestion: [{ num: '1', title: 'Write the comparative forms of the given adjectives', color: "#5DC8DA", star: 1 }],
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page44/E2/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page44/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page44/E2/3.jpg', input: true, answer: "did" },
        { url: 'img/FriendsPlus/Page44/E2/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page44/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page44/E2/6.jpg', input: true, answer: "watched" },
        { url: 'img/FriendsPlus/Page44/E2/7.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page44/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page44/E2/9.jpg', input: true, answer: "learned" },
        { url: 'img/FriendsPlus/Page44/E2/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page44/E2/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page44/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page44/E2/13.jpg', input: true, answer: "became" },
        { url: 'img/FriendsPlus/Page44/E2/14.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page44/E2/15.jpg' },
        { url: 'img/FriendsPlus/Page44/E2/16.jpg', input: true, answer: "started" },
        { url: 'img/FriendsPlus/Page44/E2/17.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page44/E2/18.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page44/E2/19.jpg' },
        { url: 'img/FriendsPlus/Page44/E2/20.jpg', input: true, answer: "travelled" },
        { url: 'img/FriendsPlus/Page44/E2/21.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page44/E2/22.jpg' },
        { url: 'img/FriendsPlus/Page44/E2/23.jpg', input: true, answer: "competed" },
        { url: 'img/FriendsPlus/Page44/E2/24.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page44/E2/25.jpg' },
        { url: 'img/FriendsPlus/Page44/E2/26.jpg', input: true, answer: "won" },
        { url: 'img/FriendsPlus/Page44/E2/27.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page44/E2/28.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  3: { // Exercise num
     unit : 'Unit 6',
    id : 'WB6-U6-P44-E3',
    audio: 'img/FriendsPlus/Page44/E3/Audio/Friends Plus for Vietnam G6 WB Track 07.mp3',
    video: '',
    component: MC2,
    exerciseKey: 'img/FriendsPlus/Page44/E3/Key/answerKey.png',
    // titleQuestion: [{ num: '2', title: 'Choose the correct answers.', color: '#3B3074' }],
    titleImage: 'img/FriendsPlus/Page44/E3/title.jpg',
    marginLeft: 140,
    widthButton: '',
    questionImage: [ // Row
      [
        // Column
        { url: 'http://' },
        { url: 'http://', input: true },
        { url: 'http://' },
      ],
    ],
    questions: [
      {
        title: 'Ryan Jones is one of the ....... competitors.',
        answers: [
          { image: "", isCorrect: false, right: "", text: "smallest" },
          { image: "", isCorrect: false, right: "", text: "most successful" },
          { image: "", isCorrect: true, right: "", text: "youngest" },
        ],
        no: 1,
        question: '',
        type: '',
      },
      {
        title: 'Ryan learned to ski when he was ....... years old.',
        answers: [
          { image: "", isCorrect: true, right: "", text: "four" },
          { image: "", isCorrect: false, right: "", text: "five" },
          { image: "", isCorrect: false, right: "", text: "six" },
        ],
        no: 2,
        question: '',
        type: '',
      },
      {
        title: 'Ryan went skiing with his ....... when he was younger.',
        answers: [
          { image: "", isCorrect: false, right: "", text: "family friends" },
          { image: "", isCorrect: true, right: "", text: "parents" },
          { image: "", isCorrect: false, right: "", text: "grandparents" },
        ],
        no: 3,
        question: '',
        type: '',
      },
      {
        title: 'At first, Ryan’s parents were ....... about the ski jumping lessons.',
        answers: [
          { image: "", isCorrect: false, right: "", text: "excited" },
          { image: "", isCorrect: false, right: "", text: "happy" },
          { image: "", isCorrect: true, right: "", text: "unhappy" },
        ],
        no: 4,
        question: '',
        type: '',
      },
      {
        title: `Ryan did ....... jumps at first.`,
        answers: [
          { image: "", isCorrect: true, right: "", text: "small" },
          { image: "", isCorrect: false, right: "", text: "bad" },
          { image: "", isCorrect: false, right: "", text: "dangerous" },
        ],
        no: 5,
        question: '',
        type: '',
      },
      {
        title: `Last year, Ryan won a ........ medal.`,
        answers: [
          { image: "", isCorrect: false, right: "", text: "gold" },
          { image: "", isCorrect: false, right: "", text: "silver" },
          { image: "", isCorrect: true, right: "", text: "bronze" },
        ],
        no: 6,
        question: '',
        type: '',
      },
    ]
  },

  4: { // Exercise num
     unit : 'Unit 6',
    id : 'WB6-U6-P44-E4',
    audio: 'img/FriendsPlus/Page44/E4/Audio/Friends Plus for Vietnam G6 WB Track 07.mp3',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page44/E4/Key/Key.png',
    imgSize: '65%',
    inputSize: 180,
    titleImage: 'img/FriendsPlus/Page44/E4/title.jpg',
    // titleQuestion: [{ num: '4', title: 'Imagine you take a special lunch to school every day. What is in your perfect lunchbox? ', color: "#036ab8", star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>Ryan is &#160;&#160;<u style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px;'> seventeen </u>&#160;&#160; years old. </p>" +
          "<p><span style='font-weight:600'>1 </span>Ryan is from the # .</p>" +
          "<p><span style='font-weight:600'>2 </span>Ryan went skiing every # when </p>" +
          "<p>he was younger.</p>" +
          "<p><span style='font-weight:600'>3 </span>Ryan watched ski jumping on TV when he was </p>" +
          "<p># years old.</p>" +
          "<p><span style='font-weight:600'>4 </span>At first, Ryan did jumps of  # metres.</p>" +
          "<p><span style='font-weight:600'>5 </span>Ryan went to a competition in # </p>" +
          "<p>when he was sixteen.</p>" +
          "<p><span style='font-weight:600'>6 </span>The Winter Olympics Special TV programme is at </p>" +
          "<p># every day.</p>",
        answer: [
          "USA",
          "weekend",
          "nine",
          "three or four ",
          "Canada",
          "2 p.m",
        ],
        type: 'longAnwser'
      },
    ]
  },

  5: { // Exercise num
     unit : 'Unit 6',
    id : 'WB6-U6-P44-E5',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page44/E5/Key/Key.png',
    imgSize: '70%',
    inputSize: 700,
    titleImage: 'img/FriendsPlus/Page44/E5/title.jpg',
    // titleQuestion: [{ num: '4', title: 'Imagine you take a special lunch to school every day. What is in your perfect lunchbox? ', color: "#036ab8", star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>Last week ... </p>" +
          "<p><u style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px;'>Tom did his homework. </u></p>" +
          "<p><span style='font-weight:600'></span>#</p>" +
          "<p><span style='font-weight:600'></span>#</p>" +
          "<p><span style='font-weight:600'></span>#</p>" +
          "<p><span style='font-weight:600'></span>#</p>" +
          "<p><span style='font-weight:600'></span>#</p>" +
          "<p><span style='font-weight:600'></span>#</p>",
        answer: [
          "Tom went to the theatre. / Tom learned some French verbs. / Tom competed in a football competition. / Tom started tennis lessons. / Tom won a medal. / Tom watched a football match on TV.",
          "Tom went to the theatre. / Tom learned some French verbs. / Tom competed in a football competition. / Tom started tennis lessons. / Tom won a medal. / Tom watched a football match on TV.",
          "Tom went to the theatre. / Tom learned some French verbs. / Tom competed in a football competition. / Tom started tennis lessons. / Tom won a medal. / Tom watched a football match on TV.",
          "Tom went to the theatre. / Tom learned some French verbs. / Tom competed in a football competition. / Tom started tennis lessons. / Tom won a medal. / Tom watched a football match on TV.",
          "Tom went to the theatre. / Tom learned some French verbs. / Tom competed in a football competition. / Tom started tennis lessons. / Tom won a medal. / Tom watched a football match on TV.",
          "Tom went to the theatre. / Tom learned some French verbs. / Tom competed in a football competition. / Tom started tennis lessons. / Tom won a medal. / Tom watched a football match on TV.",
        ],
        type: 'longAnwser'
      },
    ]
  },

}
export default json