
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';

const json = {
    1: { // Exercise num
        unit : 'Unit 3',
        id : 'WB6-U3-P27-E1',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page27/E1/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page27/E1/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page27/E1/2.jpg' },
                { url: 'img/FriendsPlus/Page27/E1/3.jpg', input: true, answer: "can't" },
                { url: 'img/FriendsPlus/Page27/E1/4.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page27/E1/5.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page27/E1/6.jpg' },
                { url: 'img/FriendsPlus/Page27/E1/7.jpg', input: true, answer: "Can" },
                { url: 'img/FriendsPlus/Page27/E1/8.jpg' },
                { url: 'img/FriendsPlus/Page27/E1/9.jpg', input: true, answer: "swim" },
                { url: 'img/FriendsPlus/Page27/E1/10.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page27/E1/11.jpg' },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page27/E1/12.jpg' },
                { url: 'img/FriendsPlus/Page27/E1/13.jpg', input: true, answer: "can " },
                { url: 'img/FriendsPlus/Page27/E1/14.jpg' },
                { url: 'img/FriendsPlus/Page27/E1/15.jpg', input: true, answer: "can't" },
                { url: 'img/FriendsPlus/Page27/E1/16.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page27/E1/17.jpg' },
            ],



        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    2: { // Exercise num
        unit : 'Unit 3',
        id : 'WB6-U3-P27-E2',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page27/E2/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page27/E2/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page27/E2/2.jpg' },
                { url: 'img/FriendsPlus/Page27/E2/3.jpg', input: true, answer: "can make" },
                { url: 'img/FriendsPlus/Page27/E2/4.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page27/E2/5.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page27/E2/6.jpg' },
                { url: 'img/FriendsPlus/Page27/E2/7.jpg', input: true, answer: "can't swim" },
                { url: 'img/FriendsPlus/Page27/E2/8.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page27/E2/9.jpg' },
                { url: 'img/FriendsPlus/Page27/E2/10.jpg', input: true, answer: "can dance" },
                { url: 'img/FriendsPlus/Page27/E2/11.jpg' },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page27/E2/12.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page27/E2/13.jpg' },
                { url: 'img/FriendsPlus/Page27/E2/14.jpg', input: true, answer: "can't fly" },
                { url: 'img/FriendsPlus/Page27/E2/15.jpg' },
            ],
            [
                // Column8
                { url: 'img/FriendsPlus/Page27/E2/16.jpg' },
                { url: 'img/FriendsPlus/Page27/E2/17.jpg', input: true, answer: "can play" },
                { url: 'img/FriendsPlus/Page27/E2/18.jpg' },
            ],
            [
                // Column9
                { url: 'img/FriendsPlus/Page27/E2/19.jpg' },
            ],
            [
                // Column10
                { url: 'img/FriendsPlus/Page27/E2/20.jpg' },
                { url: 'img/FriendsPlus/Page27/E2/21.jpg', input: true, answer: "can't speak" },
                { url: 'img/FriendsPlus/Page27/E2/22.jpg' },
            ],
            [
                // Column11
                { url: 'img/FriendsPlus/Page27/E2/23.jpg' },
                { url: 'img/FriendsPlus/Page27/E2/24.jpg', input: true, answer: "can run" },
                { url: 'img/FriendsPlus/Page27/E2/25.jpg' },
            ],


        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    3: { // Exercise num
        unit : 'Unit 3',
        id : 'WB6-U3-P27-E3',
        audio: '',
        video: '',
        component: T1,
        exerciseKey: 'img/FriendsPlus/Page27/E3/Key/answerKey.png',
        titleImage: '',
        titleQuestion: [{ num: '3', title: 'Write sentences with might.', color: "#1ABED7", star: 2 }],
        questionImage: [],
        questions: [
            {
                title: "<p>you / hear / a parrot / in the jungle</p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>You might hear a parrot in the jungle.</u></p>" +
                    "<p><span style='font-weight:600'>1</span>. it / be / cold / in Italy / in November# </p>" +
                    "<p><span style='font-weight:600'>2</span>. that bear / be / dangerous# </p>" +
                    "<p><span style='font-weight:600'>3</span>. you / see / a snake / in the mountains# </p>" +
                    "<p><span style='font-weight:600'>4</span>. an elephant / live / for sixty years# </p>" +
                    "<p><span style='font-weight:600'>5</span>. that fish / be / a shark# </p>",

                answer: [
                    "It might be cold in Italy in November.",
                    "That bear might be dangerous.",
                    "You might see a snake in the mountains.",
                    "An elephant might live for sixty years.",
                    "That fish might be a shark.",
                ],
                type: 'longAnwser'
            },
        ]
    },
    4: { // Exercise num
        unit : 'Unit 3',
        id : 'WB6-U3-P27-E4',
        audio: '',
        video: '',
        imgSize: 850,
        component: T1,
        exerciseKey: 'img/FriendsPlus/Page27/E4/Key/answerKey.png',
        titleImage: 'img/FriendsPlus/Page27/E4/title.png',
        titleQuestion: [{ num: '4', title: 'Order the words to make questions. Then match the answers.', color: "#1ABED7", star: 2 }],
        questionImage: [],
        questions: [
            {
                title: "<p>old / how / Eiffel Tower / the / is / ?</p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>How old is the Eiffel Tower? About 130 years old.</u></p>" +
                    "<p><span style='font-weight:600'>1</span>. fast / how / elephant / run / an / can / ?# </p>" +
                    "<p><span style='font-weight:600'>2</span>. cold / the North Pole / is / how / ?# </p>" +
                    "<p><span style='font-weight:600'>3</span>. heavy / how / panda / is / a / ?# </p>" +
                    "<p><span style='font-weight:600'>4</span>. many / people / how / there / are / in / the UK / ?# </p>" +
                    "<p><span style='font-weight:600'>5</span>. can / tall / how / ostrich / grow / an / ?# </p>",

                answer: [
                    "How fast can an elephant run? 40 km an hour",
                    "How cold is the North Pole? -34°C/How cold is the North Pole? -34C",
                    "How heavy is a panda? 100 kg",
                    "How many people are there in the UK? 65 million",
                    "How tall can an ostrich grow? 2.7 metres",
                ],
                type: 'longAnwser'
            },
        ]
    },
    5: { // Exercise num
        unit : 'Unit 3',
        id : 'WB6-U3-P27-E5',
        audio: '',
        video: '',
        component: T2,
        exerciseKey: '',
        titleImage: '',
        titleQuestion: [{ num: '5', title: 'Write sentences about your friends and family with can and can’t.', color: '#19BED7', star: 3 }],
        questionImage: [],
        questions: [
            {
                title: "<p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>My mum can speak German, but she can’t speak English.</u></p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>",
                answer: [],
                type: 'longAnwser'
            },
        ]
    },



}

export default json;