import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';

const json = {

  1: {
    id: 'WB6-U3-P28-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page28/E1/Key/answerKey.png',
    component: DesignUnderLine,
    totalInput: 1,
    // titleQuestion: [{ num: '1', title: `Listen to the street interviews and tick (✔) the correct box. What is the person’s favourite building?`, star: 2, color: '#F68025' }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page28/E1/02.jpg' },
      ],
      [

        { url: 'img/FriendsPlus/Page28/E1/04.jpg' },
        { url: 'img/FriendsPlus/Page28/E1/05.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page28/E1/06.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page28/E1/07.jpg' },
        { url: 'img/FriendsPlus/Page28/E1/08.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page28/E1/09.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page28/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page28/E1/11.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page28/E1/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page28/E1/13.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },


  // 2: { // Exercise num
  unit: 'Unit 3',
  //   audio: '',
  //   video: '',
  //   exerciseKey: 'img/FriendsPlus/Page28/E2/Key/Key.png',
  //   component: T6,
  //   imgSize: 800,
  //   inputSize: 100,
  //   titleImage: 'img/FriendsPlus/Page28/E2/Key/title.jpg',
  //   // titleQuestion: [{ num: '2', title: 'Read the text again. Match sentences 1–4 with paragraphs A–E.', color: '#db2d37', star: 2 }],
  //   questionImage: [],
  //   questions: [
  //     {
  //       title:
  //         "<p>A long journey high in the sky.&#160;&#160;&#160;&#160;&#160;<span style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px;'><u> C </u></span></p>" +
  //         "<p><span style='font-weight:600'>1</span> See the monarchs at the cinema. # </p>" +
  //         "<p><span style='font-weight:600'>2 </span>A very long journey for a butterfy. # </p>" +
  //         "<p><span style='font-weight:600'>3 </span>Lots of beautiful butterflies! # </p>" +
  //         "<p><span style='font-weight:600'>4 </span>No butterfly is safe on this difficult journey. # </p>",
  //       answer: [
  //         "E",
  //         "B",
  //         "A",
  //         "D",
  //       ],
  //       type: 'longAnwser'
  //     },
  //   ]
  // },
  2: { // Exercise num
    unit: 'Unit 3',
    id: 'WB6-U3-P28-E2',
    audio: '',
    video: '',
    component: D1,
    // isallowSubmit: 1,
    exerciseKey: 'img/FriendsPlus/Page28/E2/Key/Key.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page28/E2/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page28/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page28/E2/3.jpg', input: true, answer: "E" },
        { url: 'img/FriendsPlus/Page28/E2/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page28/E2/5.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page28/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page28/E2/7.jpg', input: true, answer: "B" },
        { url: 'img/FriendsPlus/Page28/E2/8.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page28/E2/9.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page28/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page28/E2/11.jpg', input: true, answer: "A" },
        { url: 'img/FriendsPlus/Page28/E2/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page28/E2/13.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page28/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page28/E2/15.jpg', input: true, answer: "D" },
        { url: 'img/FriendsPlus/Page28/E2/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page28/E2/17.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 3',
    id: 'WB6-U3-P28-E3',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page28/E3/Key/Key.png',
    component: T6,
    inputSize: 120,
    // titleImage: 'img/FriendsPlus/Page28/E2/title.jpg',
    titleQuestion: [{ num: '3', title: 'Read the text again. Correct the information in the sentences.', color: '#db2d37', star: 2 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>The butterflies have got <span style='text-decoration:line-through;'> very small </span> wings. <span style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px;'><u> enormous </u></span></p>" +
          "<p><span style='font-weight:600'>1 </span> The butterflies fly from South America and Canada to Mexico. # </p>" +
          "<p><span style='font-weight:600'>2 </span>The monarchs make the fastest journey of all the butterflies in the world. # </p>" +
          "<p><span style='font-weight:600'>3 </span> In one hour, the monarchs can go more than 100 kilometres.  # </p>" +
          "<p><span style='font-weight:600'>4 </span>When it is very hot, a lot of the butterflies die.  # </p>" +
          "<p><span style='font-weight:600'>5 </span>You can’t find a lot of information about monarch butterflies. # </p>",
        answer: [
          "North",
          "longest",
          "day",
          "cold",
          "can",
        ],
        type: 'longAnwser'
      },
    ]
  },

  4: { // Exercise num
    unit: 'Unit 3',
    id: 'WB6-U3-P28-E4',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page28/E4/Key/Key.png',
    component: T1,
    // titleImage: 'img/FriendsPlus/Page28/E2/title.jpg',
    titleQuestion: [{ num: '4', title: 'Answer the questions. Write complete sentences.', color: '#db2d37', star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>How many butterflies are there in the sky?</p>" +
          "<p><span style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px;'><u> There are thousands and thousands. </u></span></p>" +
          "<p><span style='font-weight:600'>1 </span>What colour are the butterflies? # </p>" +
          "<p><span style='font-weight:600'>2 </span>How far is their journey? # </p>" +
          "<p><span style='font-weight:600'>3 </span>When do the butterflies start their journey? # </p>" +
          "<p><span style='font-weight:600'>4 </span>How high in the sky do the butterflies sometimes go?  # </p>" +
          "<p><span style='font-weight:600'>5 </span>Do you like the sound of the film Flight of the Butterflies? Why / Why not?  # # </p>",
        answer: [
          "The butterflies are orange and black.",
          "Their journey is more than 4,500 kilometres.",
          "They usually start their journey in September or October.",
          "They sometimes fly at 1.6 kilometres in the sky.",
          "",
          "",
        ],
        type: 'longAnwser'
      },
    ]
  },

  5: { // Exercise num
    unit: 'Unit 3',
    id: 'WB6-U3-P28-E5',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page28/E5/Key/Key.png',
    component: T6,
    imgSize: 800,
    inputSize: 150,
    titleImage: 'img/FriendsPlus/Page28/E5/title.jpg',
    // titleQuestion: [{ num: '4', title: 'Answer the questions. Write complete sentences.', color: '#db2d37', star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>‘Those small birds are very pretty.’</p>" +
          "<p>‘Yes. They’re really<span style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px;'><u> beautiful </u></span>.’</p>" +
          "<p><span style='font-weight:600'>1 </span>‘My favourite type of butterfly is red, green and yellow.’</p>" +
          "<p> ‘It's very # .’ ‘Yes.’</p>" +
          "<p><span style='font-weight:600'>2 </span>‘How many of these animals are there in the world?’ </p>" +
          "<p>‘Only twenty! They’re very # .’</p>" +
          "<p><span style='font-weight:600'>3 </span>‘What colour are wild rabbits?’ ‘They are #</p>" +
          "<p>brown. They aren’t very colourful animals.’</p>" +
          "<p><span style='font-weight:600'>4 </span>‘Are there a lot of bats in your area?’ ‘No, they </p>" +
          "<p>aren’t very # .’</p>",
        answer: [
          "colourful",
          "rare",
          "dull",
          "common",
        ],
        type: 'longAnwser'
      },
    ]
  },

}

export default json;