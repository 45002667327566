
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
    1: { // Exercise num
        unit: 'Language Focus Practice Unit 2',
        id: 'WB6-LFP2-P67-E1',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page67/E1/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page67/E1/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page67/E1/2.jpg' },
                { url: 'img/FriendsPlus/Page67/E1/3.jpg', input: true, answer: "chat" },
                { url: 'img/FriendsPlus/Page67/E1/4.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page67/E1/5.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page67/E1/6.jpg' },
                { url: 'img/FriendsPlus/Page67/E1/7.jpg', input: true, answer: "studies" },
                { url: 'img/FriendsPlus/Page67/E1/8.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page67/E1/9.jpg' },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page67/E1/10.jpg' },
                { url: 'img/FriendsPlus/Page67/E1/11.jpg', input: true, answer: "play" },
                { url: 'img/FriendsPlus/Page67/E1/12.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page67/E1/13.jpg' },
                { url: 'img/FriendsPlus/Page67/E1/14.jpg', input: true, answer: "goes" },
                { url: 'img/FriendsPlus/Page67/E1/15.jpg' },
            ],
            [
                // Column8
                { url: 'img/FriendsPlus/Page67/E1/16.jpg' },
                { url: 'img/FriendsPlus/Page67/E1/17.jpg', input: true, answer: "love" },
                { url: 'img/FriendsPlus/Page67/E1/18.jpg' },
            ],
            [
                // Column9
                { url: 'img/FriendsPlus/Page67/E1/19.jpg' },
            ],
            [
                // Column10
                { url: 'img/FriendsPlus/Page67/E1/20.jpg' },
                { url: 'img/FriendsPlus/Page67/E1/21.jpg', input: true, answer: "eats" },
                { url: 'img/FriendsPlus/Page67/E1/22.jpg' },
            ],



        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    2: { // Exercise num
        unit: 'Language Focus Practice Unit 2',
        id: 'WB6-LFP2-P67-E2',
        audio: '',
        video: '',
        component: T1,
        exerciseKey: 'img/FriendsPlus/Page67/E2/Key/answerKey.png',
        titleImage: 'img/FriendsPlus/Page67/E2/title.jpg',
        //titleQuestion: [{ num: '2', title: 'Read the text. What is Hannah’s project about? What is she learning about at school?', color: "#4D3D9B" }],
        questionImage: [],
        questions: [
            {
                title: "<p>Richard watches TV a lot.</p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>Richard doesn’t watch TV a lot.</u></p>" +

                    "<p><span style='font-weight:600'>1</span>   My grandfather gets up early. # </p>" +
                    "<p><span style='font-weight:600'>2</span>   I do my homework at school. # </p>" +
                    "<p><span style='font-weight:600'>3</span>   They help with the housework. # </p>" +
                    "<p><span style='font-weight:600'>4</span>   We go to school on foot. # </p>" +
                    "<p><span style='font-weight:600'>5</span>   You like the new shopping centre. # </p>",

                answer: [
                    "My grandfather doesn't get up early.",
                    "I don't do my homework at school.",
                    "They don't help with the housework.",
                    "We don't go to school on foot.",
                    "You don't like the new shopping centre.",

                ],
                type: 'longAnwser'
            },
        ]
    },
    3: { // Exercise num
        unit: 'Language Focus Practice Unit 2',
        id: 'WB6-LFP2-P67-E3',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page67/E3/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page67/E3/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page67/E3/2.jpg' },
                { url: 'img/FriendsPlus/Page67/E3/3.jpg', input: true, answer: "do" },
                { url: 'img/FriendsPlus/Page67/E3/4.jpg' },
                { url: 'img/FriendsPlus/Page67/E3/5.jpg', input: true, answer: "get up" },
                { url: 'img/FriendsPlus/Page67/E3/6.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page67/E3/7.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page67/E3/8.jpg' },
                { url: 'img/FriendsPlus/Page67/E3/9.jpg', input: true, answer: "does" },
                { url: 'img/FriendsPlus/Page67/E3/10.jpg' },
                { url: 'img/FriendsPlus/Page67/E3/11.jpg', input: true, answer: "work" },
                { url: 'img/FriendsPlus/Page67/E3/12.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page67/E3/13.jpg' },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page67/E3/14.jpg' },
                { url: 'img/FriendsPlus/Page67/E3/15.jpg', input: true, answer: "do" },
                { url: 'img/FriendsPlus/Page67/E3/16.jpg' },
                { url: 'img/FriendsPlus/Page67/E3/17.jpg', input: true, answer: "come" },
                { url: 'img/FriendsPlus/Page67/E3/18.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page67/E3/19.jpg' },
            ],
            [
                // Column8
                { url: 'img/FriendsPlus/Page67/E3/20.jpg' },
                { url: 'img/FriendsPlus/Page67/E3/21.jpg', input: true, answer: "do" },
                { url: 'img/FriendsPlus/Page67/E3/22.jpg' },
                { url: 'img/FriendsPlus/Page67/E3/23.jpg', input: true, answer: "read" },
                { url: 'img/FriendsPlus/Page67/E3/24.jpg' },
            ],
            [
                // Column9
                { url: 'img/FriendsPlus/Page67/E3/25.jpg' },
            ],
            [
                // Column10
                { url: 'img/FriendsPlus/Page67/E3/26.jpg' },
                { url: 'img/FriendsPlus/Page67/E3/27.jpg', input: true, answer: "does" },
                { url: 'img/FriendsPlus/Page67/E3/28.jpg' },
                { url: 'img/FriendsPlus/Page67/E3/29.jpg', input: true, answer: "go" },
                { url: 'img/FriendsPlus/Page67/E3/30.jpg' },
            ],
            [
                // Column11
                { url: 'img/FriendsPlus/Page67/E3/31.jpg' },
            ],



        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    4: { // Exercise num
        unit: 'Language Focus Practice Unit 2',
        id: 'WB6-LFP2-P67-E4',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page67/E4/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page67/E4/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page67/E4/2.jpg' },
                { url: 'img/FriendsPlus/Page67/E4/3.jpg', input: true, answer: "Does your mum work" },
                { url: 'img/FriendsPlus/Page67/E4/4.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page67/E4/5.jpg' },
                { url: 'img/FriendsPlus/Page67/E4/6.jpg', input: true, answer: "Yes, she does. / No, she doesn't." },
                { url: 'img/FriendsPlus/Page67/E4/7.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page67/E4/8.jpg' },
                { url: 'img/FriendsPlus/Page67/E4/9.jpg', input: true, answer: "Do your friends play football" },
                { url: 'img/FriendsPlus/Page67/E4/10.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page67/E4/11.jpg' },
                { url: 'img/FriendsPlus/Page67/E4/12.jpg', input: true, answer: "Yes, they do./ No, they don't." },
                { url: 'img/FriendsPlus/Page67/E4/13.jpg' },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page67/E4/14.jpg' },
                { url: 'img/FriendsPlus/Page67/E4/15.jpg', input: true, answer: "Does your best friend study" },
                { url: 'img/FriendsPlus/Page67/E4/16.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page67/E4/17.jpg' },
                { url: 'img/FriendsPlus/Page67/E4/18.jpg', input: true, answer: "Yes he does./Yes she does./No, he doesn't./No, she doesn't." },
                { url: 'img/FriendsPlus/Page67/E4/19.jpg' },
            ],
            [
                // Column8
                { url: 'img/FriendsPlus/Page67/E4/20.jpg' },
                { url: 'img/FriendsPlus/Page67/E4/21.jpg', input: true, answer: "Do you go" },
                { url: 'img/FriendsPlus/Page67/E4/22.jpg' },
            ],
            [
                // Column9
                { url: 'img/FriendsPlus/Page67/E4/23.jpg' },
                { url: 'img/FriendsPlus/Page67/E4/24.jpg', input: true, answer: "Yes, I do. / No, I don't." },
                { url: 'img/FriendsPlus/Page67/E4/25.jpg' },
            ],
            [
                // Column10
                { url: 'img/FriendsPlus/Page67/E4/26.jpg' },
                { url: 'img/FriendsPlus/Page67/E4/27.jpg', input: true, answer: "Do you live" },
                { url: 'img/FriendsPlus/Page67/E4/28.jpg' },
            ],
            [
                // Column11
                { url: 'img/FriendsPlus/Page67/E4/29.jpg' },
                { url: 'img/FriendsPlus/Page67/E4/30.jpg', input: true, answer: "Yes, I do. / No, I don't." },
                { url: 'img/FriendsPlus/Page67/E4/31.jpg' },
            ],
            [
                // Column12
                { url: 'img/FriendsPlus/Page67/E4/32.jpg' },
                { url: 'img/FriendsPlus/Page67/E4/33.jpg', input: true, answer: "Does your dad have" },
                { url: 'img/FriendsPlus/Page67/E4/34.jpg' },
            ],
            [
                // Column13
                { url: 'img/FriendsPlus/Page67/E4/35.jpg' },
                { url: 'img/FriendsPlus/Page67/E4/36.jpg', input: true, answer: "Yes, he does / No, he doesn't." },
                { url: 'img/FriendsPlus/Page67/E4/37.jpg' },
            ],


        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    5: { // Exercise num
        unit: 'Language Focus Practice Unit 2',
        id: 'WB6-LFP2-P67-E5',
        audio: '',
        video: '',
        component: T1,
        exerciseKey: 'img/FriendsPlus/Page67/E5/Key/answerKey.png',
        titleImage: 'img/FriendsPlus/Page67/E5/title.jpg',
        //titleQuestion: [{ num: '2', title: 'Read the text. What is Hannah’s project about? What is she learning about at school?', color: "#4D3D9B" }],
        questionImage: [],
        questions: [
            {
                title: "<p>We get up at 7 p.m. ●●●●●</p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>We always get up at 7 p.m.</u></p>" +

                    "<p><span style='font-weight:600'>1</span>   You help at home. ●●●● # </p>" +
                    "<p><span style='font-weight:600'>2</span>   I play computer games. ●● # </p>" +
                    "<p><span style='font-weight:600'>3</span>   This street is dirty. ●●●●● # </p>" +
                    "<p><span style='font-weight:600'>4</span>   She cleans the car. ●●● # </p>" +
                    "<p><span style='font-weight:600'>5</span>   The people are friendly. ●● # </p>" +
                    "<p><span style='font-weight:600'>6</span>   Freddie walks to school. ○○○○○ # </p>" +
                    "<p><span style='font-weight:600'>7</span>   My mum goes to the cinema. ●○○○○ # </p>",

                answer: [
                    "You normally help at home./You usually help at home.",
                    "I sometimes play computer games.",
                    "This street is always dirty.",
                    "She often cleans the car.",
                    "The people are sometimes friendly.",
                    "Freddie never walks to school.",
                    "My mum rarely goes to the cinema.",


                ],
                type: 'longAnwser'
            },
        ]
    },



}

export default json;