import LT1 from '../../components/ExcerciseTypes/LineTo/LT1';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import Wordbox from '../../components/ExcerciseTypes/Design/WordBox2';

const json = {
  1: { // Exercise num
    unit: 'Unit 7',
    id: 'WB6-U7-P48-E1',
    audio: '',
    video: '',
    component: LT1,
    exerciseKey: 'img/FriendsPlus/Page48/E1/Key/answerKey.png',
    titleImage: 'img/FriendsPlus/Page48/E1/1.jpg',
    // titleQuestion: [{ num: '1', title: 'Match sentence halves 1-7 with a-g.' }],
    questionImage: [],
    questions: [
      {
        right: [
          {
            id: 1,
            text: "1. Your face isn't round, it's",
          },
          {
            id: 2,
            text: "2. Jack's eyes aren't brown, they're",
          },
          {
            id: 3,
            text: "3. That singer isn't short, she's",
          },
          {
            id: 4,
            text: "4. Daisy's hair isn't dark, it's",
          },
          {
            id: 5,
            text: "5. Ed hasn't got a beard, he's got a",
          },
          {
            id: 6,
            text: "6. That man isn't overweight, he's",
          },
          {
            id: 7,
            text: "7. My hair isn't curly, it's",
          },
        ],
        left: [
          {
            id: 1,
            text: "a. tall.",
          },
          {
            id: 2,
            text: "b. square.",
          },
          {
            id: 3,
            text: "c. straight.",
          },
          {
            id: 4,
            text: "d. blue.",
          },
          {
            id: 5,
            text: "e. slim.",
          },
          {
            id: 6,
            text: "f. blonde.",
          },
          {
            id: 7,
            text: "g. moustache.",
          },
        ],
        answer: [
          {
            right: 1,
            left: 2,
          },
          {
            right: 2,
            left: 4,
          },
          {
            right: 3,
            left: 1,
          },
          {
            right: 4,
            left: 6,
          },
          {
            right: 5,
            left: 7,
          },
          {
            right: 6,
            left: 5,
          },
          {
            right: 7,
            left: 3,
          },
        ],
        type: ''
      },
    ]
  },

  2: {
    id: 'WB6-U7-P48-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page48/E2/Key/answerKey.png',
    component: DesignUnderLine,
    totalInput: 6,
    // titleQuestion: [{ num: '2', title: `Listen to the street interviews and tick (✔) the correct box. What is the person’s favourite building?`, star: 2, color: '#F68025' }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page48/E2/03.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page48/E2/05.jpg' },
        { url: 'img/FriendsPlus/Page48/E2/06.jpg', input: 1, },
        { url: 'img/FriendsPlus/Page48/E2/07.jpg', input: 1, },
        { url: 'img/FriendsPlus/Page48/E2/08.jpg', input: 1, },
        { url: 'img/FriendsPlus/Page48/E2/09.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page48/E2/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page48/E2/11.jpg' },
        { url: 'img/FriendsPlus/Page48/E2/12.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page48/E2/13.jpg', input: 2, },
        { url: 'img/FriendsPlus/Page48/E2/14.jpg', input: 2, },
        { url: 'img/FriendsPlus/Page48/E2/15.jpg', input: 2, },
        { url: 'img/FriendsPlus/Page48/E2/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page48/E2/17.jpg' },
        { url: 'img/FriendsPlus/Page48/E2/18.jpg', input: 3, },
        { url: 'img/FriendsPlus/Page48/E2/19.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page48/E2/20.jpg', input: 3, },
        { url: 'img/FriendsPlus/Page48/E2/21.jpg', input: 3, },
        { url: 'img/FriendsPlus/Page48/E2/22.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page48/E2/23.jpg' },
        { url: 'img/FriendsPlus/Page48/E2/24.jpg', input: 4, },
        { url: 'img/FriendsPlus/Page48/E2/25.jpg', input: 4, },
        { url: 'img/FriendsPlus/Page48/E2/26.jpg', input: 4, },
        { url: 'img/FriendsPlus/Page48/E2/27.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page48/E2/28.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page48/E2/29.jpg' },
        { url: 'img/FriendsPlus/Page48/E2/30.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page48/E2/31.jpg', input: 5, },
        { url: 'img/FriendsPlus/Page48/E2/32.jpg', input: 5, },
        { url: 'img/FriendsPlus/Page48/E2/33.jpg', input: 5, },
        { url: 'img/FriendsPlus/Page48/E2/34.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page48/E2/35.jpg' },
        { url: 'img/FriendsPlus/Page48/E2/36.jpg', input: 6, },
        { url: 'img/FriendsPlus/Page48/E2/37.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page48/E2/38.jpg', input: 6, },
        { url: 'img/FriendsPlus/Page48/E2/39.jpg', input: 6, },
        { url: 'img/FriendsPlus/Page48/E2/40.jpg', input: 6, },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  3: { // Exercise num
    unit: 'Unit 7',
    id: 'WB6-U7-P48-E3',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page48/E3/Key/Key.png',
    component: Wordbox,
    isAutofocus: true,
    fontSize: 25,
    typeInput: 'center',
    titleImage: 'http://',
    titleQuestion: [{ num: '3', title: 'Look at the pictures and complete the words.', color: "#0169C0", star: 2 }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page48/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page48/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page48/E3/3.jpg', input: true, answer: "l" },
        { url: 'img/FriendsPlus/Page48/E3/4.jpg', input: true, answer: "i" },
        { url: 'img/FriendsPlus/Page48/E3/5.jpg', input: true, answer: "m" },
        { url: 'img/FriendsPlus/Page48/E3/6.jpg' },
        { url: 'img/FriendsPlus/Page48/E3/7.jpg', input: true, answer: "h" },
        { url: 'img/FriendsPlus/Page48/E3/8.jpg', input: true, answer: "o" },
        { url: 'img/FriendsPlus/Page48/E3/9.jpg', input: true, answer: "r" },
        { url: 'img/FriendsPlus/Page48/E3/10.jpg', input: true, answer: "t" },
        { url: 'img/FriendsPlus/Page48/E3/11.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page48/E3/12.jpg' },
        { url: 'img/FriendsPlus/Page48/E3/13.jpg', input: true, answer: "u" },
        { url: 'img/FriendsPlus/Page48/E3/14.jpg', input: true, answer: "r" },
        { url: 'img/FriendsPlus/Page48/E3/15.jpg', input: true, answer: "l" },
        { url: 'img/FriendsPlus/Page48/E3/16.jpg', input: true, answer: "y" },
        { url: 'img/FriendsPlus/Page48/E3/17.jpg' },
        { url: 'img/FriendsPlus/Page48/E3/18.jpg', input: true, answer: "q" },
        { url: 'img/FriendsPlus/Page48/E3/19.jpg', input: true, answer: "u" },
        { url: 'img/FriendsPlus/Page48/E3/20.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page48/E3/21.jpg', input: true, answer: "r" },
        { url: 'img/FriendsPlus/Page48/E3/22.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page48/E3/23.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page48/E3/24.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page48/E3/25.jpg' },
        { url: 'img/FriendsPlus/Page48/E3/26.jpg', input: true, answer: "v" },
        { url: 'img/FriendsPlus/Page48/E3/27.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page48/E3/28.jpg', input: true, answer: "r" },
        { url: 'img/FriendsPlus/Page48/E3/29.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page48/E3/30.jpg', input: true, answer: "g" },
        { url: 'img/FriendsPlus/Page48/E3/31.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page48/E3/32.jpg' },
        { url: 'img/FriendsPlus/Page48/E3/33.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page48/E3/34.jpg', input: true, answer: "i" },
        { url: 'img/FriendsPlus/Page48/E3/35.jpg', input: true, answer: "g" },
        { url: 'img/FriendsPlus/Page48/E3/36.jpg', input: true, answer: "h" },
        { url: 'img/FriendsPlus/Page48/E3/37.jpg', input: true, answer: "t" },
        { url: 'img/FriendsPlus/Page48/E3/38.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page48/E3/39.jpg' },
        { url: 'img/FriendsPlus/Page48/E3/40.jpg', input: true, answer: "p" },
        { url: 'img/FriendsPlus/Page48/E3/41.jpg', input: true, answer: "i" },
        { url: 'img/FriendsPlus/Page48/E3/42.jpg', input: true, answer: "k" },
        { url: 'img/FriendsPlus/Page48/E3/43.jpg', input: true, answer: "y" },
        { url: 'img/FriendsPlus/Page48/E3/44.jpg' },
        { url: 'img/FriendsPlus/Page48/E3/45.jpg', input: true, answer: "l" },
        { url: 'img/FriendsPlus/Page48/E3/46.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page48/E3/47.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page48/E3/48.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page48/E3/49.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page48/E3/50.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page48/E3/51.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page48/E3/52.jpg' },
        { url: 'img/FriendsPlus/Page48/E3/53.jpg', input: true, answer: "h" },
        { url: 'img/FriendsPlus/Page48/E3/54.jpg', input: true, answer: "i" },
        { url: 'img/FriendsPlus/Page48/E3/55.jpg', input: true, answer: "n" },
        { url: 'img/FriendsPlus/Page48/E3/56.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page48/E3/57.jpg' },
        { url: 'img/FriendsPlus/Page48/E3/58.jpg', input: true, answer: "h" },
        { url: 'img/FriendsPlus/Page48/E3/59.jpg', input: true, answer: "o" },
        { url: 'img/FriendsPlus/Page48/E3/60.jpg', input: true, answer: "r" },
        { url: 'img/FriendsPlus/Page48/E3/61.jpg', input: true, answer: "t" },
        { url: 'img/FriendsPlus/Page48/E3/62.jpg' },
        { url: 'img/FriendsPlus/Page48/E3/63.jpg', input: true, answer: "o" },
        { url: 'img/FriendsPlus/Page48/E3/64.jpg', input: true, answer: "n" },
        { url: 'img/FriendsPlus/Page48/E3/65.jpg', input: true, answer: "g" },
        { url: 'img/FriendsPlus/Page48/E3/66.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page48/E3/67.jpg' },
        { url: 'img/FriendsPlus/Page48/E3/68.jpg', input: true, answer: "o" },
        { url: 'img/FriendsPlus/Page48/E3/69.jpg', input: true, answer: "u" },
        { url: 'img/FriendsPlus/Page48/E3/70.jpg', input: true, answer: "n" },
        { url: 'img/FriendsPlus/Page48/E3/71.jpg', input: true, answer: "d" },
        { url: 'img/FriendsPlus/Page48/E3/72.jpg' },
        { url: 'img/FriendsPlus/Page48/E3/73.jpg', input: true, answer: "u" },
        { url: 'img/FriendsPlus/Page48/E3/74.jpg', input: true, answer: "n" },
        { url: 'img/FriendsPlus/Page48/E3/75.jpg', input: true, answer: "g" },
        { url: 'img/FriendsPlus/Page48/E3/76.jpg', input: true, answer: "l" },
        { url: 'img/FriendsPlus/Page48/E3/77.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page48/E3/78.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page48/E3/79.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page48/E3/80.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page48/E3/81.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page48/E3/82.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page48/E3/83.jpg' },
      ],


    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },


  4: { // Exercise num
    unit: 'Unit 7',
    id: 'WB6-U7-P48-E4',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page48/E4/Key/Key.png',
    component: D1,
    fontSize: 25,
    titleImage: 'http://',
    // titleQuestion: [{ num: '1', title: 'Write the comparative forms of the given adjectives', color: "#5DC8DA", star: 1 }],
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page48/E4/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page48/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page48/E4/3.jpg', input: true, answer: "light" },
        { url: 'img/FriendsPlus/Page48/E4/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page48/E4/5.jpg' },
        { url: 'img/FriendsPlus/Page48/E4/6.jpg', input: true, answer: "blue" },
        { url: 'img/FriendsPlus/Page48/E4/7.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page48/E4/8.jpg' },
        { url: 'img/FriendsPlus/Page48/E4/9.jpg', input: true, answer: "round" },
        { url: 'img/FriendsPlus/Page48/E4/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page48/E4/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page48/E4/12.jpg' },
        { url: 'img/FriendsPlus/Page48/E4/13.jpg', input: true, answer: "short" },
        { url: 'img/FriendsPlus/Page48/E4/14.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page48/E4/15.jpg' },
        { url: 'img/FriendsPlus/Page48/E4/16.jpg', input: true, answer: "bald" },
        { url: 'img/FriendsPlus/Page48/E4/17.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page48/E4/18.jpg' },
        { url: 'img/FriendsPlus/Page48/E4/19.jpg', input: true, answer: "moustache" },
        { url: 'img/FriendsPlus/Page48/E4/20.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page48/E4/21.jpg' },
        { url: 'img/FriendsPlus/Page48/E4/22.jpg', input: true, answer: "slim" },
        { url: 'img/FriendsPlus/Page48/E4/23.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page48/E4/24.jpg' },
        { url: 'img/FriendsPlus/Page48/E4/25.jpg', input: true, answer: "overweight" },
        { url: 'img/FriendsPlus/Page48/E4/26.jpg' },
        { url: 'img/FriendsPlus/Page48/E4/27.jpg', input: true, answer: "glasses" },
        { url: 'img/FriendsPlus/Page48/E4/28.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page48/E4/29.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page48/E4/30.jpg' },
        { url: 'img/FriendsPlus/Page48/E4/31.jpg', input: true, answer: "spiky" },
        { url: 'img/FriendsPlus/Page48/E4/32.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page48/E4/33.jpg' },
        { url: 'img/FriendsPlus/Page48/E4/34.jpg', input: true, answer: "tall" },
        { url: 'img/FriendsPlus/Page48/E4/35.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page48/E4/36.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  5: { // Exercise num
    unit: 'Unit 7',
    id: 'WB6-U7-P48-E5',
    audio: '',
    video: '',
    component: T2,
    // exerciseKey: 'img/FriendsPlus/Page46/E5/Key/Key.png',
    // titleImage: 'img/FriendsPlus/Page46/E5/title.jpg',
    titleQuestion: [{ num: '5', title: 'Describe some of your friends and family.', color: "#036bb1", star: 3 }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><u style='font-weight:600;font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px;'> My aunt Melisa has got dark curly hair, brown eyes and a square face. </u></p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>",
        answer: [

        ],
        type: 'longAnwser'
      },
    ]
  },
};

export default json;
