
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
    1: { // Exercise num
        unit: 'Language Focus Practice Unit 7',
        id: 'WB6-LFP7-P72-E1',
        audio: '',
        video: '',
        component: D1,
        typeInput: 'center',
        exerciseKey: 'img/FriendsPlus/Page72/E1/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page72/E1/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page72/E1/2.jpg' },
                { url: 'img/FriendsPlus/Page72/E1/3.jpg', input: true, answer: "didn't start" },
                { url: 'img/FriendsPlus/Page72/E1/4.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page72/E1/5.jpg' },
                { url: 'img/FriendsPlus/Page72/E1/6.jpg', input: true, answer: "talked" },
                { url: 'img/FriendsPlus/Page72/E1/7.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page72/E1/8.jpg' },
                { url: 'img/FriendsPlus/Page72/E1/9.jpg', input: true, answer: "didn't practise" },
                { url: 'img/FriendsPlus/Page72/E1/10.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page72/E1/11.jpg' },
                { url: 'img/FriendsPlus/Page72/E1/12.jpg', input: true, answer: "visited" },
                { url: 'img/FriendsPlus/Page72/E1/13.jpg' },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page72/E1/14.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page72/E1/15.jpg' },
                { url: 'img/FriendsPlus/Page72/E1/16.jpg', input: true, answer: "didn't see" },
                { url: 'img/FriendsPlus/Page72/E1/17.jpg' },
            ],
            [
                // Column8
                { url: 'img/FriendsPlus/Page72/E1/18.jpg' },
                { url: 'img/FriendsPlus/Page72/E1/19.jpg', input: true, answer: "went" },
                { url: 'img/FriendsPlus/Page72/E1/20.jpg' },
            ],
            [
                // Column9
                { url: 'img/FriendsPlus/Page72/E1/21.jpg' },
                { url: 'img/FriendsPlus/Page72/E1/22.jpg', input: true, answer: "didn't become" },
                { url: 'img/FriendsPlus/Page72/E1/23.jpg' },
            ],
            [
                // Column10
                { url: 'img/FriendsPlus/Page72/E1/24.jpg' },
                { url: 'img/FriendsPlus/Page72/E1/25.jpg', input: true, answer: "won" },
                { url: 'img/FriendsPlus/Page72/E1/26.jpg' },
            ],
            [
                // Column11
                { url: 'img/FriendsPlus/Page72/E1/27.jpg' },
                { url: 'img/FriendsPlus/Page72/E1/28.jpg', input: true, answer: "didn't have" },
                { url: 'img/FriendsPlus/Page72/E1/29.jpg' },
            ],
            [
                // Column12
                { url: 'img/FriendsPlus/Page72/E1/30.jpg' },
                { url: 'img/FriendsPlus/Page72/E1/31.jpg', input: true, answer: "knew" },
                { url: 'img/FriendsPlus/Page72/E1/32.jpg' },
            ],
        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    2: { // Exercise num
        unit: 'Language Focus Practice Unit 7',
        id: 'WB6-LFP7-P72-E2',
        audio: '',
        video: '',
        component: T1,
        exerciseKey: 'img/FriendsPlus/Page72/E2/Key/answerKey.png',
        titleImage: 'img/FriendsPlus/Page72/E2/title.jpg',
        //titleQuestion: [{ num: '2', title: 'Read the text. What is Hannah’s project about? What is she learning about at school?', color: "#4D3D9B" }],
        questionImage: [],
        questions: [
            {
                title: "<p>I talked to my friend on the phone earlier</p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>I didn’t talk to my friend on the phone earlier.</u></p>" +

                    "<p><span style='font-weight:600'>1</span>  We took a photo of that film star. #</p>" +
                    "<p><span style='font-weight:600'>2</span>  Ann didn’t see the new James Cameron film. #</p>" +
                    "<p><span style='font-weight:600'>3</span>  That actor had blonde hair. #</p>" +
                    "<p><span style='font-weight:600'>4</span>  He didn’t read the Harry Potter book. #</p>" +
                    "<p><span style='font-weight:600'>5</span>  Those actors became very famous. #</p>" +
                    "<p><span style='font-weight:600'>6</span>  I didn’t know the answer to his question. #</p>" +
                    "<p><span style='font-weight:600'>7</span>  She sang a song from the film. #</p>" +
                    "<p><span style='font-weight:600'>8</span>  It didn’t rain last night. #</p>",

                answer: [
                    "We didn't take a photo of that film star.",
                    "Ann saw the new James Cameron film.",
                    "That actress didn't have blonde hair.",
                    "He read the Harry Potter book.",
                    "Those actors didn't become very famous.",
                    "I knew the answer to his question.",
                    "She didn't sing a song from the film.",
                    "It rained last night.",
                ],
                type: 'longAnwser'
            },
        ]
    },
    3: { // Exercise num
        unit: 'Language Focus Practice Unit 7',
        id: 'WB6-LFP7-P72-E3',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page72/E3/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page72/E3/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page72/E3/2.jpg' },
                { url: 'img/FriendsPlus/Page72/E3/3.jpg', input: true, answer: "didn't like" },
                { url: 'img/FriendsPlus/Page72/E3/4.jpg' },
                { url: 'img/FriendsPlus/Page72/E3/5.jpg', input: true, answer: "preferred" },
                { url: 'img/FriendsPlus/Page72/E3/6.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page72/E3/7.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page72/E3/8.jpg' },
                { url: 'img/FriendsPlus/Page72/E3/9.jpg', input: true, answer: "didn't do" },
                { url: 'img/FriendsPlus/Page72/E3/10.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page72/E3/11.jpg' },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page72/E3/12.jpg' },
                { url: 'img/FriendsPlus/Page72/E3/13.jpg', input: true, answer: "studied" },
                { url: 'img/FriendsPlus/Page72/E3/14.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page72/E3/15.jpg' },
                { url: 'img/FriendsPlus/Page72/E3/16.jpg', input: true, answer: "watched" },
                { url: 'img/FriendsPlus/Page72/E3/17.jpg' },
            ],
            [
                // Column8
                { url: 'img/FriendsPlus/Page72/E3/18.jpg' },
                { url: 'img/FriendsPlus/Page72/E3/19.jpg', input: true, answer: "didn't have" },
                { url: 'img/FriendsPlus/Page72/E3/20.jpg' },
            ],
            [
                // Column9
                { url: 'img/FriendsPlus/Page72/E3/21.jpg' },
                { url: 'img/FriendsPlus/Page72/E3/22.jpg', input: true, answer: "went" },
                { url: 'img/FriendsPlus/Page72/E3/23.jpg' },
            ],
            [
                // Column10
                { url: 'img/FriendsPlus/Page72/E3/24.jpg' },
                { url: 'img/FriendsPlus/Page72/E3/25.jpg', input: true, answer: "swam" },
                { url: 'img/FriendsPlus/Page72/E3/26.jpg' },
            ],
            [
                // Column11
                { url: 'img/FriendsPlus/Page72/E3/27.jpg' },
            ],
            [
                // Column12
                { url: 'img/FriendsPlus/Page72/E3/28.jpg' },
                { url: 'img/FriendsPlus/Page72/E3/29.jpg', input: true, answer: "didn't see" },
                { url: 'img/FriendsPlus/Page72/E3/30.jpg' },
            ],
            [
                // Column13
                { url: 'img/FriendsPlus/Page72/E3/31.jpg' },
                { url: 'img/FriendsPlus/Page72/E3/32.jpg', input: true, answer: "got up" },
                { url: 'img/FriendsPlus/Page72/E3/33.jpg' },
            ],
            [
                // Column14
                { url: 'img/FriendsPlus/Page72/E3/34.jpg' },
                { url: 'img/FriendsPlus/Page72/E3/35.jpg', input: true, answer: "left" },
                { url: 'img/FriendsPlus/Page72/E3/36.jpg' },
            ],
            [
                // Column15
                { url: 'img/FriendsPlus/Page72/E3/37.jpg' },
                { url: 'img/FriendsPlus/Page72/E3/38.jpg', input: true, answer: "ran" },
                { url: 'img/FriendsPlus/Page72/E3/39.jpg' },
            ],
            [
                // Column16
                { url: 'img/FriendsPlus/Page72/E3/40.jpg' },
                { url: 'img/FriendsPlus/Page72/E3/41.jpg', input: true, answer: "didn't get" },
                { url: 'img/FriendsPlus/Page72/E3/42.jpg' },
            ],



        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    4: { // Exercise num
        unit: 'Language Focus Practice Unit 7',
        id: 'WB6-LFP7-P72-E4',
        audio: '',
        video: '',
        component: T1,
        exerciseKey: 'img/FriendsPlus/Page72/E4/Key/answerKey.png',
        titleImage: 'img/FriendsPlus/Page72/E4/title.jpg',
        //titleQuestion: [{ num: '2', title: 'Read the text. What is Hannah’s project about? What is she learning about at school?', color: "#4D3D9B" }],
        questionImage: [],
        questions: [
            {
                title: "<p>you / eat / a lot of sweets / earlier / ? ✘</p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>Did you eat a lot of sweets earlier? No, I didn’t.</u></p>" +


                    "<p><span style='font-weight:600'>1</span>  Burak / score / a goal / in the match / ? ✔  #</p>" +
                    "<p><span style='font-weight:600'>2</span>  your friends / go / to London / yesterday / ? ✔  #</p>" +
                    "<p><span style='font-weight:600'>3</span>  Helen / start / university / last year / ? ✘  #</p>" +
                    "<p><span style='font-weight:600'>4</span>  you / practise / your English / in the UK / ? ✔  #</p>" +
                    "<p><span style='font-weight:600'>5</span>  we / go / to that café / two weeks ago / ? ✘  #</p>" +
                    "<p><span style='font-weight:600'>6</span>  you / spend time / with your grandparents / at the weekend / ? ✔ #</p>",

                answer: [
                    "Did Burak score a goal in the match? Yes, he did. ",
                    "Did your friends go to London yesterday. Yes, they did. ",
                    "Did Helen start university last year? No, she didn't. ",
                    "Did you practise your English in the UK? Yes, I did. ",
                    "Did we go that café two weeks ago? No, we didn't. ",
                    "Did you spend time with your grandparents at the weekend? Yes, I did/Did you spend time with your grandparents at the weekend? Yes, we did",
                ],
                type: 'longAnwser'
            },
        ]
    },



}

export default json;