
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
    1: { // Exercise num
         unit : 'Unit 6',
        id : 'WB6-U6-P43-E1',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page43/E1/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page43/E1/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page43/E1/2.jpg' },
                { url: 'img/FriendsPlus/Page43/E1/3.jpg', input: true, answer: "There were " },
                { url: 'img/FriendsPlus/Page43/E1/4.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page43/E1/5.jpg' },
                { url: 'img/FriendsPlus/Page43/E1/6.jpg', input: true, answer: "an" },
                { url: 'img/FriendsPlus/Page43/E1/7.jpg' },
                { url: 'img/FriendsPlus/Page43/E1/8.jpg', input: true, answer: "some" },
                { url: 'img/FriendsPlus/Page43/E1/9.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page43/E1/10.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page43/E1/11.jpg' },
                { url: 'img/FriendsPlus/Page43/E1/12.jpg', input: true, answer: "There wasn't " },
                { url: 'img/FriendsPlus/Page43/E1/13.jpg' },
                { url: 'img/FriendsPlus/Page43/E1/14.jpg', input: true, answer: "There weren't" },
                { url: 'img/FriendsPlus/Page43/E1/15.jpg' },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page43/E1/16.jpg' },
                { url: 'img/FriendsPlus/Page43/E1/17.jpg', input: true, answer: "a" },
                { url: 'img/FriendsPlus/Page43/E1/18.jpg' },
                { url: 'img/FriendsPlus/Page43/E1/19.jpg', input: true, answer: "any" },
                { url: 'img/FriendsPlus/Page43/E1/20.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page43/E1/21.jpg' },
            ],



        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    2: { // Exercise num
         unit : 'Unit 6',
        id : 'WB6-U6-P43-E2',
        audio: '',
        video: '',
        component: T1,
        fontSize: 30,
        height: 35,
        exerciseKey: 'img/FriendsPlus/Page43/E2/Key/answerKey.png',
        titleImage: '',
        titleQuestion: [{ num: '2', title: 'You were at an important football match at a stadium last week. Write affirmativeand negative sentences using There was, There wasn’t, There were, and There weren’t.', color: "#1ABED7", star: 2 }],
        questionImage: [],
        questions: [
            {
                title: "<p>a lot of spectators at the match ✔</p><p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>There were a lot of spectators at the match.</u></p>" +
                    "<p><span style='font-weight:600'>1</span>. a lot of buses with fans ✔ # </p>" +
                    "<p><span style='font-weight:600'>2</span>. a train station near the stadium ✘ # </p>" +
                    "<p><span style='font-weight:600'>3</span>. any famous players in our team ✘ # </p>" +
                    "<p><span style='font-weight:600'>4</span>. a very good player on the other team ✔ # </p>" +
                    "<p><span style='font-weight:600'>5</span>. a nice café in the stadium ✘ # </p>" +
                    "<p><span style='font-weight:600'>6</span>. a great sports shop next to the stadium ✔ # </p>" +
                    "<p><span style='font-weight:600'>7</span>. a lot of goals in the match ✔ # </p>",


                answer: [
                    "There were a lot of buses with fans.",
                    "There wasn't a train station near the stadium.",
                    "There weren't any famous players in our team.",
                    "There was a very good player on the other team.",
                    "There wasn't a nice cafe in the stadium.",
                    "There was a great sports shop next to the stadium.",
                    "There were a lot of goals in the match.",

                ],
                type: 'longAnwser'
            },
        ]
    },
    3: { // Exercise num
         unit : 'Unit 6',
        id : 'WB6-U6-P43-E3',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page43/E3/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '4', title: 'Complete the table with the given words.', color: "#1ABED7", star: 1 }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page43/E3/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page43/E3/2.jpg' },
                { url: 'img/FriendsPlus/Page43/E3/3.jpg', input: true, answer: "weren't" },
                { url: 'img/FriendsPlus/Page43/E3/4.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page43/E3/5.jpg' },
                { url: 'img/FriendsPlus/Page43/E3/6.jpg', input: true, answer: "were" },
                { url: 'img/FriendsPlus/Page43/E3/7.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page43/E3/8.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page43/E3/9.jpg' },
                { url: 'img/FriendsPlus/Page43/E3/10.jpg', input: true, answer: "was" },
                { url: 'img/FriendsPlus/Page43/E3/11.jpg' },
            ],
            [
                // Column6
                { url: 'img/FriendsPlus/Page43/E3/12.jpg' },
                { url: 'img/FriendsPlus/Page43/E3/13.jpg', input: true, answer: "wasn't" },
                { url: 'img/FriendsPlus/Page43/E3/14.jpg' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page43/E3/15.jpg' },
                { url: 'img/FriendsPlus/Page43/E3/16.jpg', input: true, answer: "wasn't" },
                { url: 'img/FriendsPlus/Page43/E3/17.jpg' },
            ],
            [
                // Column8
                { url: 'img/FriendsPlus/Page43/E3/18.jpg' },
                { url: 'img/FriendsPlus/Page43/E3/19.jpg', input: true, answer: "was" },
                { url: 'img/FriendsPlus/Page43/E3/20.jpg' },
            ],
            [
                // Column9
                { url: 'img/FriendsPlus/Page43/E3/21.jpg' },
            ],
            [
                // Column10
                { url: 'img/FriendsPlus/Page43/E3/22.jpg' },
                { url: 'img/FriendsPlus/Page43/E3/23.jpg', input: true, answer: "weren't" },
                { url: 'img/FriendsPlus/Page43/E3/24.jpg' },
            ],
            [
                // Column11
                { url: 'img/FriendsPlus/Page43/E3/25.jpg' },
                { url: 'img/FriendsPlus/Page43/E3/26.jpg', input: true, answer: "were" },
                { url: 'img/FriendsPlus/Page43/E3/27.jpg' },
            ],
            [
                // Column12
                { url: 'img/FriendsPlus/Page43/E3/28.jpg' },
            ],
            [
                // Column13
                { url: 'img/FriendsPlus/Page43/E3/29.jpg' },
                { url: 'img/FriendsPlus/Page43/E3/30.jpg', input: true, answer: "wasn't" },
                { url: 'img/FriendsPlus/Page43/E3/31.jpg' },
            ],
            [
                // Column14
                { url: 'img/FriendsPlus/Page43/E3/32.jpg' },
                { url: 'img/FriendsPlus/Page43/E3/33.jpg', input: true, answer: "was" },
                { url: 'img/FriendsPlus/Page43/E3/34.jpg' },
            ],



        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    4: { // Exercise num
         unit : 'Unit 6',
        id : 'WB6-U6-P43-E4',
        audio: '',
        video: '',
        component: T2,
        exerciseKey: '',
        titleImage: 'img/FriendsPlus/Page43/E4/title.png',
        titleQuestion: [{ num: '4', title: 'What were you interested in when you were younger? Use the given words to help you.', color: '#19BED7', star: 3 }],
        questionImage: [],
        questions: [
            {
                title: "<p style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'><u>I was really into table tennis at the age of ten.</u></p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>" +
                    "<p style='font-weight:500'>#</p>",
                answer: [],
                type: 'longAnwser'
            },
        ]
    },



}

export default json;